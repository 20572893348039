import axios from 'axios'

export const upload_lead_service = {

  async upload_file(data) {
    return axios.post(`upload-fb-leads`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  async get_by_user() {
    return axios.get(`get-fb-leads-by-user`)
  },

  async update_contacto(id, data) {
    return axios.post(`update-fb-lead/${id}`, data)
  }
}