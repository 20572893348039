import ListaAdverts from '../componentes/ListaAdverts'
import FormAdvert from '../componentes/FormAdvert'

const routes = [
  ...route('/lista-adverts', ListaAdverts, {
    auth: true,
    require_admin: true
  }),
  ...route('/guardar-advert', FormAdvert, {
    auth: true,
    require_admin: true
  }),
]

function route(path, component = Default, meta = {}) {
  return [{
    path,
    component,
    meta
  }]
}

export default routes