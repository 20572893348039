import ListaPostAdmin from '../componentes/ListaPostAdmin'


const routes = [
  ...route('/lista-posts', ListaPostAdmin, {
    auth: true,
    require_admin: true
  }),
]

function route(path, component = Default, meta = {}) {
  return [{
    path,
    component,
    meta
  }]
}

export default routes