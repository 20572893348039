<template>

  <v-dialog v-model="dialog" width="500">

    <loader v-if="isloading"></loader>

    <v-card>
      <v-card-title class="text-h5 white--text pink darken-1">
        Detalles servicio
      </v-card-title>

      <v-card-text>
        <v-container>

          <v-row dense>
            <v-col cols="12">
              <nl2br tag="p" :text="servicio.description" />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" md="6" offset-md="3">
              <v-img :src="`${$awsPath}/${servicio.qrCode}`"></v-img>
            </v-col>
          </v-row>

        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>

        <v-btn rounded depressed class="white--text" color="red lighten-1" @click="dialog = false">
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>
  export default {


    data() {
      return {
        dialog: false,

        servicio: {
          description: null,
          qrCode: null,
        }
      }
    },

    created() {
      this.$parent.$on('open_modal', data => {
        this.dialog = true
        this.servicio = data
      })
    },

    methods: {
      crearCalendario() {
        calendar_service.save_user_calendar(this.calendario).then(res => {
          this.$toast.sucs('Calendario creado con exito')
          this.dialog = false
        }, res => {
          this.$toast.sucs('Error creando calendario')
        })
      },
    },

    computed: {
      isloading() {
        return this.$store.getters.getloading
      },
      errors() {
        return this.$store.getters.geterrors
      },
      user() {
        return JSON.parse(localStorage.getItem('user'))
      }
    }
  }
</script>