export const location_rules = {
  data() {
    return {
      valid: true,
      required_rule: [
        v => !!v || 'Requerido',
      ],
      title_rule: [
        v => !!v || 'Requerido',
      ],
      name_rule: [
        v => !!v || 'Requerido',
        v => (v && v.length <= 60) || 'Supera el limite',
      ],
    }
  },
}