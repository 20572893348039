import axios from 'axios'

export const services_service = {

  async get_user_services(user_id, status) {
    let url = status == null || status == 'null' ? `services-by-user/${user_id}` : `services-by-user/${user_id}/${status}`
    return axios.get(url)
  },

  async get_service(service_id) {
    return axios.get(`service/${service_id}`)
  },

  async delete_service(item) {
    return axios.delete(`service/${item.id}`)
  },

  async save_user_service(data) {
    return axios.post(`service`, data)
  },

  async update_user_service(service) {
    return axios.put(`service/${service.id}`, service)
  },

  async update_activated_service(id, service) {
    return axios.put(`service/${id}`, service)
  },

  async get_services_count() {
    return axios.get(`/get-count-services-by-user`)
  },

  async get_all_services() {
    return axios.get(`/list-services/ALL`)
  },

  async update_online_service(service_id, status) {
    return axios.put(`service/${service_id}`, status)
  },
}