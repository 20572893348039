<template>
  <v-container>

    <h2 class="mb-1 blue--text text--darken-1">
      Servicios
    </h2>

    <loader v-if="isloading"></loader>

    <v-row class="mt-4" dense>


      <v-col cols="12">
        <v-data-table :headers="headers" :items="services" disable-pagination hide-default-footer item-key="key" class="elevation-1">

          <template v-slot:item.action="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" @click="$emit('open_modal', item)" color="blue">
                  mdi-account-details
                </v-icon>
              </template>
              <span>ver detalles</span>
            </v-tooltip>

          </template>

          <template v-slot:item.consultor="{ item }">
            {{item.userId.name}}
          </template>

          <template v-slot:item.activated="{ item }">
            <v-chip @click="changeStatus(item)" class="white--text" :color="item.activated ? 'green' : 'red lighten-1'" small dense>
              {{item.activated ? 'activo' : 'inactivo'}}
            </v-chip>
          </template>


        </v-data-table>
      </v-col>
    </v-row>

    <modal-detalles></modal-detalles>

  </v-container>
</template>

<script>
  import {
    services_service
  } from '~/services_service'

  import modalDetalles from './modalDetalles'

  export default {
    components: {
      modalDetalles
    },
    data() {
      return {
        services: [],
        headers: [{
            text: 'Consultor',
            value: 'consultor',
          },
          {
            text: 'Nombre',
            value: 'name',
          },
          {
            text: 'Estado',
            value: 'state',
          },
          {
            text: 'Categoria',
            value: 'categoryId.name',
          },
          {
            text: 'Activo',
            value: 'activated',
            align: 'center'
          },
          {
            text: 'Acciones',
            value: 'action',
            sortable: false
          },
        ],
      }
    },

    created() {
      this.getServiciosByAdmin()
    },

    methods: {
      getServiciosByAdmin() {
        services_service.get_all_services().then(res => {
          this.services = res.data.services
        }, res => {
          this.$toast.error('Error consultando servicios')
        })
      },

      changeStatus(item) {
        let data = {
          activated: !item.activated,
          packageId: item.packageId.id
        }
        services_service.update_activated_service(item.id, data).then(res => {
          item.activated = !item.activated
          let index = this.services.indexOf(item)
          this.$set(this.services, index, item)
          this.$toast.sucs('Servicio actualizado con exito')
        }, res => {
          this.$toast.error('Error actualizando servicio')
        })
      },


      openModal(item) {
        this.item = item
        this.$emit('confirm_open')
      },

      deleteServicio() {
        services_service.delete_service(this.item).then(res => {
          this.$toast.sucs('Servicio eliminado con exito')
          this.services.splice(this.services.indexOf(this.item), 1)
        }, res => {
          this.$toast.error('Error consultando registro')
        })
      }
    },
    computed: {
      isloading() {
        return this.$store.getters.getloading
      },
      errors() {
        return this.$store.getters.geterrors
      },
      user() {
        return JSON.parse(localStorage.getItem('user'))
      }
    }
  }
</script>