<template>
  <v-container>
    <loader v-if="isloading"></loader>
    <v-card>
      <v-toolbar flat color="blue accent-2" class="white--text">
        <v-btn to="/mis-posts" icon text>
          <v-icon small class="white--text">mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title>
          <h3 class="font-weight-light">Crear / Editar post</h3>
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>

        <v-form ref="form" v-model="valid" lazy-validation>

          <v-row class="mt-3">
            <v-col cols="12" md="4">
              <v-select v-model="post.state" small dense outlined :items="states" label="Comunidad" item-text="name" item-value="name"></v-select>
            </v-col>
          </v-row>

          <v-row dense>

            <v-col cols="12" md="4">
              <v-select v-model="post.category" :rules="categoria_rule" small dense outlined :items="categorias" label="Categoria" item-text="name" item-value="id"></v-select>
            </v-col>

            <v-col cols="12" md="4">
              <v-select v-model="post.subCategoryId" :rules="subcategoria_rule" small dense outlined :items="sub_categorias" label="Subcategoria" item-text="name" item-value="id"></v-select>
            </v-col>

          </v-row>



          <v-row dense>
            <v-col cols="12" md="8">
              <v-text-field v-model="post.title" :rules="title_rules" small dense outlined label="Titulo"></v-text-field>
            </v-col>

            <v-col cols="12" md="8">
              <v-textarea v-model="post.description" :rules="description_rule" small dense outlined label="Descripción"></v-textarea>
            </v-col>

            <v-col cols="12" md="8">
              <v-text-field v-model="post.url" small dense outlined label="URL"></v-text-field>
            </v-col>

            <v-col cols="12" md="8">
              <v-file-input @change="fileChange" small outlined dense accept="image/*" label="Imagenes"></v-file-input>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <v-btn :loading="isloading" @click="updateOrCreate" rounded class="white--text" depressed color="pink darken-1">guardar</v-btn>
            </v-col>
          </v-row>

        </v-form>
      </v-card-text>

    </v-card>

  </v-container>
</template>

<script>
  import {
    post_rules
  } from '../mixins/post_rules'

  import {
    post_service
  } from '~/post_service'

  import {
    categoria_service
  } from '~/categoria_service'

  import {
    states_service
  } from '~/states_service'

  export default {

    mixins: [post_rules],

    data() {
      return {
        states: [],

        categorias: [],

        sub_categorias: [],

        formData: new FormData(),

        post: {
          subCategoryId: null,
          category: null,
          state: null,
          title: null,
          description: null,
          url: null,
          image: null,
          typePost: 'article',
          typeUser: 'COLLABORATOR_ROLE'
        }
      }
    },

    watch: {
      'post.category'(n, o) {
        this.getSubcategories(n)
      },
    },

    created() {
      this.$route.query.id ? this.getPost(this.$route.query.id) : null
      this.getCategories('INTEREST_ROLE')
      this.getStates()
    },

    methods: {
      getStates() {
        states_service.get_states().then(res => {
          this.states = res.data
        }, res => {
          this.$toast.error('Error consultando estados')
        })
      },

      getPost(id) {
        post_service.get_post_by_id(id).then(res => {
          this.post = this.transformObject(res.data)
        }, res => {
          this.$toast.error('Error consultando post')
        })
      },

      transformObject(data) {
        data.category = data.category.id
        data.subCategoryId = data.subCategoryId.id
        data.userId = data.userId.id
        return JSON.parse(JSON.stringify(data))
      },

      getCategories(role) {
        categoria_service.get_categorias(role).then(res => {
          this.categorias = res.data
        }, res => {
          this.$toast.error('Error consultando categorias')
        })
      },

      getSubcategories(categoria_id) {
        categoria_service.get_sub_categorias(categoria_id).then(res => {
          this.sub_categorias = res.data
        }, res => {
          this.$toast.error('Error consultando sub categorias')
        })
      },
      updateOrCreate() {
        if (!this.$refs.form.validate()) {
          return this.$toast.warn('Error de validando formulario')
        }
        this.post.id ? this.updatePost() : this.savePost()
      },

      savePost() {
        post_service.save_user_post(this.post).then(res => {
          this.uploadImages(res.data.id)
        }, res => {
          this.$toast.error('Error guardando post')
        })
      },

      updatePost() {
        if (this.formData.has('img')) {
          this.post.image = []
        }
        post_service.update_user_post(this.post).then(res => {
          this.uploadImages(this.post.id)
        }, res => {
          this.$toast.error('Error guardando post')
        })
      },

      uploadImages(id) {
        if (!this.formData.has('img')) {
          this.$toast.sucs('Post guardado con exito')
          return this.$router.push('/mis-posts')
        }

        this.axios.post(`/upload/posts/${id}`, this.formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(res => {
          this.$toast.sucs('Archivos cargados con exito!')
          this.$router.push('/mis-posts')
        }, res => {
          this.$toast.error('Algo ha salido mal!')
        })
      },

      fileChange(file) {
        this.formData.append('img', file)
      }
    },

    computed: {
      isloading() {
        return this.$store.getters.getloading
      },
      errors() {
        return this.$store.getters.geterrors
      },
      user() {
        return JSON.parse(localStorage.getItem('user'))
      },
    }
  }
</script>