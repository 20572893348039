import ListaTickets from '../componentes/ListaTickets'
import MisTickets from '../componentes/MisTickets'
import FormTicket from '../componentes/FormTicket'
import FormOpenTicket from '../componentes/FormOpenTicket'
import ListaTicketsUser from '../componentes/ListaTicketsUser'

const routes = [
  ...route('/lista-tickets', ListaTickets, {
    auth: true,
    require_admin: true,
  }),
  ...route('/mis-tickets', MisTickets, {
    auth: true
  }),
  ...route('/detalle-ticket/:id', FormTicket, {
    auth: true
  }),
  ...route('/ticket-soporte', FormOpenTicket, {
    auth: true
  }),
  ...route('/lista-mis-tickets', ListaTicketsUser, {
    auth: true
  }),
]

function route(path, component = Default, meta = {}, props = true) {
  return [{
    path,
    component,
    meta,
    props
  }]
}

export default routes