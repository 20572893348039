<template>
  <v-dialog persistent v-model="dialog" width="650">

    <v-card>
      <v-card-title class="text-h5 pink darken-1 white--text">
        Configuración
      </v-card-title>

      <v-card-text>
        <v-row dense class="mt-8">
          <v-col cols="12">
            <v-select v-model="item.visaTypes" multiple chips :items="visa_types" outlined small label="Tipo de visa" item-text="id" item-value="id"></v-select>
          </v-col>
          <!--<v-col cols="12">
            <v-select v-model="item.visaStatus" multiple chips :items="visa_status" outlined small label="Estado de visa" item-text="id" item-value="id"></v-select>
          </v-col>-->

          <v-col cols="12">
            <v-textarea v-model="item.message" label="Mensaje automatico" outlined small dense></v-textarea>
          </v-col>
        </v-row>

      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green" text @click="saveUserConfig">
          Guardar
        </v-btn>
        <v-btn color=" red lighten-1" text @click="dialog = false">
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
  import {
    lead_service
  } from '~/lead_service'

  export default {
    props: ['visa_status', 'visa_types'],
    data() {
      return {
        item: {
          visaTypes: [],
          visaStatus: [],
          message: null
        },
        dialog: false,
      }
    },

    created() {
      this.$parent.$on('open_config', data => {
        this.dialog = true
        this.item = JSON.parse(JSON.stringify(data))
      });
    },

    methods: {
      saveUserConfig() {
        lead_service.save_user_config(this.item).then(res => {
          this.$emit('config_saved', res.data)
          this.dialog = false
        }, res => {
          this.$toast.error('Error guardando configuración')
        })
      },
    }
  }
</script>