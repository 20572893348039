import Chat from '../componentes/Chat'
import ListaChats from '../componentes/ListaChats'

const routes = [
  ...route('/chat', Chat, {
    auth: true,
  }),
  ...route('/lista-chats', ListaChats, {
    auth: true,
  }),
]

function route(path, component = Default, meta = {}, props = true) {
  return [{
    path,
    component,
    meta,
    props
  }]
}

export default routes