<template>
  <v-container>

    <loader v-if="isloading"></loader>

    <v-row color="">

      <v-col v-for="item in packs" :key="item.id" cols="12" md="4">

        <v-card outlined class="mx-auto justify-center">
          <v-card-text class="justify-center text-center">
            <h3 class="text-uppercase font-weight-bold pink--text text--darken-1">{{item.title}}</h3>
            <h4 class="font-weight-bold mt-2 mb-0 display-1">
              ${{item.price}}
            </h4>
            <h3 class=" mb-4 font-weight-light">por mes</h3>
            <v-btn @click="openModal(item)" rounded depressed class="white--text mt-1" color="pink darken-1">
              Ver detalles
            </v-btn>
          </v-card-text>
        </v-card>

      </v-col>

    </v-row>

    <modal-detalles>
    </modal-detalles>

  </v-container>
</template>

<script>
  import {
    package_service
  } from '~/package_service'

  import modalDetalles from './modalDetalles'

  export default {
    components: {
      modalDetalles
    },
    data() {
      return {
        packs: []
      }
    },

    created() {
      this.getPackages('APP')
    },

    methods: {
      openModal(pack) {
        this.$store.dispatch('setSelectedPackage', pack)
        this.$emit('open_modal')
      },
      getPackages(environment) {
        package_service.get_packages(environment).then(res => {
          this.packs = res.data
        }, res => {
          this.$toast.error('Error consultando paquetes, intente de nuevo')
        })
      }

    },

    computed: {
      isloading() {
        return this.$store.getters.getloading
      },
      user() {
        return JSON.parse(localStorage.getItem('user'))
      },
    }
  }
</script>