import axios from 'axios'

export const reportes_service = {
  async get_user_report(data) {
    return axios.post(`reports/filters-all`, data)
  },

  async get_accounts() {
    return axios.get(`reports/filters-all`)
  },

  async get_uscis_cases(data) {
    return axios.post(`reports/filter-cases`, data)
  },

}