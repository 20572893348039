<template>

  <v-row dense>

    <v-col cols="12">
      <v-text-field :value="ticket.userId ? `${ticket.userId.name} ${ticket.userId.last_name}` : 'N/A'" outlined dense small label="Usuario" disabled></v-text-field>
    </v-col>

    <v-col cols="12">
      <v-text-field :value="ticket.created_date | fecha" outlined dense small label="Creado el" disabled></v-text-field>
    </v-col>

    <v-col cols="12">
      <v-text-field hide-details :value="ticket.updated_date | fecha" outlined dense small label="Última actualización" disabled></v-text-field>
    </v-col>

    <v-col cols="12">
      <v-chip class="white--text mb-3 ml-1 mt-2" :color="ticket.status == 'OPEN' ? 'green' : 'red lighten-1'">
        {{ticket.status}}
      </v-chip>
    </v-col>

  </v-row>
</template>

<script type="text/javascript">
  import moment from 'moment'
  export default {
    props: ['ticket'],
    filters: {
      fecha(value) {
        return moment(value).format('dddd D MMMM Y (H:s)')
      }
    },
  }
</script>