import FormPerfil from '../componentes/FormPerfil'

const routes = [
  ...route('/perfil', FormPerfil, {
    auth: true,
  }),
]

function route(path, component = Default, meta = {}) {
  return [{
    path,
    component,
    meta
  }]
}

export default routes