<template>
  <v-container>

    <h2 class="mb-4 blue--text text--darken-1">
      MIS BOOKINGS
    </h2>

    <loader v-if="isloading"></loader>

    <v-row dense>
      <v-col cols="12" md="3">
        <v-select outlined dense v-model="selected_state" :items="states" label="Estado" item-text="label" item-value="status"></v-select>
      </v-col>
    </v-row>


    <v-row dense>
      <v-col cols="12">
        <v-data-table :headers="headers" :items="items" disable-pagination hide-default-footer item-key="key" class="elevation-1 mt-6">


          <template v-slot:item.name="{ item }">
            <span>{{item.userId.name}}</span>
          </template>

          <template v-slot:item.bookingDate="{ item }">
            <span>{{item.bookingDate | format_date }}</span>
          </template>

          <template v-slot:item.amount="{ item }">
            <span>{{item.amount | convertToHuman}}</span>
          </template>

          <template v-slot:item.bookingTime="{ item }">
            <span>{{item.bookingTime | format_hour}}</span>
          </template>

          <template v-slot:item.chat="{ item }">
            <router-link v-if="item.userId" :to="`/chat?id=${item.userId.id}`">
              <v-icon color="green">mdi-chat-processing</v-icon>
            </router-link>
          </template>

          <template v-slot:item.video="{ item }">
            <router-link :to="`/booking-meet/${item.id}`">
              <v-icon color="orange">mdi-video</v-icon>
            </router-link>
          </template>

          <template v-slot:item.action="{ item }">

            <v-icon @click="$emit('open_modal', item)" small class="mr-2" color="blue">
              mdi-pencil
            </v-icon>
            <!-- <v-icon @click="deleteServicio(item)" small color="red lighten-1">
              mdi-trash-can
            </v-icon>-->
          </template>

        </v-data-table>
      </v-col>
    </v-row>
    <modal-booking v-on:update_list="updateList" :states="states"></modal-booking>


  </v-container>
</template>

<script>
  import {
    booking_service
  } from '~/booking_service'

  import moment from 'moment'

  import "moment/locale/es";

  import modalBooking from './modalBooking'

  export default {
    components: {
      modalBooking
    },
    data() {
      return {
        selected_state: 'PENDING',

        states: [{
            status: 'PENDING',
            label: 'Pendiente'
          },
          {
            status: 'CONFIRMED',
            label: 'Confirmado'
          },
          {
            status: 'COMPLETED',
            label: 'Completado'
          },
          {
            status: 'CANCELLED',
            label: 'Cancelado'
          },
        ],

        items: [],
        headers: [{
            text: 'Nombre Reserva',
            value: 'name',
          },
          {
            text: 'Teléfono Reserva',
            value: 'phone',
          },
          {
            text: 'Servicio',
            value: 'serviceId.title',
          },
          {
            text: 'Fecha',
            value: 'bookingDate',
          },
          {
            text: 'Hora',
            value: 'bookingTime',
          },
          {
            text: 'Precio',
            value: 'amount',
          },
          {
            text: 'Chat',
            value: 'chat',
          },
          {
            text: 'video',
            value: 'video',
          },
          {
            text: 'Acciones',
            value: 'action',
            sortable: false
          },
        ],
      }
    },

    created() {
      //moment.locale('es');
    },

    watch: {
      'selected_state': {
        immediate: true,
        handler(n, o) {
          this.getUserBooking(this.user.id, n)
        }
      }
    },



    methods: {
      getUserBooking(user_id, status) {
        booking_service.get_my_bookings(user_id, status).then(res => {
          this.items = res.data
        }, res => {
          this.$toast.error('Error consultando bookings')
        })
      },

      updateList(booking_id) {
        let index = this.items.findIndex(x => x.id == booking_id)
        this.items.splice(index, 1)
      },

      deleteServicio(item) {
        /*this.axios.delete(`service/${item._id}`).then(res => {
          this.$toast.sucs('Servicio eliminado con exito')
          this.services.splice(this.services.indexOf(item), 1)
        }, res => {
          this.$toast.error('Error consultando registro')
        })*/
      },
    },
    filters: {
      convertToHuman(amount) {
        let a = parseFloat(amount / 100).toFixed(2)
        return `$${a}`
      },
      format_hour(hour_24_format) {
        return moment(`01-01-2023 ${hour_24_format}:00`).format('hh:mm a')
      },
      format_date(db_date) {
        return moment(db_date).format('ll')
      },
    },
    computed: {
      isloading() {
        return this.$store.getters.getloading
      },
      errors() {
        return this.$store.getters.geterrors
      },
      user() {
        return JSON.parse(localStorage.getItem('user'))
      }
    }
  }
</script>