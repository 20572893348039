<template>
  <v-container>

    <v-card>
      <v-toolbar flat color="blue accent-2" class="white--text">
        <v-btn to="/categorias" icon text>
          <v-icon small class="white--text">mdi-keyboard-backspace</v-icon>
        </v-btn>
        <v-toolbar-title>
          <h3 class="font-weight-light">Sub Categorias - {{categoria.name}}</h3>
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-row dense>
          <v-col cols="12" md="4">
            <v-text-field small dense v-model="sub_categoria.name" outlined label="Nombre (actual)"></v-text-field>
          </v-col>
          <v-col cols="12" md="8">
            <v-file-input ref="fileinput" small dense outlined @change="onFileChange" accept="image/*" label="Imagen"></v-file-input>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" md="4">
            <v-text-field color="green" small dense v-model="sub_categoria.name_es" outlined label="Nombre ES"></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field color="orange" small dense v-model="sub_categoria.name_en" outlined label="Nombre EN"></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field color="blue" small dense v-model="sub_categoria.name_pt" outlined label="Nombre PT"></v-text-field>
          </v-col>
        </v-row>


        <v-row dense>
          <v-col cols="12" md="8">
            <v-text-field color="green" small dense v-model="sub_categoria.description_es" outlined label="Descripción ES"></v-text-field>
          </v-col>

          <v-col cols="12" md="8">
            <v-text-field color="orange" small dense v-model="sub_categoria.description_en" outlined label="Descripción EN"></v-text-field>
          </v-col>

          <v-col cols="12" md="8">
            <v-text-field color="blue" small dense v-model="sub_categoria.description_pt" outlined label="Descripción PT"></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-btn @click="updateOrCreate" rounded class="white--text" depressed color="blue accent-2">guardar</v-btn>
            <v-btn @click="nuevo" rounded class="white--text ml-4" depressed color="success">nuevo</v-btn>
          </v-col>
        </v-row>

      </v-card-text>

    </v-card>

    <loader v-if="isloading"></loader>

    <v-row dense>
      <v-col cols="12">
        <v-data-table :headers="headers" :items="categorias" disable-pagination hide-default-footer item-key="key" class="elevation-1 mt-6">

          <template v-slot:item.image="{ item }">
            <img class="mt-2 mb-2" height="50" width="50" :src="`${$awsPath}/${item.image}`" alt="">
          </template>

          <template v-slot:item.action="{ item }">
            <v-icon @click="setItem(item)" small class="mr-2" color="blue">
              mdi-pencil
            </v-icon>
            <v-icon @click="deleteSubcategoria(item)" small color="red lighten-1">
              mdi-trash-can
            </v-icon>
          </template>

        </v-data-table>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
  import {
    categoria_service
  } from '~/categoria_service'

  export default {

    data() {
      return {
        categoria: {},

        categorias: [],

        upload: new FormData(),

        sub_categoria: {
          id: null,
          categoryId: null,
          name: null,
          name_es: null,
          name_en: null,
          name_pt: null,
          description_es: null,
          description_en: null,
          description_pt: null,
          description: null
        },

        headers: [{
            text: 'Image',
            value: 'image',
          },
          {
            text: 'Name',
            value: 'name',
          },
          {
            text: 'Acciones',
            value: 'action',
            sortable: false
          },
        ],
      }
    },

    created() {
      this.sub_categoria.categoryId = this.$route.params.id
      this.getSubCategories(this.$route.params.id)
      this.getCategoria(this.$route.params.id)
    },

    methods: {
      nuevo() {
        this.$refs.fileinput.reset()
        let categoryId = this.$route.params.id
        this.sub_categoria = {
          id: null,
          categoryId: categoryId,
          name: null,
          name_es: null,
          name_en: null,
          name_pt: null,
          description_es: null,
          description_en: null,
          description_pt: null,
          description: null
        }
      },

      getCategoria(id) {
        categoria_service.get_categoria(id).then(res => {
          this.categoria = res.data
        }, res => {
          this.$toast.error('Error consultando categoria')
        })
      },

      getSubCategories(categoria) {
        categoria_service.get_sub_categorias(categoria).then(res => {
          this.categorias = res.data
        }, res => {
          this.$toast.error('Error consultando subcategorias')
        })
      },

      updateOrCreate() {
        this.sub_categoria.id ? this.updateSubcategoria() : this.saveSubcategoria()
      },

      updateSubcategoria() {
        this.axios.put(`subcategory/${this.sub_categoria.id}`, this.sub_categoria).then(res => {
          this.uploadImage(res.data)
          let find = this.categorias.findIndex(x => x.id == res.data.id)
          this.$set(this.categorias, find, res.data)
          this.nuevo()
        }, res => {
          this.$toast.error('Error actualizando subcategoria')
        })
      },

      saveSubcategoria() {
        this.axios.post(`subcategory`, this.sub_categoria).then(res => {
          this.$toast.sucs('Subcategoria guardada con exito')
          this.uploadImage(res.data)
          this.categorias.push(res.data)
          this.nuevo()
        }, res => {
          this.$toast.error('Error guardando subcategoria')
        })
      },

      deleteSubcategoria(item) {
        this.axios.delete(`subcategory/${item.id}`).then(res => {
          this.$toast.sucs('Subcategoria eliminada con exito')
          let find = this.categorias.findIndex(x => x.id == item.id)
          this.categorias.splice(find, 1)
        }, res => {
          this.$toast.error('Error eliminando subcategoria')
        })
      },

      uploadImage(categoria) {
        if (!this.upload.has('img')) {
          return null
        }
        this.axios.post(`upload/subcategory/${categoria.id}`, this.upload, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(res => {
          this.$toast.sucs('Imagen cargada con exito')
        }, res => {})
      },

      onFileChange(file) {
        this.upload.append('img', file)
      },

      setItem(item) {
        item.categoryId = item.categoryId.id
        this.sub_categoria = JSON.parse(JSON.stringify(item))
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      }

    },
    computed: {
      nombre_categoria() {
        return this.$store.getters.get_nombre_categoria
      },
      isloading() {
        return this.$store.getters.getloading
      },
      errors() {
        return this.$store.getters.geterrors
      },
      user() {
        return JSON.parse(localStorage.getItem('user'))
      }
    }
  }
</script>