import axios from 'axios'

export const lead_service = {

  async get_user_leads() {
    return axios.get(`get-user-leeds`)
  },

  async get_user_config() {
    return axios.get(`get-user-config`)
  },

  async get_all_leads() {
    return axios.get(`get-fb-leads`)
  },

  async save_user_config(data) {
    return axios.post(`save-user-config`, data)
  },

}