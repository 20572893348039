<template>
  <v-combobox @keyup="comboChange" outlined v-model="selected_place" small dense :search-input.sync="locationSearchText" item-text="description" item-value="placeId" label="Ubicación" dense :items="locationFoundItems">
  </v-combobox>
</template>

<script>
  import {
    Loader
  } from "@googlemaps/js-api-loader"

  import {
    google_place_service
  } from '~utils/google_place_service'

  export default {

    data() {
      return {
        google_loader: new Loader({
          apiKey: 'AIzaSyBM5gR8kKS8-xVr3lMyRnBpKQZObEPJIfE',
          version: 'weekly',
        }),

        selected_place: {
          description: null,
          place_id: null,
        },

        locationSearchText: null,

        found_items: [],

        includes_item: [],

        places_service: null,


      }
    },

    watch: {

      'locationSearchText'(n, o) {
        if (!n) {
          return null
        }
        this.selected_place = n
        this.places_service.getPlacePredictions({
          input: n,
          types: ['geocode']
        }, this.displaySuggestions)
      },

      'selected_place'(n, o) {
        if (!n.place_id) {
          return null
        }
        //this.servicio.office = n.description
        this.findPlaceId(n.place_id)
      }

    },


    mounted() {
      this.google_loader.load().then(async () => {
        const {
          AutocompleteService
        } = await google.maps.importLibrary('places')

        const options = {
          types: ['geocode', 'geometry'],
          fields: ["place_id", "geometry", "name", "formatted_address"],
          placeIdOnly: true
        }
        this.places_service = new AutocompleteService(null, options)
      })
    },

    methods: {
      async findPlaceId(place_id) {
        let results = await google_place_service.findPlaceById(place_id)
        this.$emit('place_find', {
          formatted_address: results[0].formatted_address,
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng()
        })
      },
      comboChange(data) {
        //console.log('data',data);
      },
      displaySuggestions(predictions, status) {
        if (status !== google.maps.places.PlacesServiceStatus.OK) {
          return null
        }
        this.found_items = predictions
      }
    },


    computed: {
      locationFoundItems() {
        return this.found_items
      }
    }
  }
</script>

<style scoped="true">
  .v-tab {
    font-weight: 700;
  }
</style>