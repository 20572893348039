import axios from 'axios'

export const center_service = {

  async get_service_centers() {
    return axios.get(`all-services-center`)
  },

  async save_service_center() {
    return axios.post(`services-center`)
  },

  async update_service_center(data) {
    return axios.put(`services-center/${data.id}`, data)
  },

  async delete_service_center(id) {
    return axios.delete(`services-center/${id}`)
  },
}