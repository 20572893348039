<template>

  <v-container class="fill-height" fluid>
    <v-row class="mt-4 " dense justify="center">

      <v-col cols="12" md="8">
        <div ref="meetingref" id="meeting">
          <div class="content-video">
            <video v-show="!isVideoSubed || isCameraOn" id="local-video" :class="{'video-fullscreen': !isVideoSubed}"></video>
            <video v-show="isVideoSubed" id="remote-video" :class="{'video-fullscreen': isVideoSubed && !isCameraOn}"></video>
          </div>

          <div class="mt-4 text-center">
            <v-btn @click="toggleCamera" class="mx-2" fab dark small color="primary">
              <v-icon dark>
                {{ !isCameraOn ? 'mdi-video-off' : 'mdi-video' }}
              </v-icon>
            </v-btn>

            <v-btn @click="toggleMicrophone" class="mx-2" fab dark small color="green">
              <v-icon dark>
                {{ !isMicrophoneOn ? 'mdi-microphone-off' : 'mdi-microphone' }}
              </v-icon>
            </v-btn>

            <v-btn @click="endCall" class="mx-2" fab dark small color="red lighten-1">
              <v-icon dark>
                mdi-phone-hangup
              </v-icon>
            </v-btn>

          </div>
        </div>
      </v-col>

      <v-col cols="12" md="4">

        <chat-component></chat-component>
      </v-col>

    </v-row>
  </v-container>

</template>

<script>
  import AC from 'agora-chat';

  import AgoraRTC from 'agora-rtc-sdk-ng'

  import chatComponent from './chatComponent'

  export default {
    components: {
      chatComponent
    },

    data() {
      return {
        client: null,
        token: '007eJxTYDg/60fTif9hd76WlDJVrjkzh+P6lT2h/cWLBM9xJM4Pm6WgwJBkZG6UYpaYmmhhaGpikWpukZhslGpmZGqWnGhqbJBiaVH7IrUhkJFhQZoZIyMDBIL4XAyefr6e7kGOocGODAwA4V4ijQ==',
        channel: null,
        app_id: 'b272d6aea81548e78ac2e6256ca530d9',
        isCameraOn: false,
        isMicrophoneOn: false,
        isVideoSubed: false,
        isAudioSubed: false,
        videoTrack: null,
        audioTrack: null,
        video_devices: [],
        chat_conn: null,
        meeting_h: 300,
      }
    },

    sockets: {
      "join-meeting": function(data) {
        this.client.setClientRole("host")

        this.client.join(this.app_id, data.data.channelName, data.token, null).then(res => {

        }, err => {
          console.log(err)
        })
      },

      "user-join": function(data) {

      },

      "disconnect-user": function() {
        this.$toast.warn('Usuario ya se encuentra conectado')
        setTimeout(() => {
          this.$router.push('/mis-bookings')
        }, 500)
      }
    },

    mounted() {
      this.meeting_h = this.$refs.meetingref.clientHeight
      this.client = AgoraRTC.createClient({
        mode: 'live',
        codec: 'vp8'
      })

      this.channel = this.$route.params.id

      this.$socket.emit('join-meeting', {
        channelName: this.channel,
        userId: null,
        role: null
      })

      this.$socket.emit('subscribe', {
        room: this.channel,
        userId: this.user.id
      })

      this.getDevices()

      this.client.on("user-published", this.onPublished)
    },

    methods: {

      endCall() {
        this.client.leave()

        this.videoTrack && this.videoTrack.stop()
        this.audioTrack && this.audioTrack.stop()

        this.$toast.sucs('Saliendo del meeting')

        setTimeout(() => {
          this.$router.push('/mis-bookings')
        }, 500)

        this.$socket.emit('left-room', {
          room: this.channel,
          userId: this.user.id
        })
      },


      getDevices() {
        AgoraRTC.getCameras().then(devices => {
          this.devices = devices
        }).catch(e => {
          console.log("get devices error!", e)
        })
      },

      async onPublished(user, mediaType = "video" | "audio") {
        await this.client.subscribe(user, mediaType)

        if (mediaType === "video") {
          const remoteVideoTrack = user.videoTrack

          if (remoteVideoTrack) {
            remoteVideoTrack.play("remote-video")
            this.isVideoSubed = true
          }
        }

        if (mediaType === "audio") {
          const remoteAudioTrack = user.audioTrack;

          if (remoteAudioTrack) {
            remoteAudioTrack.play()
            this.isAudioSubed = true
          }
        }
      },

      async toggleCamera() {
        if (this.devices.length == 0) {
          await this.client.publish('video')
          return this.$toast.warn('No se encuentra dispositivo de video')
        }

        if (!this.videoTrack) {
          this.videoTrack = await AgoraRTC.createCameraVideoTrack()
          await this.client.publish(this.videoTrack)
          this.videoTrack.play("local-video")
        }

        if (!this.isCameraOn) {
          this.videoTrack.setMuted(false)
        } else {
          this.videoTrack.setMuted(true)
        }

        this.isCameraOn = !this.isCameraOn
      },

      async toggleMicrophone() {
        if (!this.audioTrack) {
          this.audioTrack = await AgoraRTC.createMicrophoneAudioTrack()
          await this.client.publish(this.audioTrack)
        }

        if (!this.isMicrophoneOn) {
          await this.audioTrack.setMuted(false)
        } else {
          await this.audioTrack.setMuted(true)
        }
        this.isMicrophoneOn = !this.isMicrophoneOn
      }

    },


    computed: {
      isloading() {
        return this.$store.getters.getloading
      },
      errors() {
        return this.$store.getters.geterrors
      },
      user() {
        return JSON.parse(localStorage.getItem('user'))
      }
    }
  }
</script>


<style media="screen">
  #meeting {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .content-video {
    display: flex;
    flex-grow: 1;
    max-height: 80%;
    align-items: center;
  }

  .video-fullscreen {
    max-width: 100%;
    max-height: 100%;
  }

  #local-video,
  #remote-video {
    aspect-ratio: 16/9;
    margin: 4px;
    display: flex;
    flex-grow: 1;
    border-radius: 4px;
    /*background-color: #666666;*/
    background-size: cover;
    background-position: center;
    background-image: url('/default-video-thumbnail.jpg');
  }

  .content-operation {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    background-color: #efefef;
  }
</style>