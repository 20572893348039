<template>
  <v-container>

    <v-card>
      <v-toolbar flat color="blue accent-2" class="white--text">
        <v-btn to="/paquetes-directorio" icon text>
          <v-icon small class="white--text">mdi-arrow-left-bold</v-icon>
        </v-btn>
        <v-toolbar-title>
          <h3 class="font-weight-light">Crear / Editar paquete</h3>
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <loader v-if="isloading"></loader>
        <!--        <v-form ref="form" v-model="valid" lazy-validation>
-->
        <v-form>

          <v-row class="mt-3" dense>

            <v-col cols="12" md="4">
              <v-select small dense outlined hide-details v-model="item.type" :items="roles" label="Rol"></v-select>
            </v-col>

            <v-col cols="12" md="4">
              <v-select v-model="item.category" small dense outlined :items="categorias" item-text="name" item-value="id" label="Categoria"></v-select>
            </v-col>

            <v-col cols="12" md="4">
              <v-select v-model="item.subCategory" small dense outlined :items="sub_categorias" item-text="name" item-value="id" label=" Subcategoria"></v-select>
            </v-col>

          </v-row>

          <v-row dense>

            <v-col cols="12" md="4">
              <v-select v-model="item.packageType" small dense outlined :items="tipos" label="Tipo"></v-select>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field v-model="item.price" :rules="price_rule" small dense outlined label="Precio" type="number" step="0.1"></v-text-field>
            </v-col>

          </v-row>

          <v-row dense>

            <v-col cols="12" md="4">
              <v-text-field v-model="item.title" :rules="title_rule" small dense hide-details outlined label="Titulo"></v-text-field>
            </v-col>

            <v-col cols="8">
              <v-text-field v-model="item.description" :rules="description_rule" small dense outlined label="Descripción corta"></v-text-field>
            </v-col>
          </v-row>



          <v-row dense>
            <v-col cols="12" md="3">
              <v-text-field v-model="item.advertQuantity" :rules="number_rule" type="number" step="1" small dense outlined label="Cantidad Anucios"></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field v-model="item.booking_quantity" :rules="number_rule" type="number" step="1" small dense outlined label="Cantidad Bookings"></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field v-model="item.service_quantity" :rules="number_rule" type="number" step="1" small dense outlined label="Cantidad Servicios"></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field v-model="item.business_quantity" :rules="number_rule" :disabled="!item.business_enable" type="number" step="1" small dense outlined label="Cantidad Business"></v-text-field>
            </v-col>
          </v-row>


          <v-row dense>
            <v-col cols="12" md="3">
              <v-text-field v-model="item.combo_quantity" :rules="number_rule" :disabled="!item.combo" type="number" step="1" small dense outlined label="Cantidad Combos"></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field v-model="item.items_quantity" :rules="number_rule" :disabled="!item.isItems" type="number" step="1" small dense outlined label="Cantidad Items"></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field v-model="item.leads" type="number" step="1" small dense outlined label="Leads"></v-text-field>
            </v-col>
          </v-row>


          <v-row dense>
            <v-col cols="12" md="3">
              <v-checkbox class="mt-1" v-model="item.chat_enable" label="Chat"></v-checkbox>
            </v-col>

            <v-col cols="12" md="3">
              <v-checkbox class="mt-1" v-model="item.top" label="Top"></v-checkbox>
            </v-col>

            <v-col cols="12" md="3">
              <v-checkbox class="mt-1" v-model="item.video_enable" label="Video"></v-checkbox>
            </v-col>

            <v-col cols="12" md="3">
              <v-checkbox class="mt-1" v-model="item.business_enable" label="Business"></v-checkbox>
            </v-col>

            <v-col cols="12" md="3">
              <v-checkbox class="mt-1" v-model="item.combo" label="Combo"></v-checkbox>
            </v-col>

            <v-col cols="12" md="3">
              <v-checkbox class="mt-1" v-model="item.isItems" label="Items"></v-checkbox>
            </v-col>

            <v-col cols="12" md="3">
              <v-checkbox class="mt-1" v-model="item.payment_outside" label="Pagos outside app"></v-checkbox>
            </v-col>

            <v-col cols="12" md="3">
              <v-checkbox class="mt-1" v-model="item.payment_withinapp" label="Pagos inside app"></v-checkbox>
            </v-col>

          </v-row>

          <v-row dense>

            <v-col cols="12" md="3">
              <v-checkbox class="mt-1" v-model="item.status" label="Status"></v-checkbox>
            </v-col>

            <v-col cols="12" md="3">
              <v-checkbox class="mt-1" v-model="item.businessCard" label="Business Card"></v-checkbox>
            </v-col>

            <v-col cols="12" md="3">
              <v-checkbox class="mt-1" v-model="item.top" label="Top 10"></v-checkbox>
            </v-col>

            <v-col cols="12" md="3">
              <v-checkbox class="mt-1" v-model="item.qr_code" label="Código Qr"></v-checkbox>
            </v-col>
          </v-row>
          <!-- mas ->

          <!-- items -->
          <v-row align="center" dense>

            <v-col cols="12">
              <h3 class="mt-4 mb-4 blue--text">Items package</h3>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field v-model="paquete_item.description" hide-details outlined label="Descripción" small dense></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-btn @click="addItem" depressed rounded class="white--text" small dense color="green">
                guardar
              </v-btn>
            </v-col>

          </v-row>

          <v-row class="mt-4" dense>
            <v-col cols="12">

              <v-data-table :headers="headers" :items="item.items" disable-pagination hide-default-footer item-key="key" class="elevation-1">

                <template v-slot:item.action="{ item }">

                  <v-icon @click="setItem(item)" small class="mr-2" color="blue">
                    mdi-pencil
                  </v-icon>
                  <v-icon @click="deleteItem(item)" small color="red lighten-1">
                    mdi-trash-can
                  </v-icon>
                </template>

              </v-data-table>
            </v-col>

          </v-row>




          <v-row>
            <v-col cols="12" md="6">
              <v-btn @click="updateOrCreate" :loading="isloading" rounded class="white--text" depressed color="pink darken-1">guardar</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

    </v-card>


  </v-container>
</template>

<script>
  import {
    paquetes_service
  } from '~/paquetes_service'

  import {
    paquete_directorio_rules
  } from '../mixins/paquete_directorio_rules'

  import {
    categoria_service
  } from '~/categoria_service'

  export default {
    mixins: [paquete_directorio_rules],

    data() {
      return {
        roles: ['COMPANY_ROLE', 'CONSULTANT_ROLE', 'HANDYMAN_ROLE', 'INTEREST_ROLE'],

        tipos: ['MONTH', 'YEAR'],

        categorias: [],

        sub_categorias: [],

        modalidades: ['FREE', 'OTHER'],

        paquete_item: {
          description: null,
          activated: true,
        },

        item: {
          id: null,
          items: [],
          packageType: 'MONTH',
          packageModality: 'OTHER',
          price: 0,
          title: null,
          description: null,
          duration: 1,
          status: true,
          top: false,
          businessCard: false,
          advertQuantity: 1,
          stripeId: null,
          environment: 'DIRECTORY',
          subCategory: null,
          category: null,
          type: 'COMPANY_ROLE',
          chat_enable: false,
          top: false,
          price_discount: 0,
          coupons: 0,
          booking_quantity: 0,
          service_quantity: 0,
          video_enable: false,
          business_enable: false,
          business_quantity: 0,
          combo_quantity: 0,
          combo: false,
          isItems: false,
          items_quantity: 0,
          payment_outside: true,
          payment_withinapp: false,
          qr_code: false,
        },

        headers: [{
            text: 'Descripción',
            value: 'description',
          },
          {
            text: 'Acciones',
            value: 'action',
            sortable: false
          },
        ],
      }
    },

    watch: {
      'item.category': {
        immediate: true,
        handler(n) {
          if (n) {
            this.getSubCategories(n)
          }
        }
      },
      'item.type': {
        immediate: true,
        handler(n) {
          if (n) {
            this.getCategories(n)
          }
        }
      }
    },

    created() {
      let id = this.$route.query.id;
      id ? this.getPaquete(id) : null
    },

    methods: {
      addItem() {
        if (this.paquete_item.is_editing) {
          let index = this.item.items.findIndex(x => x.id == this.paquete_item.id)
          this.$set(this.item.items, index, this.paquete_item)
        } else {
          this.item.items.push(this.paquete_item)
        }

        this.paquete_item = {
          description: null,
          activated: true
        }
      },

      setItem(item) {
        item.is_editing = true
        item.id = (new Date()).getTime()
        this.paquete_item = JSON.parse(JSON.stringify(item))
      },

      deleteItem(item) {
        this.item.items.splice(this.item.items.indexOf(item), 1)
      },

      getPaquete(id) {
        paquetes_service.get_package(id).then(res => {
          this.item = res.data
          this.$set(this.item, 'price_discount', 0)
          this.$set(this.item, 'coupons', 0)
          this.$set(this.item, 'business_quantity', 1)
          this.$set(this.item, 'combo_quantity', 1)
          this.$set(this.item, 'isItems', false)
          this.$set(this.item, 'items_quantity', 1)
        }, res => {
          this.$toast.error('Error consultando registro')
        })
      },

      updateOrCreate() {
        /*  if (!this.$refs.form.validate()) {
            return this.$toast.warn('Error de validando formulario')
          }*/
        this.item.id ? this.updatePackage() : this.savePackage()
      },

      savePackage() {
        paquetes_service.save_package(this.item).then(res => {
          this.$toast.sucs('Paquete guardado con exito')
          this.$router.push('/paquetes-directorio')
        }, res => {
          this.$toast.error('Error guardando servicio')
        })
      },

      updatePackage() {
        paquetes_service.update_package(this.item).then(res => {
          this.$toast.sucs('Paquete guardado con exito')
        }, res => {
          this.$toast.error('Error guardando servicio')
        })
      },

      getCategories(role) {
        categoria_service.get_categorias(role).then(res => {
          this.categorias = res.data
        }, res => {
          this.$toast.error('Error consultando registro')
        })
      },

      getSubCategories(categoria) {
        categoria_service.get_sub_categorias(categoria).then(res => {
          this.sub_categorias = res.data
        }, res => {
          this.$toast.error('Error consultando subcategorias')
        })
      },
    },
    computed: {
      isloading() {
        return this.$store.getters.getloading
      },
      errors() {
        return this.$store.getters.geterrors
      },
      user() {
        return JSON.parse(localStorage.getItem('user'))
      },
    }
  }
</script>