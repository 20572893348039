import store from "../store/store.js"

import {
  booking_package_service
} from '~/booking_package_service'

export const check_booking = {

  check_user_booking() {
    booking_package_service.get_package_booking('APP').then(res => {
      if (res.data.id) {
        store.dispatch('setPackage', res.data)
        localStorage.setItem('package', JSON.stringify(res.data))
      }
    })
  },
}