const modulo_package = {
  strict: false,

  state: {
    notifications: [],
    notification_lenght: 0
  },

  getters: {
    get_notifications: state => state.notifications,

    get_notifications_length: state => state.notification_lenght
  },

  mutations: {
    set_notificacions_lenght: (state, value) => state.notification_lenght = value,

    set_notificacions: (state, notifications) => state.notifications = notifications,

    add_notification: (state, notification) => state.notifications.unshift(notification),

    clear_notifications: (state, notification) => state.notifications = [],
  },

  actions: {
    setNotificacionsLenght: (context, value) => {
      context.commit('set_notificacions_lenght', value)
    },

    setNotifications: (context, notifications) => {
      context.commit('set_notificacions', notifications)
    },

    addNotification: (context, notification) => {
      context.commit('add_notification', notification)
    },

    clearNotifications: (context) => {
      context.commit('clear_notifications')
    },
  }
}

export default modulo_package;