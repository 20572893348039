<template id="">
  <v-dialog v-model="dialog" width="600px">

    <v-card>
      <v-card-title>

        <h2 class="mt-2">
          <strong class="pink--text text--darken-1">
            {{selected_pack.title}}
          </strong>
        </h2>


      </v-card-title>
      <v-card-text>

        <h2 class="mb-4 grey--text text--darken-1 font-weight-regular">
          {{selected_pack.description}}
        </h2>

        <h2 style="line-height:2rem;" class="font-weight-regular">
          <span class="pink--text text--darken-1">Incluye:</span>
          <br>
          Cantidad de anuncios: <strong class="">{{selected_pack.advertQuantity}}</strong>
          <br>
          Chat: <strong :class="setColor(selected_pack.chat_enable)">{{selected_pack.chat_enable | boolean_filter}}</strong>
          <br>
          Descuento: <strong>{{selected_pack.price_discount}}%</strong>
          <br>
          Cupones: <strong>{{selected_pack.coupons}}</strong>
          <br>
          Cantidad de bookings: <strong>{{selected_pack.booking_quantity}}</strong>
          <br>
          Cantidad de Servicios: <strong>{{selected_pack.service_quantity}}</strong>
          <br>
          Video llamada: <strong :class="setColor(selected_pack.video_enable)">{{selected_pack.video_enable | boolean_filter}}</strong>
          <br>
          Business Enable: <strong :class="setColor(selected_pack.business_enable)">{{selected_pack.business_enable | boolean_filter}}</strong>
          <br>
          Business Quantity: <strong>{{selected_pack.business_quantity}}</strong>
          <br>
          Combo Quantity: <strong>{{selected_pack.combo_quantity}}</strong>
          <br>
          Combo: <strong :class="setColor(selected_pack.combo)">{{selected_pack.combo | boolean_filter}}</strong>
          <br>
          Pagos fuera de la app: <strong :class="setColor(selected_pack.payment_outside)">{{selected_pack.payment_outside | boolean_filter}}</strong>
          <br>
          Código Qr: <strong :class="setColor(selected_pack.qr_code)">{{selected_pack.qr_code | boolean_filter}}</strong>
          <br>

        </h2>


      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="comprarPack()">
          Comprar
        </v-btn>
        <v-btn color="red darken-1" text @click="dialog = false">
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    data() {
      return {
        dialog: false
      }
    },

    created() {
      this.$parent.$on('open_modal', () => {
        this.dialog = true
      })
    },

    methods: {
      setColor(value) {
        return value ? 'green--text text--lighten-1' : 'red--text text--lighten-1'
      },
      comprarPack() {
        this.$router.push('/finalizar-compra')
      }
    },

    filters: {
      boolean_filter(value) {
        return value ? 'si' : 'no'
      }
    },

    computed: {
      selected_pack() {
        return this.$store.getters.get_selected_pack
      }
    }
  }
</script>