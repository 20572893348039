import FormLocation from '../componentes/FormLocation'
import ListaLocation from '../componentes/ListaLocation'

const routes = [
  ...route('/crear-business-location', FormLocation, {
    auth: true,
    //require_admin: true,
  }),
  ...route('/listar-business-location', ListaLocation, {
    auth: true,
    //require_admin: true,
  }),
]

function route(path, component = Default, meta = {}) {
  return [{
    path,
    component,
    meta
  }]
}

export default routes