import ListaUcsis from '../componentes/ListaUcsis'
import FormUcsis from '../componentes/FormUcsis'
import ListaServiceCenters from '../componentes/ListaServiceCenters'

const routes = [
  ...route('/ucsis-type', ListaUcsis, {
    auth: true,
    require_admin: true
  }),
  ...route('/crear-ucsis-type', FormUcsis, {
    auth: true,
    require_admin: true
  }),
  ...route('/lista-service-center', ListaServiceCenters, {
    auth: true,
    require_admin: true
  }),
]

function route(path, component = Default, meta = {}) {
  return [{
    path,
    component,
    meta
  }]
}

export default routes