import axios from 'axios'

export const location_service = {

  async save_busines_location(data) {
    return axios.post(`business-location`, data)
  },

  async update_busines_location(data) {
    return axios.put(`business-location/${data.id}`, data)
  },

  async get_busines_location(id) {
    return axios.get(`business-location/${id}`)
  },

  async get_busines_locations() {
    return axios.get(`list-business-location`)
  },

  async get_count() {
    return axios.get(`get-count-business-location`)
  },

  async get_user_busines_locations() {
    return axios.get(`/business-location-by-admin`)
  }

}