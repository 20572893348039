import ListaPacks from '../componentes/ListaPacks'
import ComprarPack from '../componentes/ComprarPack'
import Success from '../componentes/Success'

const routes = [
  ...route('/seleccionar-package', ListaPacks, {
    auth: true,
    require_consultant_handy: true
  }),
  ...route('/finalizar-compra', ComprarPack, {
    auth: true,
    require_consultant_handy: true
  }),
  ...route('/compra-realizada', Success, {
    auth: true,
    require_consultant_handy: true
  }),
]

function route(path, component = Default, meta = {}) {
  return [{
    path,
    component,
    meta
  }]
}

export default routes