export const config_user_service = {

  get_action_codes() {
    return [{
      id: 'Caso Siendo Revisado Activamente Por USCIS',
      count: 4
    }, {
      id: 'Entrevista Completada Y Caso Debe Ser Revisado ',
      count: 2
    }, {
      id: 'Emitió Decisión de Aprobación En El Caso',
      count: 5
    }, {
      id: 'Entrevista Fue Programada',
      count: 6
    }, {
      id: 'Caso Reabierto',
      count: 1
    }, {
      id: 'Caso Fue Aprobado',
      count: 47
    }, {
      id: 'Caso Fue Migrado Al Sistema ELIS',
      count: 1
    }, {
      id: 'Caso Recibido',
      count: 53
    }, {
      id: 'Caso No Fue Confirmado',
      count: 5
    }, {
      id: 'Caso Aprobado',
      count: 19
    }, {
      id: 'Caso Siendo Revisado Activamente Por USCIS',
      count: 149
    }, {
      id: 'Entrevista Programada ',
      count: 4
    }, {
      id: 'Caso Permanece Pendiente',
      count: 3
    }, {
      id: 'Se Emtitió El Certificado De Naturalización ',
      count: 1
    }, {
      id: 'Caso Confirmado',
      count: 15
    }]
  },

  get_form_nums() {
    return [{
      id: 'N-400',
      count: 10
    }, {
      id: 'I-130',
      count: 223
    }, {
      id: 'N-336',
      count: 1
    }, {
      id: 'I-824',
      count: 6
    }, {
      id: 'I-212',
      count: 1
    }, {
      id: 'I-601',
      count: 6
    }, {
      id: 'I-730',
      count: 1
    }, {
      id: 'I-131',
      count: 26
    }, {
      id: 'I-360',
      count: 18
    }, {
      id: 'I-821D',
      count: 8
    }, {
      id: 'I-290B',
      count: 1
    }, {
      id: 'I-134A',
      count: 369
    }, {
      id: 'I-192',
      count: 2
    }, {
      id: 'OS155A',
      count: 1
    }, {
      id: 'I-90',
      count: 3
    }, {
      id: 'I-485',
      count: 249
    }, {
      id: 'I-134',
      count: 9
    }, {
      id: 'I-589',
      count: 8
    }, {
      id: 'I-539',
      count: 2
    }, {
      id: 'I-129F',
      count: 38
    }, {
      id: 'I-140',
      count: 31
    }, {
      id: 'I-821',
      count: 5
    }, {
      id: 'I-751',
      count: 9
    }, {
      id: 'I-601A',
      count: 12
    }, {
      id: 'I-765',
      count: 146
    }, {
      id: 'I-129',
      count: 2
    }]
  }

}