import axios from 'axios'

export const calendar_service = {

  async save_user_calendar(data) {
    return axios.post(`calendar-service`, data)
  },

  async save_user_calendar_optional(data) {
    return axios.post(`calendar-service-optional`, data)
  },

  async get_calendar_by_service(service_id, date) {
    return axios.get(`get-calendar-by-service/${service_id}/${date}`)
  },

  async update_calendar_service(calendar_service_id, data) {
    return axios.put(`update-serviceHours/${calendar_service_id}`, data)
  },

  async delete_service_hour(service_hour_id, data) {
    return axios.delete(`delete-serviceHours/${service_hour_id}`, {
      data
    })
  },

}