import FormUploadFbLeads from '../componentes/FormUploadFbLeads'
import ListaFbLeads from '../componentes/ListaFbLeads'

const routes = [
  ...route('/upload-fb-leads', FormUploadFbLeads, {
    auth: true,
    require_admin: true
  }),
  ...route('/leads-calificados', ListaFbLeads, {
    auth: true,
    require_consultant_handy_package: true
  }),
]

function route(path, component = Default, meta = {}, props = true) {
  return [{
    path,
    component,
    meta,
    props
  }]
}

export default routes