<template>
  <v-container>
    <v-row dense>
      <v-col cols="12">
        <loader v-if="isloading"></loader>
        <h2 class="pink--text tex--darken-1">Compra realizada con exito... <span class="black--text">redireccionando en {{cuenta_regresiva}}</span> </h2>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import {
    booking_package_service
  } from '~/booking_package_service'

  export default {
    data() {
      return {
        //pk: 'pk_live_51M1a3FL7H3TGzFRCWXOLghqkeWj4Uti8wYYYP9cp0Qmcf1DBE3SehbVjr8BmoKHpqhhiVPPU2xoKLos6OEmvQpvk00G1cMIRQF',
        pk: 'pk_test_51M1a3FL7H3TGzFRCiOcWLNRR4VMTtty2uwT5EzCVpXc40IzV5BSzL3g3U9xbK8AkvtXa2gREcSI9znMaunK2SR9E00Apz7x2We',
        cuenta_regresiva: 5,
        intervalo: null,
        stripe: null
      }
    },

    mounted() {
      this.stripe = Stripe(this.pk)
      this.checkStatus()
      this.getBooking()
    },

    methods: {
      cuentaRegresiva() {
        var inter = setInterval(() => {
          this.cuenta_regresiva = this.cuenta_regresiva - 1
          if (this.cuenta_regresiva <= 1) {
            this.$router.push('/').catch(() => {});
            clearInterval(inter)
          }
        }, 1000);
      },

      getBooking() {
        booking_package_service.get_package_booking('APP').then(res => {
          if (res.data.id) {
            this.$store.dispatch('setPackage', res.data)
            localStorage.setItem('package', JSON.stringify(res.data))
            this.cuentaRegresiva()
          }
        }, res => {
          this.$toast.error('Error consultando package')
        })
      },

      async checkStatus() {
        let clientSecret = this.$route.query.payment_intent_client_secret

        if (!clientSecret) {
          return this.$toast.error('algo ha salido mal!')
        }

        const {
          paymentIntent
        } = await this.stripe.retrievePaymentIntent(clientSecret)

        switch (paymentIntent.status) {
          case "succeeded":
            this.$toast.sucs('Pago procesado con exito!')
            this.getBooking()
            break;
          case "processing":
            this.$toast.warn('su pago esta siendo procesado.')
            break;
          case "requires_payment_method":
            this.$toast.error('Su pago no se ha procesado, por favor intente de nuevo.')
            break;
          default:
            this.$toast.error('Algo ha salido mal.')
            break;
        }
      },
    },

    computed: {
      isloading() {
        return this.$store.getters.getloading
      },
      user() {
        return JSON.parse(localStorage.getItem('user'))
      },
    }
  }
</script>