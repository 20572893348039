export const directorio_rules = {
  data() {
    return {
      valid: true,
      required_rule: [
        v => !!v || 'Requerido',
      ],
      email_rules: [
        v => !!v || 'Requerido',
        v => /.+@.+\..+/.test(v) || 'E-mail debe ser valido',
      ],
      title_rule: [
        v => !!v || 'Requerido',
        v => (v && v.length <= 200) || 'Supera el limite de 200',
      ],
      description_rule: [
        v => !!v || 'Requerido',
        v => (v && v.length <= 300) || 'Supera el limite de 300',
      ],
      duration_rules: [
        v => !!v || 'Requerido'
      ],
      advert_rules: [
        v => !!v || 'Requerido'
      ],
      categoria_rule: [
        v => !!v || 'Requerido'
      ],
      subcategoria_rule: [
        v => !!v || 'Requerido'
      ],
      location_rule: [
        v => !!v || 'Requerido'
      ],
      web_site_rule: [
        v => !v || (v && v.length > 0 && /^$|[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi.test(v)) || "Debe introducir una url válida"
      ],
      video_rule: [
        v => !v || (v && v.length > 0 && v.includes('youtube')) || "Debe introducir un link valido"
      ],
      number_rule: [
        v => Number.isInteger(Number(v)) || 'valor debe ser un numero entero'
      ],
    }
  },
}