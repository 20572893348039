<template>
  <v-container>

    <v-card>
      <v-toolbar flat color="blue accent-2" class="white--text">
        <v-btn to="/productos-app" icon text>
          <v-icon small class="white--text">mdi-arrow-left-bold</v-icon>
        </v-btn>
        <v-toolbar-title>
          <h3 class="font-weight-light">Crear / Editar producto</h3>
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <loader v-if="isloading"></loader>

        <v-form ref="form" v-model="valid" lazy-validation>

          <v-row class="mt-3" dense>
            <v-col cols="12" md="6">
              <v-text-field v-model="item.name" :rules="title_rule" small dense outlined label="Nombre" type="text"></v-text-field>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" md="6">
              <v-textarea v-model="item.description" :rules="description_rule" small dense outlined label="Descripción" type="text"></v-textarea>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" md="3">
              <v-text-field v-model="item.price" :rules="price_rule" small dense outlined label="Precio" type="number" step="0.1"></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field v-model="item.priceOffer" :rules="price_rule" small dense outlined label="Oferta" type="number" step="0.1"></v-text-field>
            </v-col>
          </v-row>


          <v-row dense>
            <v-col cols="12" md="3">
              <v-text-field v-model="item.periodTime" small dense outlined label="Periodo Tiempo"></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field v-model="item.textOffer" small dense outlined label="Texto Oferta"></v-text-field>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" md="3">
              <v-text-field v-model="item.productId" small dense outlined label="Producto ID"></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field v-model="item.nroTickets" type="number" step="1" small dense outlined label="Nro Tickets"></v-text-field>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" md="3">
              <v-checkbox class="mt-0 " v-model="item.isActive" label="Activo"></v-checkbox>
            </v-col>

            <v-col cols="12" md="3">
              <v-checkbox class="mt-0 " v-model="item.subscription" label="Subscripción"></v-checkbox>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" md="6">
              <h3 class="mb-3">Beneficios</h3>

              <v-row dense justify="center">
                <v-col cols="12" md="8">
                  <v-text-field v-model="beneficio.description" hide-details small dense outlined label="Descripción"></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-btn rounded @click="saveBeneficio" class="white--text" color="green">
                    {{isEditing ? 'Guardar' : 'Agregar'}}
                  </v-btn>
                </v-col>
              </v-row>

              <v-data-table :headers="headers" :items="item.benefits" disable-pagination hide-default-footer item-key="key" class="elevation-1 mt-6">


                <template v-slot:item.action="{ item }">

                  <v-icon @click="setBeneficio(item)" small class="mr-2" color="blue">
                    mdi-pencil
                  </v-icon>

                  <v-icon @click="deleteBeneficio(item)" small color="red lighten-1">
                    mdi-trash-can
                  </v-icon>
                </template>

              </v-data-table>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <v-btn @click="updateOrCreate" :loading="isloading" rounded class="white--text" depressed color="pink darken-1">guardar</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

    </v-card>


  </v-container>
</template>

<script>
  import {
    productos_service
  } from '~/productos_service'

  import {
    paquete_directorio_rules
  } from '../mixins/paquete_directorio_rules'

  export default {
    mixins: [paquete_directorio_rules],

    data() {
      return {
        item: {
          name: '',
          description: '',
          price: 0,
          priceOffer: 0,
          isActive: true,
          benefits: [],
          productId: null,
          periodTime: null,
          textOffer: null,
          nroTickets: 0,
          subscription: false
        },
        beneficio: {
          description: null
        },
        isEditing: false,
        index: 0,
        headers: [{
            text: 'Descripción',
            value: 'description',
          },
          {
            text: 'Acciones',
            value: 'action',
          }
        ]
      }
    },


    created() {
      this.$route.query.id ? this.getProducto(this.$route.query.id) : null
    },

    methods: {
      getProducto(id) {
        productos_service.get_producto(id).then(res => {
          this.item = res.data
        }, res => {
          this.$toast.error('Error consultando registro')
        })
      },

      updateOrCreate() {
        if (!this.$refs.form.validate()) {
          return this.$toast.warn('Error de validando formulario')
        }
        this.item.id ? this.updateProducto() : this.saveProducto()
      },

      saveProducto() {
        productos_service.save_producto(this.item).then(res => {
          this.$toast.sucs('Producto guardado con exito')
          this.$router.push('productos-app')
        }, res => {
          this.$toast.error('Error guardando servicio')
        })
      },

      updateProducto() {
        productos_service.update_producto(this.item).then(res => {
          this.$toast.sucs('Producto actualizado con exito')
          this.$router.push('productos-app')
        }, res => {
          this.$toast.error('Error guardando servicio')
        })
      },

      saveBeneficio() {
        if (this.beneficio.description == null || this.beneficio.description == '' || !this.beneficio.description.trim().length) {
          return this.$toast.warn('Campo no puede estar vacio')
        }
        if (this.isEditing) {
          this.$set(this.item.benefits, this.index, this.beneficio)
        } else {
          this.item.benefits.push(this.beneficio)
        }

        this.beneficio = {
          description: null
        }
        this.isEditing = false
      },

      setBeneficio(item) {
        this.isEditing = true
        this.index = this.item.benefits.indexOf(item)
        this.beneficio = JSON.parse(JSON.stringify(item))
      },

      deleteBeneficio(item) {
        this.item.benefits.splice(this.item.benefits.indexOf(item), 1)
      }
    },
    computed: {
      isloading() {
        return this.$store.getters.getloading
      },
      errors() {
        return this.$store.getters.geterrors
      },
      user() {
        return JSON.parse(localStorage.getItem('user'))
      },
    }
  }
</script>