<template>
  <v-container>

    <v-card>
      <v-toolbar flat color="blue accent-2" class="white--text">
        <v-btn to="/" icon text>
          <v-icon small class="white--text">mdi-home</v-icon>
        </v-btn>
        <v-toolbar-title>
          <h3 class="font-weight-light">Categorias</h3>
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-row dense>

          <v-col cols="12" md="4">
            <v-select small dense outlined v-model="categoria.type" :items="roles" label="Rol"></v-select>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field small dense v-model="categoria.name" outlined label="Nombre (actual)"></v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <v-file-input ref="fileinput" small dense outlined @change="onFileChange" accept="image/*" label="Imagen"></v-file-input>
          </v-col>



          <!--langs-->
          <v-col cols="12" md="4">
            <v-text-field color="green" small dense v-model="categoria.name_es" outlined label="Nombre ES"></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field color="orange" small dense v-model="categoria.name_en" outlined label="Nombre EN"></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field color="blue" small dense v-model="categoria.name_pt" outlined label="Nombre PT"></v-text-field>
          </v-col>
          <!--endlangs-->

          <v-col cols="12" md="8">
            <v-text-field color="green" small dense v-model="categoria.description_es" outlined label="Descripción ES"></v-text-field>
          </v-col>

          <v-col cols="12" md="8">
            <v-text-field color="orange" small dense v-model="categoria.description_en" outlined label="Descripción EN"></v-text-field>
          </v-col>

          <v-col cols="12" md="8">
            <v-text-field color="blue" small dense v-model="categoria.description_pt" outlined label="Descripción PT"></v-text-field>
          </v-col>


          <v-col align="center" justify="center" cols="12" md="4">
            <v-checkbox class="mt-1" v-model="categoria.certificate" label="Certificado"></v-checkbox>
          </v-col>


        </v-row>

        <v-row>
          <v-col cols="12" md="4">
            <v-btn @click="updateOrCreate" rounded class="white--text" depressed color="blue accent-2">guardar</v-btn>
            <v-btn @click="nuevo" rounded class="white--text ml-4" depressed color="success">nuevo</v-btn>

          </v-col>
        </v-row>

      </v-card-text>

    </v-card>

    <loader v-if="isloading"></loader>

    <v-row dense>
      <v-col cols="12">
        <v-data-table :headers="headers" :items="categorias" disable-pagination hide-default-footer item-key="key" class="elevation-1 mt-6">

          <template v-slot:item.name_es="{ item }">
            <span>{{ item.name_es ? item.name_es : item.name }}</span>
          </template>

          <template v-slot:item.image="{ item }">
            <img class="mt-2 mb-2" height="50" width="50" :src="`${$awsPath}/${item.image}`" alt="">
          </template>


          <template v-slot:item.action="{ item }">

            <router-link :to="`sub-categorias/${item.id}`">
              <v-icon small class="mr-2" color="orange">
                mdi-file-tree
              </v-icon>
            </router-link>

            <v-icon @click="setItem(item)" small class="mr-2" color="blue">
              mdi-pencil
            </v-icon>
            <v-icon @click="deleteCategoria(item)" small color="red lighten-1">
              mdi-trash-can
            </v-icon>
          </template>

        </v-data-table>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
  import {
    categoria_service
  } from '~/categoria_service'

  export default {

    data() {
      return {
        roles: ['COMPANY_ROLE', 'CONSULTANT_ROLE', 'HANDYMAN_ROLE', 'INTEREST_ROLE'],

        categorias: [],

        upload: new FormData(),

        headers: [{
            text: 'Image',
            value: 'image',
          },
          {
            text: 'Name',
            value: 'name_es',
          },
          {
            text: 'Acciones',
            value: 'action',
            sortable: false
          },
        ],
      }
    },

    watch: {
      'categoria.type': {
        immediate: true,
        handler(n, o) {
          this.getCategories(n)
        }
      }
    },

    methods: {
      nuevo() {
        let type = this.categoria.type
        this.$refs.fileinput.reset()
        this.$store.dispatch('setCategoria', {
          id: null,
          name_es: null,
          name_en: null,
          name_pt: null,
          image: null,
          description: null,
          description_es: null,
          description_en: null,
          description_pt: null,
          type: type,
          certificate: false,
          enableCertificate: false,
          enableDocument: false,
          enableIdCard: false,
        })
      },

      getCategories(role) {
        categoria_service.get_categorias(role).then(res => {
          this.categorias = res.data
        }, res => {
          this.$toast.error('Error consultando registro')
        })
      },

      updateOrCreate() {
        this.categoria.id ? this.updateCategoria() : this.saveCategoria()
      },

      updateCategoria() {
        this.axios.put(`category/${this.categoria.id}`, this.categoria).then(res => {
          this.uploadImage(res.data)
          let find = this.categorias.findIndex(x => x.id == res.data.id)
          this.$set(this.categorias, find, res.data)
          this.nuevo()
        }, res => {
          this.$toast.error('Error consultando registro')
        })
      },

      saveCategoria() {
        this.axios.post(`category`, this.categoria).then(res => {
          this.$toast.sucs('Categoria guardada con exito')
          this.uploadImage(res.data)
          this.categorias.push(res.data)
          this.nuevo()
        }, res => {
          this.$toast.error('Error consultando registro')
        })
      },

      deleteCategoria(item) {
        this.axios.delete(`category/${item.id}`).then(res => {
          this.$toast.sucs('Categoria eliminada con exito')
          let find = this.categorias.findIndex(x => x.id == item.id)
          this.categorias.splice(find, 1)
        }, res => {
          this.$toast.error('Error eliminando categoria')
        })
      },

      uploadImage(categoria) {
        if (!this.upload.has('img')) {
          return null
        }
        this.axios.post(`upload/category/${categoria.id}`, this.upload, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(res => {
          this.$toast.sucs('Imagen cargada con exito')
        }, res => {})
      },

      onFileChange(file) {
        this.upload.append('img', file)
      },

      setItem(item) {
        this.$store.dispatch('setCategoria', JSON.parse(JSON.stringify(item)))
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
        //this.categoria = JSON.parse(JSON.stringify(item))
      }
    },
    computed: {
      isloading() {
        return this.$store.getters.getloading
      },
      errors() {
        return this.$store.getters.geterrors
      },
      user() {
        return JSON.parse(localStorage.getItem('user'))
      },
      categoria() {
        return this.$store.getters.get_categoria
      }
    }
  }
</script>