import axios from 'axios'

export const paquetes_service = {

  async get_packages(slug) {
    return axios.get(`get-packages-by-category-slug/${slug}`)
  },

  async get_package(id) {
    return axios.get(`packages-directory/${id}`)
  },

  async save_package(data) {
    return axios.post(`packages-directory`, data)
  },

  async update_package(data) {
    return axios.put(`packages-directory/${data.id}`, data)
  },
}