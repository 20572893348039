<template>
  <v-container>

    <reporte-ucsis :total_accounts="total_accounts" :total_ucsis="total_ucsis" :porcentaje="porcentaje" :total_purchases="total_purchases"> </reporte-ucsis>

    <v-card>
      <v-toolbar flat color="blue accent-2" class="white--text">
        <v-btn to="/" icon text>
          <v-icon small class="white--text">mdi-home</v-icon>
        </v-btn>
        <v-toolbar-title>
          <h3 class="font-weight-light">Reportes</h3>
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>

        <loader v-if="isloading"></loader>

        <!--
          colaborador@inmigrausa.com
          inmigrausa2023
        -->

        <v-row dense>
          <v-col cols="12" md="4">
            <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">

              <template v-slot:activator="{ on, attrs }">
                <v-text-field hide-details v-model="range" label="Rango fechas" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined small dense></v-text-field>
              </template>

              <v-date-picker range v-model="range" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(date)">
                  OK
                </v-btn>
              </v-date-picker>

            </v-menu>
          </v-col>

          <v-col cols="12">
            <apexchart height="340" @click="clickHandler" ref="chart" width="100%" type="bar" :options="options" :series="series" :color="options.colors"></apexchart>
          </v-col>

        </v-row>
      </v-card-text>

    </v-card>

    <modal-publicidad v-on:reload_reporte="reloadReporte"></modal-publicidad>
    <lista-accounts :list_accounts="list_accounts"></lista-accounts>

  </v-container>
</template>

<script>
  import _ from 'lodash';

  import moment from 'moment'

  import {
    reportes_service
  } from '~/reportes_service'

  import reporteUcsis from './reporteUcsis'
  import modalPublicidad from './modalPublicidad'
  import listaAccounts from './listaAccounts'

  export default {
    components: {
      reporteUcsis,
      modalPublicidad,
      listaAccounts
    },

    data() {
      return {
        options: {
          chart: {
            id: 'vuechart-example'
          },
          xaxis: {
            categories: []
          },
          dataLabels: {
            enabled: false
          },
          colors: ['#26c6da', '#f35145', '#00c853', '#3d5afe']
        },

        series: [],

        costo_set: new Map(),

        query: {
          startDate: null,
          endDate: null,
        },
        list_accounts: [],

        range: [moment().startOf('month').format('YYYY-MM-DD'), moment().add(1, 'days').format('YYYY-MM-DD')],
        test_data: {},
        data: null,
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menu: false,
        total_accounts: 0,
        total_ucsis: 0,
        porcentaje: 0,
        total_purchases: 0,
        dates_without_format: [],

        date_values: {
          accounts: 0,
          ucsis: 0,
          purchases: 0,
          publicidad: 1
        }

      }
    },

    watch: {
      'range': {
        immediate: true,
        handler(n) {
          if (n[0] && n[1]) {
            this.query.startDate = n[0]
            this.query.endDate = n[1]
          }
        }
      },

      'query': {
        immediate: true,
        deep: true,
        handler(n) {
          if (n.startDate && n.endDate) {
            this.getReportes(n)
          }
        }
      }
    },

    mounted() {

    },

    methods: {
      reloadReporte() {
        this.getReportes(this.query)
      },

      getReportes(n) {
        reportes_service.get_user_report(n).then(res => {
          if (res.data) {
            this.test_data = res.data
            this.list_accounts = res.data.list_accounts
          }
          this.prepateData(res.data)
          this.fillSet(res.data.costo)
        }, res => {
          this.$toast.error('Error consultando posts')
        })
      },

      fillSet(data) {
        data.forEach((item, i) => {
          this.costo_set.set(item._id, item)
        })
      },

      clickHandler(event, chartContext, config) {
        let position = config.dataPointIndex
        let fecha = this.dates_without_format[position]
        let parsed_date = this.options.xaxis.categories[position]

        let publicidad = this.costo_set.has(fecha) ? this.costo_set.get(fecha).count : 1

        this.$emit('open', {
          accounts: this.series[0].data[position],
          ucsis: this.series[1].data[position],
          purchases: this.series[2].data[position],
          publicidad: publicidad,
          date: fecha
        })
      },

      totalCount(array) {
        return array.reduce(function(acc, obj) {
          return acc + obj.count;
        }, 0);
      },

      getLabels(array) {
        return array.map(x => moment(x).format('dd, Do MMM YYYY'))
      },

      returnDates(array) {
        return array.map(x => x._id)
      },

      refreshChart() {
        this.series = []
        this.options.xaxis.categories = []
        this.$refs.chart.refresh();
      },

      prepateData(data) {
        this.refreshChart()
        this.total_accounts = this.totalCount(data.total_accounts)
        this.total_ucsis = this.totalCount(data.total_ucsis)
        this.total_purchases = this.totalCount(data.purchases)
        this.porcentaje = parseFloat((this.total_ucsis * 100) / this.total_accounts).toFixed(2)

        let dates = [
          ...this.returnDates(data.total_accounts),
          ...this.returnDates(data.total_ucsis),
          ...this.returnDates(data.purchases),
          ...this.returnDates(data.costo)
        ]

        dates.sort((d1, d2) => new Date(d1).getTime() - new Date(d2).getTime())

        let unique_dates = _.uniq(dates)
        this.dates_without_format = unique_dates

        this.addSeries('Usuarios', unique_dates, data.total_accounts)
        this.addSeries('Uscis', unique_dates, data.total_ucsis)
        this.addSeries('Purchases', unique_dates, data.purchases)
        this.addSeries('Costo', unique_dates, data.costo)

        let all_labels = this.getLabels(dates)

        this.options.xaxis.categories = _.uniq(all_labels)
        this.$refs.chart.refresh();
      },

      addSeries(serie_name, unique_dates, data_array) {
        let serie = []
        unique_dates.forEach((item, i) => {
          let find_index = data_array.findIndex(x => x._id == item)

          if (find_index > -1) {
            serie.push(data_array[find_index].count)
          } else {
            serie.push(0)
          }
        })

        this.series.push({
          name: serie_name,
          data: serie
        })

      },

    },
    computed: {
      isloading() {
        return this.$store.getters.getloading
      }
    }
  }
</script>