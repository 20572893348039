<template>
  <v-dialog v-model="dialog" persistent max-width="520">
    <v-card>
      <v-card-title class="text-h5">
        Asignar Ticket
      </v-card-title>

      <v-card-text>
        <v-row class="mt-2" dense>
          <v-col cols="12" md="8">
            <v-select v-model="ticket.to" :items="users" item-text="name" item-value="id" outlined small dense label="Asignar a usuario"></v-select>
          </v-col>

          <v-col cols="12" md="4">
            <v-chip class="white--text mt-1 ml-1" :color="ticket.status == 'OPEN' ? 'green' : 'red lighten-1'">
              {{ticket.status}}
            </v-chip>
          </v-col>
        </v-row>

        <v-row class="mt-1" dense>
          <v-col cols="12" md="4">
            <v-text-field :value="ticket.numeroTicket" outlined dense small label="Nro Ticket" disabled></v-text-field>
          </v-col>
          <v-col cols="12" md="8">
            <v-text-field :value="ticket.created_date | fecha" outlined dense small label="Creado el" disabled></v-text-field>
          </v-col>
        </v-row>


        <v-row dense>
          <v-col cols="12">
            <v-text-field :value="ticket.userId ? `${ticket.userId.name} ${ticket.userId.last_name}` : 'N/A'" outlined dense small label="Usuario" disabled></v-text-field>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12">
            <v-text-field :value="ticket.asunto" outlined dense small label="Asunto" disabled></v-text-field>
          </v-col>

          <v-col cols="12">
            <template v-if="ticket.chats.length > 0">
              <v-textarea hide-details auto-grow rows="3" row-height="15" disabled :value="ticket.chats[0].message" outlined label="Mensaje"></v-textarea>
            </template>
            <template v-else>
              <v-alert outlined dense border="left" type="warning">
                Usuario no envio mensaje
              </v-alert>
            </template>
          </v-col>

        </v-row>

      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn rounded depressed color="green" class="white--text" @click="asignarUsuario">
          Asignar ticket
        </v-btn>
        <v-btn rounded depressed class="white--text" color="red lighten-1" @click="dialog = false">
          cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import {
    ticket_service
  } from '~/ticket_service'

  import moment from 'moment'

  export default {
    props: ['users'],

    data() {
      return {
        dialog: false,
        ticket: {
          to: null,
          id: null,
          userId: {
            name: "N/A",
          },
          message: null,
          closedBy: null,
          asunto: null,
          status: null,
          chats: [{
            message: null
          }],
          closed_date: null,
          created_date: null,
          updated_date: null,
          numeroTicket: 7
        },
      };
    },

    created() {
      this.$parent.$on('open_asignar', data => {
        this.dialog = true
        if (!data.to) {
          this.$set(data, 'to', null)
        }
        this.ticket = JSON.parse(JSON.stringify(data))
      });
    },

    methods: {
      asignarUsuario() {
        let data = {
          id: this.ticket.id,
          to: this.ticket.to
        }
        ticket_service.asignar_ticket(data).then(res => {
          this.$toast.sucs('¡ticket asignado con exito!')
          this.$emit('update_ticket', res.data)
          this.dialog = false
        }, res => {
          this.$toast.error('¡error asignando tickets!')
        })
      },
      confirm() {
        this.dialog = false;
        this.$emit("delete");
      },
    },

    filters: {
      fecha(value) {
        return moment(value).format('dddd D MMMM Y')
      }
    }

  };
</script>