<template>
  <v-container>

    <v-card>
      <v-toolbar flat color="blue accent-2" class="white--text">
        <v-toolbar-title>
          <h3 class="font-weight-light">Uscis</h3>
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <loader v-if="isloading"></loader>


        <v-row dense>
          <v-col cols="12" md="4">
            <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">

              <template v-slot:activator="{ on, attrs }">
                <v-text-field hide-details v-model="range" label="Rango fechas" prepend-icon="mdi-calendar" append-icon="mdi-close" @click:append="clearRange" readonly v-bind="attrs" v-on="on" outlined small dense></v-text-field>
              </template>

              <v-date-picker range v-model="range" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(date)">
                  OK
                </v-btn>
              </v-date-picker>

            </v-menu>
          </v-col>
          <v-col cols="12" md="3">
            <v-select v-model="filter.status" :items="status" hide-details outlined small dense label="status"></v-select>
          </v-col>

          <v-col cols="12" md="3">
            <v-text-field :value="uscis.length" disabled outlined small dense hide-details label=""></v-text-field>
          </v-col>


          <v-col cols="12">
            <apexchart height="340" ref="chart" width="100%" type="bar" :options="options" :series="series" :color="options.colors"></apexchart>
          </v-col>

          <v-col cols="12">

            <v-data-table :headers="headers" :items="uscis.cases" disable-pagination hide-default-footer item-key="key" class="elevation-1 mt-6">

              <template v-slot:item.receiptNum="{item}">
                <span>{{ item.receiptNum | masked }}</span>
              </template>

              <template v-slot:item.ownerId="{item}">
                <v-icon v-if="item.ownerId" @click="$emit('open_user_modal', item.ownerId)" color="green" class="white--text">
                  mdi-account-card-details
                </v-icon>
              </template>

            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>

    </v-card>

    <modal-user></modal-user>

  </v-container>
</template>

<script>
  import {
    reportes_service
  } from '~/reportes_service'

  import modalUser from './modalUser'

  import _ from 'lodash'

  import moment from 'moment'

  export default {

    components: {
      modalUser
    },

    data() {
      return {
        chart_data: null,

        range: [moment().startOf('month').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],

        uscis: {
          cases: [],
          length: 0
        },

        status: ['approved', 'pending', 'denied'],

        filter: {
          status: 'approved',
          gte: null,
          lt: null,
        },

        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

        menu: false,

        headers: [{
            text: 'Nro. Caso',
            value: 'receiptNum',
          },
          {
            text: 'Nro. Actualizaciones',
            value: 'count',
          },
          {
            text: 'Usuario',
            value: 'ownerId',
          }
        ],

        series: [],

        options: {
          chart: {
            id: 'vuechart-example'
          },
          xaxis: {
            categories: []
          },
          dataLabels: {
            enabled: false
          },
          colors: ['#26c6da']
        },

      }
    },

    watch: {
      'range': {
        immediate: true,
        handler(n) {
          if (n[0] && n[1]) {
            this.filter.gte = n[0]
            this.filter.lt = n[1]
          }
          if (n[0] == null && n[1] == null) {
            this.filter.gte = null
            this.filter.lt = null
          }
        }
      },

      'filter': {
        immediate: true,
        deep: true,
        handler(query) {
          this.getReportes(query)
        }
      }
    },

    filters: {
      masked(value) {
        return `${value.substring(0,3)}*********`
      }
    },

    methods: {
      getReportes(n) {
        reportes_service.get_uscis_cases(n).then(res => {
          this.uscis = res.data
          this.makeChartData(res.data.cases)
        }, res => {
          this.$toast.error('Error consultando usuario')
        })
      },
      clearRange() {
        this.$refs.menu.save([])
        this.$set(this.range, 0, null)
        this.$set(this.range, 1, null)
      },

      makeChartData(data) {
        let full_parse = true
        if (this.range[0] == null && this.range[1] == null) {
          full_parse = false
          data = data.map(x => {
            return {
              firstTime: x.firstTime.substring(0, 7)
            }
          })
        }

        let sort_data = _.sortBy(data, 'firstTime')

        let result = _.countBy(sort_data, 'firstTime')

        let labels = Object.keys(result).map(x => moment(x).format(full_parse ? 'dd, Do MMM YYYY' : 'MMM YYYY'))
        let values = Object.values(result)

        this.chart_data = {
          labels: labels,
          values: values
        }

        this.series = []

        this.series.push({
          name: 'Cases',
          data: values
        })

        this.options.xaxis.categories = labels
        this.$refs.chart.refresh();

      }
    },
    computed: {
      isloading() {
        return this.$store.getters.getloading
      }
    }
  }
</script>