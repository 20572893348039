import axios from 'axios'

export const advert_service = {

  async get_adverts() {
    return axios.get(`list-adverts`)
  },

  async get_advert(id) {
    return axios.get(`adverts/${id}`)
  },

  async save_advert(data) {
    return axios.post(`adverts`, data)
  },

  async update_advert(data) {
    return axios.put(`adverts/${data.id}`, data)
  },

  async delete_advert(id) {
    return axios.delete(`adverts/${id}`)
  },
}