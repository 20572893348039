<template>
  <v-container>

    <v-card>
      <v-toolbar flat color="blue accent-2" class="white--text">
        <v-btn to="/paquetes-app" icon text>
          <v-icon small class="white--text">mdi-arrow-left-bold</v-icon>
        </v-btn>
        <v-toolbar-title>
          <h3 class="font-weight-light">Crear / Editar paquete</h3>
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <loader v-if="isloading"></loader>

        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row class="mt-3" dense>

            <v-col cols="12" md="4">
              <v-select small dense outlined hide-details v-model="item.type" :items="roles" label="Rol"></v-select>
            </v-col>

            <v-col cols="12" md="4">
              <v-select v-model="item.category" small dense outlined :items="categorias" item-text="name" item-value="id" label="Categoria"></v-select>
            </v-col>

            <v-col cols="12" md="4">
              <v-select v-model="item.subCategory" small dense outlined :items="sub_categorias" item-text="name" item-value="id" label=" Subcategoria"></v-select>
            </v-col>

          </v-row>

          <v-row class="mt-3" dense>

            <v-col cols="12" md="4">
              <v-select v-model="item.packageModality" small dense outlined :items="modalidades" label="Modalidad"></v-select>
            </v-col>

            <v-col cols="12" md="4">
              <v-select v-model="item.packageType" small dense outlined :items="tipos" label="Tipo"></v-select>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field v-model="item.price" :rules="price_rule" small dense outlined label="Precio" type="number" step="0.1"></v-text-field>
            </v-col>

          </v-row>

          <v-row dense>

            <v-col cols="12" md="4">
              <v-text-field v-model="item.title" :rules="title_rule" small dense outlined label="Titulo"></v-text-field>
            </v-col>

            <v-col cols="8">
              <v-text-field v-model="item.description" :rules="description_rule" small dense outlined label="Descripción corta"></v-text-field>
            </v-col>

          </v-row>

          <v-row dense>
            <v-col cols="12" md="3">
              <v-text-field v-model="item.advertQuantity" :rules="number_rule" type="number" step="1" small dense outlined label="Cantidad Anucios"></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field v-model="item.booking_quantity" :rules="number_rule" type="number" step="1" small dense outlined label="Cantidad Bookings"></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field v-model="item.service_quantity" :rules="number_rule" type="number" step="1" small dense outlined label="Cantidad Servicios"></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field v-model="item.business_quantity" :rules="number_rule" :disabled="!item.business_enable" type="number" step="1" small dense outlined label="Cantidad Business"></v-text-field>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" md="3">
              <v-text-field v-model="item.combo_quantity" :rules="number_rule" :disabled="!item.combo" type="number" step="1" small dense outlined label="Cantidad Combos"></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field v-model="item.items_quantity" :rules="number_rule" :disabled="!item.isItems" type="number" step="1" small dense outlined label="Cantidad Items"></v-text-field>
            </v-col>
          </v-row>


          <v-row dense>
            <v-col cols="12" md="3">
              <v-checkbox class="mt-1" v-model="item.chat_enable" label="Chat"></v-checkbox>
            </v-col>

            <v-col cols="12" md="3">
              <v-checkbox class="mt-1" v-model="item.top" label="Top"></v-checkbox>
            </v-col>

            <v-col cols="12" md="3">
              <v-checkbox class="mt-1" v-model="item.video_enable" label="Video"></v-checkbox>
            </v-col>

            <v-col cols="12" md="3">
              <v-checkbox class="mt-1" v-model="item.business_enable" label="Business"></v-checkbox>
            </v-col>

            <v-col cols="12" md="3">
              <v-checkbox class="mt-1" v-model="item.combo" label="Combo"></v-checkbox>
            </v-col>

            <v-col cols="12" md="3">
              <v-checkbox class="mt-1" v-model="item.isItems" label="Items"></v-checkbox>
            </v-col>

            <v-col cols="12" md="3">
              <v-checkbox class="mt-1" v-model="item.payment_outside" label="Pagos outside app"></v-checkbox>
            </v-col>

            <v-col cols="12" md="3">
              <v-checkbox class="mt-1" v-model="item.payment_withinapp" label="Pagos inside app"></v-checkbox>
            </v-col>

            <v-col cols="12" md="3">
              <v-checkbox class="mt-1" v-model="item.qr_code" label="Código Qr"></v-checkbox>
            </v-col>
          </v-row>




          <v-row>
            <v-col cols="12" md="6">
              <v-btn @click="updateOrCreate" :loading="isloading" rounded class="white--text" depressed color="pink darken-1">guardar</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

    </v-card>


  </v-container>
</template>

<script>
  import {
    paquetes_service
  } from '~/paquetes_service'

  import {
    categoria_service
  } from '~/categoria_service'

  import {
    paquete_directorio_rules
  } from '../mixins/paquete_directorio_rules'

  export default {
    mixins: [paquete_directorio_rules],

    data() {
      return {
        roles: ['COMPANY_ROLE', 'CONSULTANT_ROLE', 'HANDYMAN_ROLE', 'INTEREST_ROLE'],

        categorias: [],

        sub_categorias: [],

        tipos: ['MONTH', 'YEAR', 'DAY'],

        modalidades: ['FREE', 'OTHER'],

        paquete_item: {
          description: null,
          activated: true,
        },

        item: {
          id: null,
          items: [],
          packageType: 'MONTH',
          packageModality: 'FREE',
          price: 0,
          title: null,
          description: null,
          duration: 30,
          status: true,
          businessCard: false,
          advertQuantity: 1,
          stripeId: null,
          environment: 'APP',
          subCategory: null,
          category: null,
          type: null,
          chat_enable: false,
          top: false,
          price_discount: 0,
          coupons: 0,
          booking_quantity: 0,
          service_quantity: 0,
          video_enable: false,
          business_enable: false,
          business_quantity: 0,
          combo_quantity: 0,
          combo: false,
          isItems: false,
          items_quantity: 0,
          payment_outside: true,
          payment_withinapp: false,
          qr_code: false,
        },


      }
    },

    watch: {
      'item.category'(n, o) {
        this.getSubCategories(n)
      },
      'item.type'(n, o) {
        this.getCategories(n)
      }
    },

    created() {
      this.getCategories()
      let id = this.$route.query.id;
      id ? this.getPaquete(id) : null
    },

    methods: {
      addItem() {
        if (this.paquete_item.is_editing) {
          let index = this.item.items.findIndex(x => x.id == this.paquete_item.id)
          this.$set(this.item.items, index, this.paquete_item)
        } else {
          this.item.items.push(this.paquete_item)
        }

        this.paquete_item = {
          description: null,
          activated: true
        }
      },

      setItem(item) {
        item.is_editing = true
        item.id = (new Date()).getTime()
        this.paquete_item = JSON.parse(JSON.stringify(item))
      },

      deleteItem(item) {
        this.item.items.splice(this.item.items.indexOf(item), 1)
      },

      getPaquete(id) {
        paquetes_service.get_package(id).then(res => {
          this.item = res.data
        }, res => {
          this.$toast.error('Error consultando registro')
        })
      },

      updateOrCreate() {
        if (!this.$refs.form.validate()) {
          return this.$toast.warn('Error de validando formulario')
        }
        this.item.id ? this.updatePackage() : this.savePackage()
      },

      savePackage() {
        paquetes_service.save_package(this.item).then(res => {
          this.$toast.sucs('Paquete guardado con exito')
        }, res => {
          this.$toast.error('Error guardando servicio')
        })
      },

      updatePackage() {
        paquetes_service.update_package(this.item).then(res => {
          this.$toast.sucs('Paquete guardado con exito')
        }, res => {
          this.$toast.error('Error guardando servicio')
        })
      },

      getCategories(role) {
        categoria_service.get_categorias(role).then(res => {
          this.categorias = res.data
        }, res => {
          this.$toast.error('Error consultando registro')
        })
      },

      getSubCategories(categoria) {
        categoria_service.get_sub_categorias(categoria).then(res => {
          this.sub_categorias = res.data
        }, res => {
          this.$toast.error('Error consultando subcategorias')
        })
      },
    },
    computed: {
      isloading() {
        return this.$store.getters.getloading
      },
      errors() {
        return this.$store.getters.geterrors
      },
      user() {
        return JSON.parse(localStorage.getItem('user'))
      },
    }
  }
</script>