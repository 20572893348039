import ListaPaquetesDirectorio from '../componentes/ListaPaquetesDirectorio'
import ListaPaquetesApp from '../componentes/ListaPaquetesApp'
import FormPaqueteDirectorio from '../componentes/FormPaqueteDirectorio'
import FormPaquetesApp from '../componentes/FormPaquetesApp'

const routes = [
  ...route('/paquetes-directorio', ListaPaquetesDirectorio, {
    auth: true,
    require_admin: true
  }),
  ...route('/editar-paquete', FormPaqueteDirectorio, {
    auth: true,
    require_admin: true
  }),
  ...route('/paquetes-app', ListaPaquetesApp, {
    auth: true,
    require_admin: true
  }),
  ...route('/editar-paquete-app', FormPaquetesApp, {
    auth: true,
    require_admin: true
  }),
]

function route(path, component = Default, meta = {}) {
  return [{
    path,
    component,
    meta
  }]
}

export default routes