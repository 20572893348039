import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import Main from "./base_components/main/Main.vue"
import router from "./router/router.js"
import store from "./store/store.js"
import auth from './auth/auth.js'
require("./bootstrap")

Vue.config.productionTip = false

import {
  check_booking
} from '~helpers/check_booking'

new Vue({
  vuetify,
  router,
  store,
  components: {
    "main-component": Main
  },

  sockets: {
    'checkin-token': function(data) {
      if (data == false) {
        auth.logout()
      }
    },

    'get-notifications': function(data) {
      let reverse_data = [...data].reverse()
      store.dispatch('setNotifications', reverse_data)
    },

    'notification': function(data) {
      store.dispatch('addNotification', data)
      store.dispatch('setNotificacionsLenght', 1)
    },
  },

  mounted() {
    if (auth.check()) {
      this.$socket.emit('checkin-token', localStorage.getItem('id_token'))

      this.$socket.emit('authenticate', {
        token: localStorage.getItem('id_token')
      })

      let user = JSON.parse(localStorage.getItem('user'))

      this.$socket.emit('get-notifications', {
        userId: user.id
      })

      this.setUser()

      let pack = localStorage.getItem('package')

      if (pack) {
        store.dispatch('setPackage', JSON.parse(pack))
      } else {
        check_booking.check_user_booking()
      }
    }
  },

  methods: {
    setUser() {
      store.dispatch('setUser', JSON.parse(localStorage.getItem('user')))
      store.dispatch('setAuth', true)
    },
  },

  render: h => h(Main)
}).$mount('#app')