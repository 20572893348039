<template>
  <v-container>

    <v-card>
      <v-toolbar flat color="blue accent-2" class="white--text">
        <v-btn to="/productos-app" icon text>
          <v-icon small class="white--text">mdi-arrow-left-bold</v-icon>
        </v-btn>
        <v-toolbar-title>
          <h3 class="font-weight-light">Subir Archivos</h3>
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <loader v-if="isloading"></loader>

        <v-row class="mt-3">

          <v-col cols="12" md="6">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row dense>
                <v-col cols="12">
                  <v-text-field v-model="item.ref" :rules="title_rule" small dense outlined label="Referencia" type="text"></v-text-field>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12">
                  <v-text-field v-model="item.title" :rules="title_rule" small dense outlined label="Titulo" type="text"></v-text-field>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12">
                  <v-textarea v-model="item.description" :rules="description_rule" small dense outlined label="Descripción" type="text"></v-textarea>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12">
                  <v-checkbox class="mt-0" v-model="item.isActive" label="Activo"></v-checkbox>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12">
                  <v-file-input ref="fileinput" @change="fileChange" small outlined dense label="Archivo"></v-file-input>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col v-if="!item.id" cols="12">
                  <v-btn @click="updateOrCreate" :loading="isloading" rounded class="white--text" depressed color="pink darken-1">guardar</v-btn>
                </v-col>
                <v-col v-else cols="12">
                  <v-btn @click="updateFile" :loading="isloading" rounded class="white--text" depressed color="pink darken-1">actualizar</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>


          <v-col cols="12" md="6">
            <v-row dense>
              <v-col cols="12">
                <h3 class="">Archivos cargados</h3>
                <v-data-table :headers="headers" :items="items" disable-pagination hide-default-footer item-key="key" class="elevation-1 mt-6">


                  <template v-slot:item.action="{ item }">

                    <v-icon @click="getSignUrl(item)" small class="mr-2" color="green">
                      mdi-lock-open
                    </v-icon>

                    <v-icon @click="setItem(item)" small class="mr-2" color="blue">
                      mdi-pencil
                    </v-icon>

                    <v-icon @click="openDialog(item)" small color="red lighten-1">
                      mdi-trash-can
                    </v-icon>
                  </template>

                </v-data-table>
              </v-col>
            </v-row>
          </v-col>

        </v-row>




      </v-card-text>

    </v-card>


    <v-dialog v-model="dialog" persistent max-width="350">
      <v-card>
        <v-card-title class="text-h5">
          Descarga
        </v-card-title>
        <v-card-text>
          <p>Nombre de archivo: {{selected_item}}</p>
        </v-card-text>
        <v-card-actions class="pb-4">

          <a :href="signed_url" target="_blank">
            <v-btn rounded class="white--text mr-4" color="green">Descargar</v-btn>
          </a>

          <v-btn rounded depressed class="white--text" color="red lighten-1" @click="dialog = false">
            cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-confirm-dialog v-on:delete="deleteFile">
    </v-confirm-dialog>


  </v-container>
</template>

<script>
  import {
    upload_service
  } from '~/upload_service'

  import {
    productos_service
  } from '~/productos_service'

  import {
    paquete_directorio_rules
  } from '../mixins/paquete_directorio_rules'

  export default {
    mixins: [paquete_directorio_rules],

    data() {
      return {
        formData: new FormData(),
        dialog: false,
        delete_item: {},
        edit_item: {},
        selected_item: null,
        signed_url: null,
        item: {
          ref: '',
          title: '',
          description: '',
          isActive: true
        },
        items: [],
        headers: [{
            text: 'Referencia',
            value: 'ref',
          },
          {
            text: 'Titulo',
            value: 'title',
          },
          {
            text: 'Acciones',
            value: 'action',
          }
        ]
      }
    },

    created() {
      this.getProducto(this.$route.params.id)
    },

    methods: {
      openDialog(item) {
        this.delete_item = item
        this.$emit('confirm_open')
      },

      setItem(item) {
        this.item = JSON.parse(JSON.stringify(item))
      },

      deleteFile() {
        upload_service.delete_private_file(this.delete_item.id).then(res => {
          this.$toast.sucs('Archivo eliminado con exito')
          this.getProducto(this.$route.params.id)
        }, res => {
          this.$toast.error('Error guardando servicio')
        })
      },

      getSignUrl(item) {
        this.selected_item = item.name
        upload_service.get_sign_file({
          path: item.path
        }).then(res => {
          this.dialog = true
          this.signed_url = res.data
        }, res => {
          this.$toast.error('Error consultando registro')
        })
      },
      getProducto(id) {
        productos_service.get_producto(id).then(res => {
          this.items = res.data.privateFiles
        }, res => {
          this.$toast.error('Error consultando registro')
        })
      },

      updateOrCreate() {
        let data = this.jsonToFormData(this.item)
        upload_service.upload_private_file('products', this.$route.params.id, data).then(res => {
          this.$toast.sucs('Archivo cargado con exito')
          this.getProducto(this.$route.params.id)
          this.resetform()
        }, res => {
          this.$toast.error('Error guardando servicio')
        })
      },

      updateFile() {
        let data = this.jsonToFormData(this.item)
        upload_service.update_private_file('products', this.item.id, data).then(res => {
          this.$toast.sucs('Registro actualizado con exito')
          this.getProducto(this.$route.params.id)
          this.resetform()
        }, res => {
          this.$toast.error('Error guardando servicio')
        })
      },

      resetform() {
        this.item = {
          ref: '',
          title: '',
          description: '',
        }
        this.$refs.form.resetValidation()
        this.$refs.fileinput.reset()
      },

      fileChange(file) {
        this.formData.append('img', file)
      },

      jsonToFormData(json_object) {
        Object.keys(json_object).forEach(key => this.formData.append(key, json_object[key]))
        return this.formData
      },
    },
    computed: {
      isloading() {
        return this.$store.getters.getloading
      },
      errors() {
        return this.$store.getters.geterrors
      },
      user() {
        return JSON.parse(localStorage.getItem('user'))
      },
    }
  }
</script>