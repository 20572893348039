<template id="">
  <v-container>

    <v-row dense class="mt-3">
      <v-col cols="12" md="8" offset-md="2">
        <h2 class="pink--text text-center mb-3">{{anuncio.title}}</h2>

        <v-card class="elevation-1">
          <v-card-text>
            <v-carousel height="300">
              <v-carousel-item eager v-for="(item,i) in imagenes" :key="`local_${i}`" :src="item" reverse-transition="fade-transition" transition="fade-transition">
                <template v-slot:default>
                  <v-row class="pa-4" align="center">
                    <v-icon @click="$emit('delete_local_img', item)" large color="red lighten-1">mdi-delete</v-icon>
                  </v-row>
                </template>
              </v-carousel-item>

              <v-carousel-item eager v-for="(item,i) in anuncio.image" :src="`${$awsPath}/${item}`" :key="i" reverse-transition="fade-transition" transition="fade-transition">
                <template v-slot:default>
                  <v-row class="pa-4" align="center">
                    <v-icon @click="$emit('delete_anuncio_img', item)" large color="red lighten-1">mdi-delete</v-icon>
                  </v-row>
                </template>
              </v-carousel-item>

            </v-carousel>
          </v-card-text>
        </v-card>

        <!--SECCION DOS-->

        <v-card class="elevation-1 mt-6">
          <v-card-text class="pt-6 pb-6">
            <h2 class="pink--text text-h5 text-center mb-3">Contactar al anunciante</h2>

            <p align="center" class="mt-8 text-center">
              <v-icon class="mr-1 mb-2">mdi-city-variant-outline</v-icon> {{anuncio.address}}
            </p>

            <br>

            <v-row justify="center" align="center" id="">

              <v-btn color="pink" outlined rounded class="ma-2 white--text">
                <v-icon center dark>
                  mdi-email-outline
                </v-icon>
              </v-btn>

              <v-btn color="pink" rounded class="elevation-0 ma-2 white--text">

                <v-icon left dark>
                  mdi-phone
                </v-icon>
                {{ anuncio.phone }}
              </v-btn>


            </v-row>
          </v-card-text>
        </v-card>

        <!-- SECCION TRES -->
        <v-card class="elevation-1 mt-6">
          <v-card-text class="pt-6 pb-6">
            <h2 class="pink--text text-h5 text-center mb-3 nunito-sans">Más información</h2>

            <div v-html="anuncio.description" class="text-center">

            </div>


          </v-card-text>
        </v-card>

        <!--SECCION CUATRO-->
        <v-card class="elevation-1 mt-6">
          <v-card-text class="pt-6 pb-6">

            <v-google-map :cords="{lat:anuncio.lat, long:anuncio.long}"></v-google-map>
          </v-card-text>
        </v-card>

      </v-col>
    </v-row>

  </v-container>
</template>

<script>
  export default {
    props: ['anuncio', 'imagenes'],
  }
</script>


<style scoped>

</style>