export const advert_rules = {
  data() {
    return {
      valid: true,
      price_rule: [
        v => !!v || 'Requerido',
      ],
      title_rule: [
        v => !!v || 'Requerido',
        v => (v && v.length <= 200) || 'Supera el limite de 200',
      ],
      description_rule: [
        v => !!v || 'Requerido',
        v => (v && v.length <= 300) || 'Supera el limite de 300',
      ],
      duration_rules: [
        v => !!v || 'Requerido'
      ],
      advert_rules: [
        v => !!v || 'Requerido'
      ],
      categoria_rule: [
        v => !!v || 'Requerido'
      ],
      subcategoria_rule: [
        v => !!v || 'Requerido'
      ],
      location_rule: [
        v => !!v || 'Requerido'
      ],
      number_rule: [
        v => Number.isInteger(Number(v)) || 'valor debe ser un numero entero'
      ],
    }
  },
}