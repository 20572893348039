import ListaDirectorio from '../componentes/ListaDirectorio'
import FormDirectorio from '../componentes/FormDirectorio'
import ListaAdminDirectorio from '../componentes/ListaAdminDirectorio'

const routes = [
  ...route('/mis-anuncios', ListaDirectorio, {
    auth: true,
  }),
  ...route('/guardar-anuncio-directorio', FormDirectorio, {
    auth: true,
  }),
  ...route('/lista-directorio', ListaAdminDirectorio, {
    auth: true,
    require_admin: true
  }),
]

function route(path, component = Default, meta = {}) {
  return [{
    path,
    component,
    meta
  }]
}

export default routes