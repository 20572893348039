import axios from 'axios'

export const categoria_service = {

  async get_categoria(id) {
    return axios.get(`category/${id}`)
  },

  async get_categorias(role) {
    return axios.get(`list-category/${role}`)
  },

  async get_sub_categorias(categoria_id) {
    return axios.get(`list-subcategory/${categoria_id}`)
  }

}