<template>
  <v-container>

    <v-card>
      <v-toolbar flat color="blue accent-2" class="white--text">
        <v-btn to="/" icon text>
          <v-icon small class="white--text">mdi-home</v-icon>
        </v-btn>
        <v-toolbar-title>
          <h3 class="font-weight-light">Negocios registrados en el app</h3>
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <loader v-if="isloading"></loader>
        <v-row dense align="center">
          <v-col cols="12" md="4">
            <v-text-field v-model="search" hide-details append-icon="mdi-magnify" label="Buscar" single-line outlined small dense></v-text-field>
          </v-col>

          <v-col class="mt-2" cols="4">
            <v-btn :to="`/crear-business-location`" depressed rounded class="white--text" color="green">
              nuevo
            </v-btn>
          </v-col>
        </v-row>


        <v-row dense>
          <v-col cols="12">
            <v-data-table :search="search" :headers="headers" :items="items" disable-pagination hide-default-footer item-key="key" class="elevation-1 mt-6">

              <template v-slot:item.action="{ item }">

                <router-link :to="`/crear-business-location?id=${item.id}`">
                  <v-icon small class="mr-2" color="blue">
                    mdi-pencil
                  </v-icon>
                </router-link>


                <!--<v-icon @click="openConfirmDialog(item)" small color="red lighten-1">
                  mdi-trash-can
                </v-icon>--->

              </template>

            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>

    </v-card>


  </v-container>
</template>

<script>
  import {
    location_service
  } from '~/location_service'

  export default {

    data() {
      return {
        item: {},

        items: [],

        search: null,

        headers: [{
            text: 'Nombre',
            value: 'name',
            width: '400',
          },
          {
            text: 'Categoria',
            value: 'subCategoryId.name',
          },
          {
            text: 'Acciones',
            value: 'action',
            sortable: false
          },
        ],
      }
    },

    created() {
      if (this.user.role == 'ADMIN_ROLE') {
        this.getAdminLocations()
      } else {
        this.getLocationByUser()
      }

    },

    methods: {
      getAdminLocations() {
        location_service.get_busines_locations(this.user.id).then(res => {
          this.items = res.data
        }, res => {
          this.$toast.error('Error consultando posts')
        })
      },
      getLocationByUser() {
        location_service.get_user_busines_locations().then(res => {
          this.items = res.data
        }, res => {
          this.$toast.error('Error consultando posts')
        })
      }

    },
    computed: {
      isloading() {
        return this.$store.getters.getloading
      },
      errors() {
        return this.$store.getters.geterrors
      },
      user() {
        return JSON.parse(localStorage.getItem('user'))
      },
      categoria() {
        return this.$store.getters.get_categoria
      }
    }
  }
</script>