<template id="">
  <v-dialog persistent v-model="dialog" width="750">

    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Comentarios
      </v-card-title>

      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <loader v-if="isloading"></loader>
            <v-data-table :headers="headers" :items="comentarios" disable-pagination hide-default-footer item-key="key" class="elevation-1 mt-6">

            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false">
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import {
    post_service
  } from '~/post_service'

  export default {
    data() {
      return {
        dialog: false,
        comentarios: [],
        headers: [{
            text: 'Usuario',
            value: 'userId.name',
            width: 200
          },
          {
            text: 'Comentario',
            value: 'text',
          }
        ],
      }
    },

    created() {
      this.$parent.$on('open_modal', post => {
        this.comentarios = []
        this.dialog = true
        this.getComments(post)
      })
    },

    methods: {
      getComments(post) {
        post_service.get_comments_by_post(post).then(res => {
          this.comentarios = res.data.comments
        })
      }
    },

    computed: {
      isloading() {
        return this.$store.getters.getloading
      }
    }
  }
</script>