export const ucsis_rules = {
  data() {
    return {
      valid: true,
      type_rule: [
        v => !!v || 'Requerido',
      ],
      title_rule: [
        v => !!v || 'Requerido',
      ],
      description_rule: [
        v => !!v || 'Requerido',
      ],
      avegare_rules: [
        v => !!v || 'Requerido'
      ],
      advert_rules: [
        v => !!v || 'Requerido'
      ],
      categoria_rule: [
        v => !!v || 'Requerido'
      ],
      subcategoria_rule: [
        v => !!v || 'Requerido'
      ],
      location_rule: [
        v => !!v || 'Requerido'
      ],
    }
  },
}