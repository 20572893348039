<template>
  <v-container>

    <v-card>
      <v-toolbar flat color="blue accent-2" class="white--text">
        <v-btn to="/" icon text>
          <v-icon small class="white--text">mdi-home</v-icon>
        </v-btn>
        <v-toolbar-title>
          <h3 class="font-weight-light">Anuncios directorio</h3>
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <loader v-if="isloading"></loader>

        <v-row dense>
          <v-col class="mt-2" cols="12">
            <v-btn :to="`/guardar-anuncio-directorio`" depressed rounded class="white--text" color="green">
              nuevo anuncio
            </v-btn>
            <v-btn target="_blank" :href="`${$inmigraPath}/directorio-inmigra`" depressed rounded class="ml-2 white--text" color="blue">
              visitar directorio
            </v-btn>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12">
            <v-data-table :headers="headers" :items="items" disable-pagination hide-default-footer item-key="key" class="elevation-1 mt-6">

              <template v-slot:item.image="{ item }">
                <img class="mt-2 mb-2" height="50" width="50" :src="`${$awsPath}/${item.image[0]}`" alt="">
              </template>

              <template v-slot:item.status="{ item }">
                <v-chip class="white--text" :color="item.status ? 'green' : 'red lighten-1'" small dense>
                  {{item.status ? 'activo' : 'inactivo'}}
                </v-chip>
              </template>

              <template v-slot:item.action="{ item }">

                <router-link :to="`/guardar-anuncio-directorio?id=${item.id}`">
                  <v-icon small class="mr-2" color="blue">
                    mdi-pencil
                  </v-icon>
                </router-link>

              </template>

            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>

    </v-card>


  </v-container>
</template>

<script>
  import {
    directorio_service
  } from '~/directorio_service'

  export default {

    data() {
      return {
        items: [],

        item: {},

        headers: [{
            text: 'Imagen',
            value: 'image',
            width: 90,
          }, {
            text: 'Titulo',
            value: 'title',
          },
          {
            text: 'Activo',
            value: 'status',
          },
          {
            text: 'Acciones',
            value: 'action',
            sortable: false
          },
        ],
      }
    },

    created() {
      this.getAdverts()
    },

    methods: {
      getAdverts(role) {
        directorio_service.get_mis_anuncios(this.user.id).then(res => {
          this.items = res.data
        }, res => {
          this.$toast.error('Error consultando adverts')
        })
      }
    },
    computed: {
      isloading() {
        return this.$store.getters.getloading
      },
      errors() {
        return this.$store.getters.geterrors
      },
      user() {
        return JSON.parse(localStorage.getItem('user'))
      },
    }
  }
</script>