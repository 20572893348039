<template>
  <v-container>

    <v-card>
      <v-toolbar flat color="blue accent-2" class="white--text">
        <v-btn to="/" icon text>
          <v-icon small class="white--text">mdi-home</v-icon>
        </v-btn>
        <v-toolbar-title>
          <h3 class="font-weight-light">Subir facebook leads</h3>
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>

        <loader v-if="isloading"></loader>


        <v-row dense>

          <v-col cols="12" md="4">
            <v-select small dense outlined v-model="upload.type" :items="roles" label="Rol"></v-select>
          </v-col>

          <v-col cols="12" md="4">
            <v-select v-model="upload.categoryId" small dense outlined :items="categorias" label="Categoria" item-text="name" item-value="id"></v-select>
          </v-col>

          <v-col cols="12" md="4">
            <v-file-input ref="fileinput" @change="changeFile" small dense outlined accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" label="Archivo"></v-file-input>
          </v-col>

        </v-row>

        <v-row dense>
          <v-col cols="12" md="4">
            <v-btn class="white--text" @click="uploadFile" color="success" rounded depressed>guardar</v-btn>
          </v-col>
        </v-row>

        <br>

        <v-row dense>
          <v-col cols="12">
            <v-data-table :headers="headers" :items="items" disable-pagination hide-default-footer item-key="key" class="elevation-1 mt-6">

              <template v-slot:item.consultor="{ item }">
                <span v-if="item.consultor.length > 0">{{item.consultor[0].name}}</span>
              </template>

              <template v-slot:item.action="{ item }">

              </template>

            </v-data-table>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import {
    categoria_service
  } from '~/categoria_service'

  import {
    upload_lead_service
  } from '~/upload_lead_service'

  import {
    lead_service
  } from '~/lead_service'

  export default {

    data() {
      return {
        formData: new FormData(),
        roles: ['COMPANY_ROLE', 'CONSULTANT_ROLE', 'HANDYMAN_ROLE', 'INTEREST_ROLE'],
        categorias: [],
        sub_categorias: [],
        upload: {
          type: 'COMPANY_ROLE',
          categoryId: null,
        },

        items: [],

        headers: [{
            text: 'Nombre',
            value: 'name',
          },
          {
            text: 'Email',
            value: 'email',
          },
          {
            text: 'Teléfono',
            value: 'phone',
          },
          {
            text: 'Categoria',
            value: 'category',
          },
          {
            text: 'Estado',
            value: 'state',
          },
          {
            text: 'Consultor',
            value: 'consultor',
          },
          {
            text: 'Acciones',
            value: 'action',
            sortable: false
          },
        ],
      }
    },

    watch: {
      'upload.type': {
        immediate: true,
        handler(n, o) {
          this.getCategories(n)
        }
      },
    },

    mounted() {
      this.getAllLeads()
    },

    methods: {
      getAllLeads() {
        lead_service.get_all_leads().then(res => {
          this.items = res.data
        })
      },

      changeFile(file) {
        this.formData.append('leadFile', file)
      },
      uploadFile() {
        this.formData.delete('categoryId')
        this.formData.delete('subCategoryId')
        this.formData.append('categoryId', this.upload.categoryId)
        this.formData.append('subCategoryId', this.upload.subCategoryId)

        upload_lead_service.upload_file(this.formData).then(res => {
          this.$toast.sucs('archivo importado con exito')
        }, res => {
          this.$toast.error('Error subiendo archivo')
        })

      },
      getCategories(role) {
        categoria_service.get_categorias(role).then(res => {
          this.categorias = res.data
        }, res => {
          this.$toast.error('Error consultando registro')
        })
      },
    },

    filters: {

    },
    computed: {
      isloading() {
        return this.$store.getters.getloading
      },
      errors() {
        return this.$store.getters.geterrors
      },
      user() {
        return JSON.parse(localStorage.getItem('user'))
      }
    }
  }
</script>