import Vue from 'vue'

import VuetifyToast from 'vuetify-toast-snackbar-ng'

Vue.use(require('vue-moment'));

import setup from './interceptors/interceptors.js'
setup()

import Vue2Editor from "vue2-editor"
Vue.use(Vue2Editor)

import VueRouter from 'vue-router'
Vue.use(VueRouter)

import Loader from './base_components/base/Loader.vue'
Vue.component('loader', Loader)

import VConfirmDialog from './base_components/dialogs/VConfirmDialog.vue'
Vue.component('v-confirm-dialog', VConfirmDialog)

import VGoogleAutocomplete from './base_components/dialogs/VGoogleAutocomplete.vue'
Vue.component('v-google-autocomplete', VGoogleAutocomplete)

import VGoogleMap from './base_components/dialogs/VGoogleMap.vue'
Vue.component('v-google-map', VGoogleMap)

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

import Nl2br from 'vue-nl2br'
Vue.component('nl2br', Nl2br)

//const base_url = 'http://localhost:3000'
//const base_url = 'https://api-dev-mobile.inmigrausa.com'
const base_url = process.env.VUE_APP_API_URL
Vue.prototype.$awsPath = process.env.VUE_APP_AWS_URL
Vue.prototype.$inmigraPath = process.env.VUE_APP_INMIGRA_URL

axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['x-access-token'] = `${localStorage.getItem('id_token')}`
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
axios.defaults.baseURL = `${base_url}/api`

import VueSocketIO from 'vue-socket.io'
import SocketIO from 'socket.io-client'

const socketConnection = SocketIO(base_url, {
  path: '/events/socket.io',
  transports: ['websocket'],
  upgrade: false
})

import store from './store/store.js'

Vue.use(new VueSocketIO({
  debug: true,
  connection: socketConnection,
  options: {
    path: '/events/socket.io'
  },
  vuex: {
    store,
    actionPrefix: "SOCKET_",
    mutationPrefix: "SOCKET_"
  }
}))

Vue.use(VuetifyToast, {
  x: 'right',
  y: 'top',
  color: 'info',
  icon: 'mdi-info',
  timeout: 3000,
  dismissable: true,
  autoHeight: false,
  multiLine: false,
  vertical: false,
  shorts: {
    error: {
      color: '#EF5350'
    },
    sucs: {
      color: '#8BC34A'
    },
    warn: {
      color: '#FFA726'
    }
  },
  property: '$toast'
})