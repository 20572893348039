<template>
  <v-container>

    <v-card>
      <v-toolbar flat color="blue accent-2" class="white--text">
        <v-btn to="/" icon text>
          <v-icon small class="white--text">mdi-home</v-icon>
        </v-btn>
        <v-toolbar-title>
          <h3 class="font-weight-light">Mis posts</h3>
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <loader v-if="isloading"></loader>
        <v-row dense align="center">
          <v-col cols="12" md="4">
            <v-text-field v-model="search" hide-details append-icon="mdi-magnify" label="Buscar" single-line outlined small dense></v-text-field>
          </v-col>

          <v-col class="mt-2" cols="4">
            <v-btn :to="`/crear-post-colaborador`" depressed rounded class="white--text" color="green">
              nuevo
            </v-btn>
          </v-col>
        </v-row>


        <v-row dense>
          <v-col cols="12">
            <v-data-table :search="search" :headers="headers" :items="items" disable-pagination hide-default-footer item-key="key" class="elevation-1 mt-6">

              <template v-slot:item.image="{ item }">
                <img class="mt-2 mb-2" height="50" width="50" :src="`${$awsPath}/${item.image[0]}`" alt="">
              </template>

              <template v-slot:item.action="{ item }">
                <router-link :to="`/crear-post-colaborador?id=${item.id}`">
                  <v-icon small class="mr-2" color="blue">
                    mdi-pencil
                  </v-icon>
                </router-link>


                <v-icon @click="openConfirmDialog(item)" small color="red lighten-1">
                  mdi-trash-can
                </v-icon>

              </template>

            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>

    </v-card>

    <v-confirm-dialog v-on:delete="deletePost">
    </v-confirm-dialog>

  </v-container>
</template>

<script>
  import {
    post_service
  } from '~/post_service'

  export default {

    data() {
      return {
        item: {},

        items: [],

        search: null,

        headers: [{
            text: 'Imagen',
            value: 'image',
          },
          {
            text: 'titulo',
            value: 'title',
          },
          {
            text: 'Descripción',
            value: 'description',
          },
          {
            text: 'Acciones',
            value: 'action',
            sortable: false
          },
        ],
      }
    },

    created() {
      this.getPosts()
    },

    methods: {
      getPosts() {
        post_service.get_post_by_user_id(this.user.id).then(res => {
          this.items = res.data
        }, res => {
          this.$toast.error('Error consultando posts')
        })
      },

      openConfirmDialog(item) {
        this.item = item
        this.$emit('confirm_open')
      },

      deletePost() {
        post_service.delete_post(this.item.id).then(res => {
          this.items.splice(this.items.indexOf(this.item), 1)
          this.$toast.sucs('Registro eliminado con exito')
          this.item = {}
        }, res => {
          this.$toast.error('Error eliminando registro')
        })
      }

    },
    computed: {
      isloading() {
        return this.$store.getters.getloading
      },
      errors() {
        return this.$store.getters.geterrors
      },
      user() {
        return JSON.parse(localStorage.getItem('user'))
      },
      categoria() {
        return this.$store.getters.get_categoria
      }
    }
  }
</script>