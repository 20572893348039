import axios from 'axios'

export const upload_service = {

  async upload_private_file(type, id, data) {
    return axios.post(`private-upload/${type}/${id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  async update_private_file(type, id, data) {
    return axios.put(`update-private-file/${type}/${id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  async get_sign_file(data) {
    return axios.post(`get-signed-url`, data)
  },

  async delete_private_file(id) {
    return axios.delete(`delete-private-file/${id}`)
  },

  async upload_files(type, id, data) {
    return axios.post(`upload/${type}/${id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

}