const modulo_estado = {
  strict: false,

  state: {
    is_loading: false,

    auth: false,

    user: {
      name: '...',
      role: 0,
      email: '',
      id: '',
    },

    errors: {
      errors: {

      }
    },

    login_errors: {
      message: null
    },

    reload: false,
  },

  getters: {
    getreload: state => state.reload,

    getloading: state => state.is_loading,

    geterrors: state => state.errors,

    getuser: state => state.user,

    getauth: state => state.auth,

    get_login_errors: state => state.login_errors
  },

  mutations: {
    has_reload: (state, reload) => state.reload = reload,

    is_loading: (state, status) => state.is_loading = status,

    is_errors: (state, errors) => state.errors = errors,

    user: (state, user) => state.user = user,

    auth: (state, status) => state.auth = status,

    has_login_errors: (state, errors) => state.login_errors = errors

  },

  actions: {
    hasReload: (context, reload) => context.commit('has_reload', reload),

    isLoading: (context, status) => context.commit('is_loading', status),

    setErrors: (context, errors) => context.commit('is_errors', errors),

    setUser: (context, user) => context.commit('user', user),

    setAuth: (context, status) => context.commit('auth', status),

    setLoginErrors: (context, errors) => context.commit('has_login_errors', errors)
  }
}

export default modulo_estado;