import ListaPreciosCiudades from '../componentes/ListaPreciosCiudades'

const routes = [
  ...route('/lista-actualizar-precios', ListaPreciosCiudades, {
    auth: true,
    require_admin: true
  }),
]

function route(path, component = Default, meta = {}, props = true) {
  return [{
    path,
    component,
    meta,
    props
  }]
}

export default routes