<template>
  <v-container>

    <v-row ref="chatContainer" color="#f3f6fd" class="pa-2 overflow-y-auto rounded-lg" style="height:40vh;" dense>

      <v-col class="mt-5" v-for="mensaje in mensajes" :key="mensaje.id" cols="12">

        <!--<v-row :class="[!setOwn(mensaje) ? 'float-left blue accent-1 grey--text text--darken-4' : 'float-right pink accent-2 grey--text text--darken-4']" dense>-->

        <div :class="[!setOwn(mensaje) ? 'pr-4' : 'pl-4']">
          <v-avatar size="30">
            <img :src="mensaje.from.image.includes('uploads') ? `${$awsPath}/${mensaje.from.image}` : mensaje.from.image">
          </v-avatar>
          <i style="font-size:12px;"> {{mensaje.from.name}}</i>

          <div class="mt-2 light-blue darken-1 white pa-2 rounded">
            <span class="font-weight-light white--text" style="overflow-wrap: break-word;" v-html="mensaje.message"> </span>
          </div>
        </div>


      </v-col>
    </v-row>

    <v-row dense align="center">
      <v-col dense cols="12">
        <v-textarea hide-details @keydown.enter.exact.prevent @keyup.enter.exact="enviarMensaje" v-model="mensaje.message" @click:append-outer="enviarMensaje" append-outer-icon="mdi-send" outlined color="green" placeholder="Mensaje" rows="3"
          row-height="20">
        </v-textarea>
      </v-col>
    </v-row>



  </v-container>

</template>


<script type="text/javascript">
  export default {
    props: ['meeting_h'],
    data() {
      return {
        mensajes: [],
        mensaje: {
          from: null,
          to: null,
          message: null
        }
      }
    },

    watch: {
      '$route.query.id': {
        handler: function(id) {
          this.mensaje.to = id
          this.$socket.emit('chats', this.mensaje)
        },
        deep: true,
      }
    },

    updated() {
      this.$nextTick(() => this.scrollToEnd());
    },

    sockets: {
      chats: function(data) {
        this.mensajes = data.reverse()
      },

      chat: function(data) {
        if (data.from._id == this.$route.query.id || data.from._id == this.user.id) {
          this.mensajes.push(data)
        }
      },
    },

    mounted() {
      this.mensaje.from = this.user.id
      this.mensaje.to = this.$route.query.id
      this.$socket.emit('chats', this.mensaje)
    },

    methods: {
      scrollToEnd: function() {
        if (this.$refs.chatContainer && this.$refs.chatContainer.lastElementChild) {
          this.$refs.chatContainer.scrollTop = this.$refs.chatContainer.lastElementChild.offsetTop;
        }
      },
      enviarMensaje() {
        this.$socket.emit('chat', this.mensaje)
        this.mensaje.message = null
      },
      setOwn(mensaje) {
        return (this.user.id == mensaje.from._id) ? true : false
      }
    },

    computed: {
      isloading() {
        return this.$store.getters.getloading
      },
      user() {
        return JSON.parse(localStorage.getItem('user'))
      }
    }
  }
</script>