<template>
  <v-container>

    <h2 class="mb-4 blue--text text--darken-1">
      Chats recientes
    </h2>

    <loader v-if="isloading"></loader>

    <v-row dense>
      <v-col cols="12">
        <v-data-table :headers=" headers" :items="recientes" disable-pagination hide-default-footer item-key="key" class="elevation-1 mt-6">

          <template v-slot:item.image="{ item }">
            <router-link :to="`/chat?id=${item.id}`">
              <v-avatar>
                <img :src="item.image">
              </v-avatar>
            </router-link>
            <span class="ml-3">{{item.name}}</span>
          </template>

          <template v-slot:item.created_at="{ item }">
            <span>{{ item.created_at | format_date}}</span>
          </template>

          <template v-slot:item.chat="{ item }">
            <router-link :to="`/chat?id=${item.id}`">
              <v-icon color="green">mdi-chat-processing</v-icon>
            </router-link>
          </template>

        </v-data-table>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
  import moment from 'moment'

  import "moment/locale/es"

  export default {

    data() {
      return {
        recientes: [],

        items: [],

        headers: [{
            text: 'Usuario',
            value: 'image',
          },
          {
            text: 'Último mensaje',
            value: 'created_at',
          }, {
            text: 'Chat',
            value: 'chat',
          }
        ],
      }
    },


    sockets: {
      "chats-history": function(data) {
        console.log(data)
        this.items = data
        this.transformData(data)
      }
    },

    created() {
      this.$socket.emit('chats-history', {
        from: this.user.id
      })
    },

    methods: {
      invertUser(item, createdAt) {
        return {
          id: item._id,
          image: item.image.includes('uploads') ? `${this.$awsPath}/${item.image}` : item.image,
          name: item.name,
          created_at: createdAt
        }
      },
      transformData(items) {
        let conversaciones = []
        this.items.forEach((item, i) => {
          if (!item) {
            return null
          }
          if (this.user.id == item.to._id) {
            conversaciones.push(this.invertUser(item.from, item.createdAt))
          } else {
            conversaciones.push(this.invertUser(item.to, item.createdAt))
          }
        })
        this.recientes = _.uniqBy(conversaciones, 'id');
      }
    },

    filters: {
      format_hour(hour_24_format) {
        return moment(`01-01-2023 ${hour_24_format}:00`).format('hh:mm a')
      },

      format_date(db_date) {
        return moment(db_date).format('dddd, D MMMM - Y hh:mm a')
      },
    },
    computed: {
      isloading() {
        return this.$store.getters.getloading
      },
      errors() {
        return this.$store.getters.geterrors
      },
      user() {
        return JSON.parse(localStorage.getItem('user'))
      }
    }
  }
</script>