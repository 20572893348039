import ListaProductosApp from '../componentes/ListaProductosApp'
import FormProducto from '../componentes/FormProducto'
import FormPrivateFiles from '../componentes/FormPrivateFiles'

const routes = [
  ...route('/productos-app', ListaProductosApp, {
    auth: true,
    require_admin: true
  }),
  ...route('/guardar-producto-app', FormProducto, {
    auth: true,
    require_admin: true
  }),
  ...route('/subir-archivos-producto/:id', FormPrivateFiles, {
    auth: true,
    require_admin: true
  }),
]

function route(path, component = Default, meta = {}) {
  return [{
    path,
    component,
    meta
  }]
}

export default routes