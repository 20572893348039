<template>
  <v-container>

    <v-card>
      <v-toolbar flat color="blue accent-2" class="white--text">
        <v-btn to="/" icon text>
          <v-icon small class="white--text">mdi-home</v-icon>
        </v-btn>
        <v-toolbar-title>
          <h3 class="font-weight-light">Categorias</h3>
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-row dense>

          <v-col cols="12" md="4">
            <v-select small dense outlined v-model="categoria.type" :items="roles" label="Rol"></v-select>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field small dense v-model="categoria.name" outlined label="Nombre (actual)"></v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <v-file-input ref="fileinput" small dense outlined @change="onFileChange" accept="image/*" label="Imagen"></v-file-input>
          </v-col>



          <!--langs-->




        </v-row>



      </v-card-text>

    </v-card>

    <loader v-if="isloading"></loader>



  </v-container>
</template>

<script>
  import {
    categoria_service
  } from '~/categoria_service'

  export default {

    data() {
      return {
        roles: ['COMPANY_ROLE', 'CONSULTANT_ROLE', 'HANDYMAN_ROLE', 'INTEREST_ROLE'],

        categorias: [],

        links: {
          role: 'COMPANY_ROLE',
          categoria_id: null
        }
      }
    },

    watch: {
      'links.categoria_id': {
        immediate: true,
        handler(n, o) {
          this.getCategories(n)
        }
      }
    },

    methods: {
      getCategories(role) {
        categoria_service.get_categorias(role).then(res => {
          this.categorias = res.data
        }, res => {
          this.$toast.error('Error consultando categorias')
        })
      },
    },
    computed: {
      isloading() {
        return this.$store.getters.getloading
      },
      errors() {
        return this.$store.getters.geterrors
      },
      user() {
        return JSON.parse(localStorage.getItem('user'))
      },
      categoria() {
        return this.$store.getters.get_categoria
      }
    }
  }
</script>