<template>
  <v-dialog persistent v-model="dialog" width="480">
    <v-card class="pt-8">
      <loader v-if="isloading"></loader>

      <v-card-title dense class="justify-center" justify="center">
        <v-avatar v-if="user.image && user.image.length > 15" size="150px">
          <v-img :src="user.image.includes('uploads') ? `${$awsPath}/${user.image}` : user.image" dark>
          </v-img>
        </v-avatar>
      </v-card-title>


      <v-card-title justify="center" class="justify-center">
        <div class="pt-4 text-h5 text-center">
          {{user.name}} <span v-if="user.name != user.last_name">{{user.last_name}}</span>
        </div>
      </v-card-title>


      <v-list two-line>
        <v-list-item>
          <v-list-item-icon>
            <v-icon color="indigo">
              mdi-phone
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title> <span v-if="user.address">{{user.address.country_code}}</span> {{user.phone}}</v-list-item-title>
            <v-list-item-subtitle>Teléfono</v-list-item-subtitle>
          </v-list-item-content>

        </v-list-item>

        <v-divider inset></v-divider>

        <v-list-item>
          <v-list-item-icon>
            <v-icon color="indigo">
              mdi-email
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{user.email}}</v-list-item-title>
            <v-list-item-subtitle>Email</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>


        <v-divider inset></v-divider>

        <v-list-item v-if="user.address">
          <v-list-item-icon>
            <v-icon color="indigo">
              mdi-map-marker
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{user.address.country}}</v-list-item-title>
            <v-list-item-subtitle> <span v-if="user.address.state">{{user.address.state}},</span> {{user.address.pha2_code}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red lighten-1" text @click="clearModal">
          Cerrar
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>


<script>
  import {
    account_service
  } from '~/account_service'
  export default {
    data() {
      return {
        userId: null,
        user: {
          id: null,
          name: null,
          last_name: null,
          email: null,
          phone: null,
          community: null,
          role: null,
          image: null,
          address: {
            state: null,
            country_code: null,
            country_flag: null,
            pha2_code: "FL",
            country: "United States",
            detect_country: null
          },
          group: null,
          isConnect: false,
          activated: true,
          interess: [],
          links: [],
          tags: [],
          followings: [],
          blockedUsers: [],
          followers: [],
          followingsLength: 0,
          followersLength: 1,
          acceptedTerm: true,
          created_date: null
        },
        dialog: false,
      }
    },

    created() {
      this.$parent.$on('open_user_modal', userId => {
        this.dialog = true
        this.getUser(userId)
      })
    },

    methods: {
      getUser(userId) {
        account_service.get_account(userId).then(res => {

          if (res.data.id) {
            this.user = res.data
          }
        }, res => {
          this.$toast.error('Error consultando posts')
        })
      },

      clearModal() {

        this.user = {
          id: null,
          name: null,
          last_name: null,
          email: null,
          phone: null,
          community: null,
          role: null,
          image: null,
          address: {
            state: null,
            country_code: null,
            country_flag: null,
            pha2_code: "FL",
            country: "United States",
            detect_country: null
          },
          group: null,
          isConnect: false,
          activated: true,
          interess: [],
          links: [],
          tags: [],
          followings: [],
          blockedUsers: [],
          followers: [],
          followingsLength: 0,
          followersLength: 1,
          acceptedTerm: true,
          created_date: null
        }
        this.dialog = false
      }
    },


    computed: {
      isloading() {
        return this.$store.getters.getloading
      }
    }

  }
</script>