<template>
  <v-dialog persistent v-model="dialog" width="700">

    <v-card>
      <v-card-title class="text-h5 pink darken-1 white--text">
        Añadir nuevo horario
      </v-card-title>

      <v-card-text>

        <v-row dense class="mt-4">
          <v-col cols="12" md="6">
            <v-date-picker v-model="dates" :value="value" multiple></v-date-picker>
          </v-col>
          <v-col cols="12" align="center" md="6">
            <v-time-picker ampm-in-title v-model="initTime"></v-time-picker>
          </v-col>
        </v-row>

      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn @click="addHours" class="white--text" rounded color="success">Guardar</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="red lighten-1" text @click="dialog = false">
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import {
    calendar_service
  } from '~/calendar_service'

  export default {
    data() {
      return {
        value: null,
        dialog: false,
        dates: [],
        initTime: '00:00',
      }
    },

    created() {
      this.$parent.$on('open_days', data => {
        this.dialog = true
        this.value = data
        this.dates.push(data)

      })
    },

    methods: {
      addHours() {
        let data = {
          serviceId: this.$route.query.id,
          initTime: this.initTime,
          serviceHours: this.dates.map(x => {
            return {
              currentDate: x
            }
          })
        }

        calendar_service.save_user_calendar_optional(data).then(res => {
          this.$toast.sucs('Horario creado con exito')
          this.dates = []
          this.initTime = '00:00'
          this.dialog = false
          this.$emit('get_hours')
        }, res => {
          if (res.response.status == 400) {
            return this.$toast.warn('Fecha y Hora a Registrar ya Existe')
          }
          this.$toast.error('Algo ha salido mal, por favor intente nuevamente')
        })
      }
    }
  }
</script>