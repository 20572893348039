import axios from 'axios'

export const post_service = {

  async get_post_by_user_id(user_id) {
    return axios.get(`posts-by-user/${user_id}`)
  },

  async get_post_by_id(id) {
    return axios.get(`posts/${id}`)
  },

  async save_user_post(data) {
    return axios.post(`posts`, data)
  },

  async update_user_post(data) {
    return axios.put(`posts/${data.id}`, data)
  },

  async delete_post(id) {
    return axios.delete(`posts/${id}`)
  },

  /*parte del admin*/
  async get_post_by_admin() {
    return axios.get(`posts-by-type/USER_ROLE`)
  },

  async get_comments_by_post(post) {
    return axios.get(`commentsByPost/${post.id}`)
  },
}