import ListaUserBooking from '../componentes/ListaUserBooking'
import VideoBooking from '../componentes/VideoBooking'

const routes = [
  ...route('/mis-bookings', ListaUserBooking, {
    auth: true,
    require_consultant_handy_package: true,
  }),
  ...route('/booking-meet/:id', VideoBooking, {
    auth: true,
  }),
]

function route(path, component = Default, meta = {}, props = true) {
  return [{
    path,
    component,
    meta,
    props
  }]
}

export default routes