<template>
  <v-container>

    <v-card>
      <v-toolbar flat color="blue accent-2" class="white--text">
        <v-toolbar-title>
          <h3 class="font-weight-light">accounts</h3>
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <loader v-if="isloading"></loader>


        <v-row dense>
          <v-col cols="12">
            <v-data-table :headers="headers" :items="list_accounts" disable-pagination hide-default-footer item-key="key" class="elevation-1 mt-6">

              <template v-slot:item.uscis="{item}">

                <v-icon v-if="item.ucsis_doc && item.ucsis_doc.length > 0" @click="$emit('open_uscis', item.ucsis_doc)" color="green" class="white--text">
                  mdi-account-card-details
                </v-icon>
              </template>

            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>

    </v-card>

    <modal-uscis></modal-uscis>

  </v-container>
</template>

<script>
  import modalUscis from './modalUscis'
  export default {
    props: ['list_accounts'],

    components: {
      modalUscis
    },

    data() {
      return {

        headers: [{
            text: 'Nombre',
            value: 'name',
          }, {
            text: 'Email',
            value: 'email',
          },
          {
            text: 'Uscis',
            value: 'uscis',
          },
          {
            text: 'Fecha',
            value: 'fecha',
          }
        ],
      }
    },

    methods: {

    },
    computed: {
      isloading() {
        return this.$store.getters.getloading
      }
    }
  }
</script>