import axios from 'axios'

export const package_service = {

  async get_packages(environment) {
    return axios.get(`list-packages-directory/${environment}`)
  },

  async cancel_subscription() {
    return axios.get(`cancel-subscription`)
  },

}