export const months_mixin = {
  data() {
    return {
      durations: [{
          label: '1 Mes',
          value: '1 Month'
        },
        {
          label: '2 Meses',
          value: '2 Month'
        },
        {
          label: '3 Meses',
          value: '3 Month'
        },
        {
          label: '4 Meses',
          value: '4 Month'
        },
        {
          label: '5 Meses',
          value: '5 Month'
        },
        {
          label: '6 Meses',
          value: '6 Month'
        },
        {
          label: '7 Meses',
          value: '7 Month'
        },
        {
          label: '8 Meses',
          value: '8 Month'
        },
        {
          label: '9 Meses',
          value: '9 Month'
        },
        {
          label: '10 Meses',
          value: '10 Month'
        },
        {
          label: '11 Meses',
          value: '11 Month'
        },
        {
          label: '12 Meses',
          value: '12 Month'
        }
      ]
    }
  },
}