<template>
  <v-dialog persistent v-model="dialog" width="350">


    <v-card>
      <v-card-title class="text-h5 pink darken-1 white--text">
        Editar / Eliminar Hora
      </v-card-title>

      <v-card-text>
        <v-row dense>
          <v-col cols="12" align="center">
            <v-time-picker ampm-in-title v-model="item.name" class="mt-4"></v-time-picker>
          </v-col>
        </v-row>

      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn @click="updateHour" class="white--text" rounded color="success">Guardar</v-btn>
        <v-btn @click="deleteHour" class="white--text" rounded color="red lighten-1">Eliminar</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="red lighten-1" text @click="dialog = false">
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import {
    calendar_service
  } from '~/calendar_service'

  export default {
    data() {
      return {
        dialog: false,
        item: {
          calendar_service_id: null,
          id: null,
          start: null,
          currentDate: null,
          name: '00:00'
        }
      }
    },

    created() {
      this.$parent.$on('open_dialog', data => {
        this.item = data
        this.dialog = true
      })
    },

    methods: {
      resetDialog() {
        this.item = {
          calendar_service_id: null,
          id: null,
          start: null,
          currentDate: null,
          name: '00:00'
        }
        this.dialog = false
      },

      deleteHour() {
        let data = {
          id: this.item.id
        }
        calendar_service.delete_service_hour(this.item.calendar_service_id, data).then(res => {
          this.$toast.sucs('Hora eliminada con exito')
          this.$emit('remove_hour', this.item)
          this.resetDialog()
        }, res => {
          this.$toast.error('Algo ha salido mal, por favor intente nuevamente')
        })
      },

      updateHour() {
        let data = {
          id: this.item.id,
          initTime: this.item.name,
          currentDate: this.item.currentDate
        }

        calendar_service.update_calendar_service(this.item.calendar_service_id, data).then(res => {
          this.$toast.sucs('Horario actualizado con exito')
          this.resetDialog()
        }, res => {
          this.$toast.error('Algo ha salido mal, por favor intente nuevamente')
        })
      }
    }
  }
</script>