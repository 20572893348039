<template>
  <v-container>

    <v-card>
      <v-toolbar flat color="blue accent-2" class="white--text">
        <v-btn to="/" icon text>
          <v-icon small class="white--text">mdi-home</v-icon>
        </v-btn>
        <v-toolbar-title>
          <h3 class="font-weight-light">Tickets</h3>
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>

        <v-row dense class="mt-4">
          <v-col cols="12" md="3">
            <v-select v-model="query.status" outlined small dense :items="status" label="Estado"></v-select>
          </v-col>
        </v-row>

        <v-row dense>

          <loader v-if="isloading"></loader>

          <v-col cols="12">
            <v-data-table :headers="headers" :items="items" disable-pagination hide-default-footer item-key="key" class="elevation-1 mt-6">

              <template v-slot:item.action="{ item }">

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon @click="openAsignar(item)" class="mr-2" color="green" dark v-bind="attrs" v-on="on">
                      mdi-account-arrow-left
                    </v-icon>
                  </template>
                  <span>Asignar Ticket</span>
                </v-tooltip>

              </template>

              <template v-slot:item.created_date="{item}">
                {{item.created_date | fecha }}
              </template>

              <template v-slot:item.userId="{item}">
                <template v-if="item.userId">{{`${item.userId.name} ${item.userId.last_name}`}}</template>

                <v-chip v-else small dense class="white--text" color="red lighten-1">
                  N/A
                </v-chip>
              </template>

              <template v-slot:item.to="{item}">

                <template v-if="item.to">{{`${item.to.name} ${item.to.last_name}`}}</template>

                <v-chip v-else small dense class="white--text" color="red lighten-1">
                  N/A
                </v-chip>

              </template>

            </v-data-table>
          </v-col>
        </v-row>

      </v-card-text>

    </v-card>

    <modalAsignar v-on:update_ticket="updateTicket" :users="users"></modalAsignar>

  </v-container>

</template>

<script>
  import {
    ticket_service
  } from '~/ticket_service'

  import {
    account_service
  } from '~/account_service'

  import moment from 'moment'

  import modalAsignar from './modalAsignar'

  export default {
    components: {
      modalAsignar
    },

    data() {
      return {
        status: ['OPEN', 'CLOSED'],
        query: {
          status: 'OPEN'
        },

        data: {
          roles: ['COLLABORATOR_ROLE', 'ADMIN_ROLE']
        },

        users: [],

        items: [],
        headers: [{
            text: 'Nro.',
            value: 'numeroTicket',
          },
          {
            text: 'Usuario',
            value: 'userId',
          },
          {
            text: 'Asignado',
            value: 'to',
          },
          {
            text: 'Asunto',
            value: 'asunto',
          },
          {
            text: 'Fecha abierto',
            value: 'created_date',
          },
          {
            text: 'Acciones',
            value: 'action',
            sortable: false
          },
        ],
      }
    },

    watch: {
      'query': {
        deep: true,
        immediate: true,
        handler(filters, o) {
          this.getTickets(filters)
        }
      }
    },

    created() {
      this.getUsers(this.data)
    },

    methods: {
      updateTicket(ticket) {
        let index = this.items.findIndex(x => x.id == ticket.id)
        if (index > -1) {
          this.$set(this.items, index, ticket)
        }
      },
      openAsignar(ticket) {
        this.$emit('open_asignar', ticket)
      },
      getTickets(filters) {
        ticket_service.search_tickets(filters).then(res => {
          this.items = res.data
        }, res => {
          this.$toast.error('Error consultando tickets')
        })
      },

      getUsers(data) {
        account_service.get_accounts(data).then(res => {
          this.users = res.data.accounts
        }, res => {
          this.$toast.error('Error consultando usuarios')
        })
      }

    },
    filters: {
      fecha(value) {
        return moment(value).format('dd D MMMM Y')
      }
    },
    computed: {
      isloading() {
        return this.$store.getters.getloading
      },
      errors() {
        return this.$store.getters.geterrors
      },
      user() {
        return JSON.parse(localStorage.getItem('user'))
      },
    }
  }
</script>