<template>

  <v-app>
    <v-content>


      <v-row class="fill-height">

        <v-col align="center" justify="center" cols="12" md="5">

          <v-container>
            <v-row dense>
              <v-col class="pa-8 mt-12" cols="12">

                <img width="60%" src="logo.png" alt="">

                <v-card class="mt-5 elevation-0" outlined>
                  <v-toolbar flat color="pink" class="white--text">

                    <v-toolbar-title class="flex text-center">
                      <h3 class="font-weight-light">Iniciar sesion</h3>
                    </v-toolbar-title>
                  </v-toolbar>

                  <v-card-text class="pb-0 pt-8">
                    <h4 style="text-align:center;" class="mb-2 mt-2 red--text text--lighten-1 font-weight-light" v-if="login_errors.mensaje">
                      {{login_errors.mensaje[0]}}
                    </h4>
                    <v-form>
                      <v-text-field outlined small dense prepend-icon="mdi-account" v-model="user.email" label="Email" required></v-text-field>
                      <v-text-field outlined small dense prepend-icon="mdi-key" v-model="user.password" label="Contraseña" required type="password"></v-text-field>
                    </v-form>

                    <v-row dense>

                      <v-col cols="12" align="center" justify="center">
                        <v-btn rounded depressed :disabled="isloading" @click="Login" color="pink" class="white--text" :loading="isloading">
                          entrar
                        </v-btn>
                      </v-col>

                    </v-row>

                    <br>

                    <v-row dense>

                      <v-col cols="12" md="6" align="center" justify="center">
                        <router-link color="red" to="/registro">
                          <span class="blue--text"> ¿No tienes cuenta? <br> click aqui para registrarte</span>
                        </router-link>
                      </v-col>

                      <v-col cols="12" md="6" align="center" justify="center">
                        <router-link color="blue" to="/recuperar-password">
                          <span class="blue--text">Recuperar contraseña</span>
                        </router-link>
                      </v-col>
                    </v-row>

                  </v-card-text>

                  <v-card-actions>



                  </v-card-actions>
                </v-card>


              </v-col>
            </v-row>

          </v-container>

        </v-col>


        <v-col class="inspire d-none d-lg-block d-md-block" cols="12" md="7">
          <v-row align="center" justify="center" dense class="fill-h">
            <v-col cols="12" class="text-center">
              <h1 class="text-h2 font-weight-bold white--text">Bienvenido a <br> InmigraUSA</h1>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

    </v-content>
  </v-app>


</template>

<script>
  import auth from '../../auth/auth.js'
  export default {
    data() {
      return {
        auth2: null,
        user: {
          email: '',
          password: ''
        }
      }
    },
    mounted() {
      console.log(gapi);
      gapi.load('auth2', () => {
        this.auth2 = gapi.auth2.init({
          client_id: '118914531572-edt10f62o6vi0vf7mgut7hptcebm6l10.apps.googleusercontent.com',
          cookiepolicy: 'single_host_origin',
          // Request scopes in addition to 'profile' and 'email'
          //scope: 'additional_scope'
        })
        this.attachSignin(document.getElementById('customBtn'));
      });
    },
    methods: {
      Login() {
        auth.signin(this.user)
      },
      attachSignin(element) {
        /*console.log(element.id);
        this.auth2.attachClickHandler(element, {},
          function(googleUser) {
            console.log(googleUser.getBasicProfile().getName())
          },
          function(error) {
            alert(JSON.stringify(error, undefined, 2));
          })*/
      }
    },
    computed: {
      isloading: function() {
        return this.$store.getters.getloading
      },
      login_errors: function() {
        return this.$store.getters.get_login_errors
      },
    }
  }
</script>

<style>
  .playfair-display {
    font-family: "Playfair Display", serif !important;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
  }

  .inspire {
    /*background: linear-gradient(0deg, rgba(255, 0, 0, 0.5), rgba(255, 255, 255, 0)), url('/login_cover.jpg');*/
    background: url('/login_cover_blue.jpg');
    background-size: cover;
    background-position: center;
  }

  .cover-img {
    height: 100%
  }

  .back {
    background-color: #212121;
  }

  .fill-h {
    height: 100%;
  }

  .fill-height {
    height: 102vh;
    min-height: 102vh;
  }
</style>