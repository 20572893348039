<template>
  <v-container>

    <loader v-if="isloading"></loader>


    <v-card>
      <v-toolbar color="blue accent-2" dark flat>

        <v-app-bar-nav-icon></v-app-bar-nav-icon>

        <v-toolbar-title>Crear Negocio local en el app</v-toolbar-title>


        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="pink"></v-tabs-slider>

            <v-tab key="#tab-1">
              Información
            </v-tab>

            <v-tab key="#tab-2">
              Horarios
            </v-tab>

            <v-tab key="#tab-3">
              Ubicación
            </v-tab>

            <v-tab key="#tab-4">
              Imágenes
            </v-tab>

          </v-tabs>
        </template>
      </v-toolbar>

      <v-tabs-items v-model="tab">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-tab-item key="#tab-1">
            <v-card flat>
              <v-card-text>

                <v-row class="mt-3">

                  <v-col cols="12" md="4">
                    <v-select v-model="location.state" :rules="required_rule" small dense outlined :items="states" label="Estado" item-text="name" item-value="name"></v-select>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-select v-model="location.category" :rules="required_rule" small dense outlined :items="categorias" label="Categoria" item-text="name" item-value="id"></v-select>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-select v-model="location.subCategoryId" :rules="required_rule" small dense outlined :items="sub_categorias" label="Subcategoria" item-text="name" item-value="id"></v-select>
                  </v-col>

                </v-row>

                <v-row dense>
                  <v-col cols="12" md="8">
                    <v-text-field v-model="location.name" :rules="title_rule" small dense outlined label="Servicio"></v-text-field>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" md="8">
                    <v-textarea v-model="location.description" :rules="title_rule" small dense outlined label="Descripción del servicio"></v-textarea>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" md="4">
                    <v-text-field v-model="location.phone" :rules="title_rule" small dense outlined label="Teléfono"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field v-model="location.website" :rules="title_rule" small dense outlined label="Sitio web"></v-text-field>
                  </v-col>
                </v-row>

              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item key="#tab-2">
            <v-card flat>
              <v-card-text>

                <v-row v-for="(item, index) in location.workDays" class="mt-3">
                  <v-col cols="12" md="3">
                    <v-text-field :value="item.day" hide-details disabled small dense outlined label="Día"></v-text-field>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-switch class="mt-1" v-model="item.status" label="Cerrado / Abierto"></v-switch>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-menu ref="time_picker" v-model="item.menu" :close-on-content-click="false" :nudge-right="40" :return-value.sync="item.timeOpen" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="item.timeOpen" label="Open time" dense small outlined prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-time-picker v-if="item.menu" v-model="item.timeOpen" full-width @click:minute="$refs.time_picker[index].save(item.timeOpen)"></v-time-picker>
                    </v-menu>
                  </v-col>


                  <v-col cols="12" md="3">
                    <v-menu ref="time_picker_close" v-model="item.menu2" :close-on-content-click="false" :nudge-right="40" :return-value.sync="item.timeClose" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="item.timeClose" label="Close time" dense small outlined prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-time-picker v-if="item.menu2" v-model="item.timeClose" full-width @click:minute="$refs.time_picker_close[index].save(item.timeClose)"></v-time-picker>
                    </v-menu>
                  </v-col>

                </v-row>

              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item key="#tab-3" eager>
            <v-card flat>
              <v-card-text>
                <v-row class="mt-3">

                  <v-col cols="12" md="8">
                    <v-text-field v-model="location.address.street" :rules="title_rule" small dense outlined label="Calle"></v-text-field>
                  </v-col>

                </v-row>

                <v-row dense>
                  <v-col cols="12" md="4">
                    <v-text-field v-model="location.address.country" :rules="title_rule" small dense outlined label="Pais"></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field v-model="location.address.city" :rules="title_rule" small dense outlined label="Ciudad"></v-text-field>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" md="4">
                    <v-text-field v-model="location.address.apt" :rules="title_rule" small dense outlined label="Apto / Suite"></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field v-model="location.address.zipcode" :rules="title_rule" type="number" step="1" small dense outlined label="Código postal"></v-text-field>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" md="8">
                    <v-combobox :rules="title_rule" @keyup="comboChange" outlined v-model="selected_place" small dense :search-input.sync="locationSearchText" item-text="description" item-value="placeId" label="Ubicación" dense
                      :items="locationFoundItems">
                    </v-combobox>
                  </v-col>
                </v-row>

                <v-col cols="12" md="8">
                  <div style="height:350px" ref="map">

                  </div>
                </v-col>


              </v-card-text>
            </v-card>

          </v-tab-item>


          <v-tab-item key="#tab-4">
            <v-card flat>
              <v-card-text>
                <v-row class="mt-3" dense>

                  <v-col cols="12" md="8">
                    <v-file-input @change="fileChange" small outlined dense accept="image/*" label="Imagen Portada"></v-file-input>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" md="8">
                    <v-file-input @change="sliderChange" multiple small outlined dense accept="image/*" label="Imagenes Slider"></v-file-input>
                  </v-col>
                </v-row>


                <v-row dense>
                  <v-col class="mt-3" cols="12" md="3" v-for="(item,i) in location.images" :key="i">
                    <v-card>

                      <v-img :src="`${$awsPath}/${item}`" height="125" class="grey darken-4"></v-img>
                      <v-icon class="mt-2 mb-2 ml-2" @click="deleteImage(item)" medium color="red lighten-1">
                        mdi-trash-can
                      </v-icon>
                    </v-card>
                  </v-col>

                  <v-col class="mt-3" cols="12" md="3" v-for="(item,i) in imagenes" :key="i">
                    <v-card>
                      <v-img :src="item" height="125" class="grey darken-4"></v-img>
                      <v-icon class="mt-2 mb-2 ml-2" @click="imagenes.splice(imagenes.indexOf(item),1)" medium color="red lighten-1">
                        mdi-trash-can
                      </v-icon>
                    </v-card>
                  </v-col>
                </v-row>

              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-form>

      </v-tabs-items>
    </v-card>

    <v-row class="mt-4">
      <v-col cols="12" md="6">
        <v-btn :loading="isloading" @click="updateOrCreate" rounded class="white--text" depressed color="pink darken-1">guardar</v-btn>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
  import {
    services_service
  } from '~/services_service'

  import {
    categoria_service
  } from '~/categoria_service'


  import {
    location_service
  } from '~/location_service'

  import {
    states_service
  } from '~/states_service'

  import {
    Loader
  } from "@googlemaps/js-api-loader"

  import {
    google_place_service
  } from '~utils/google_place_service'

  import {
    location_rules
  } from '../mixins/location_rules'


  export default {
    mixins: [location_rules],

    data() {
      return {
        count: 0,
        google_loader: new Loader({
          apiKey: 'AIzaSyBM5gR8kKS8-xVr3lMyRnBpKQZObEPJIfE',
          version: 'weekly',
        }),

        tab: '#tab-1',

        geocoder: null,

        selected_place: {
          description: null
        },

        locationSearchText: null,

        found_items: [],

        includes_item: [],

        marker: null,

        map: null,

        places_service: null,

        states: [],

        categorias: [],

        sub_categorias: [],

        formImages: new FormData(),

        imagenes: [],

        formImage: new FormData(),

        image: '',

        location: {
          images: [],
          image: null,
          subCategoryId: null,
          category: null,
          name: null,
          description: null,
          state: null,
          status: true,
          review: [],
          phone: null,
          website: null,
          address: {
            street: "",
            country: "",
            city: "",
            zipcode: null,
            apt: "",
            coord_address: {
              lat: 28.04343229532778,
              lng: -81.60376120429687
            }
          },
          workDays: [{
              day: "Lunes",
              timeOpen: "",
              timeClose: "",
              numberDay: 1,
              status: false,
              menu: false,
              menu2: false
            },
            {
              day: "Martes",
              timeOpen: "",
              timeClose: "",
              numberDay: 2,
              status: false,
              menu: false,
              menu2: false
            },
            {
              day: "Miércoles",
              timeOpen: "",
              timeClose: "",
              numberDay: 3,
              status: false,
              menu: false,
              menu2: false
            },
            {
              day: "Jueves",
              timeOpen: "",
              timeClose: "",
              numberDay: 4,
              status: false,
              menu: false,
              menu2: false
            },
            {
              day: "Viernes",
              timeOpen: "",
              timeClose: "",
              numberDay: 5,
              status: false,
              menu: false,
              menu2: false
            },
            {
              day: "Sábado",
              timeOpen: "",
              timeClose: "",
              numberDay: 6,
              status: false,
              menu: false,
              menu2: false
            },
            {
              day: "Domingo",
              timeOpen: "",
              timeClose: "",
              numberDay: 0,
              status: false,
              menu: false,
              menu2: false
            }
          ],
          closeDays: [],
          startIn: new Date(),
          expireIn: new Date()
        },
        multiple: false,
        single: false,
      }
    },

    watch: {
      'location.category'(n, o) {
        this.getSubcategories(n)
      },

      'locationSearchText'(n, o) {
        if (!n) {
          return null
        }
        this.selected_place = n
        this.places_service.getPlacePredictions({
          input: n,
          types: ['geocode']
        }, this.displaySuggestions)
      },

      'selected_place'(n, o) {
        if (!n.place_id) {
          return null
        }
        //this.servicio.office = n.description
        this.findPlaceId(n.place_id)
      }

    },

    created() {
      this.$route.query.id ? this.getLocation(this.$route.query.id) : null
      this.getCategories('COMPANY_ROLE')
      this.getStates()
      this.getCount()
      //this.servicio.timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone
    },

    mounted() {
      this.google_loader.load().then(async () => {
        const {
          Map
        } = await google.maps.importLibrary('maps')

        const {
          Marker
        } = await google.maps.importLibrary('marker')

        const {
          AutocompleteService
        } = await google.maps.importLibrary('places')

        const {
          Geocoder
        } = await google.maps.importLibrary("geocoding")

        const default_position = {
          lat: this.location.address.coord_address.lat,
          lng: this.location.address.coord_address.lng
        }

        this.map = new Map(this.$refs.map, {
          center: default_position,
          zoom: 12,
        })

        const options = {
          types: ['geocode', 'geometry'],
          fields: ["place_id", "geometry", "name", "formatted_address"],
          placeIdOnly: true
        }

        this.places_service = new AutocompleteService(null, options)

        this.geocoder = new Geocoder()

        this.marker = new Marker({
          position: default_position,
          map: this.map
        })

      })
    },

    methods: {
      getCount() {
        location_service.get_count().then(res => {
          this.count = res.data
        }, res => {})
      },
      getLocation(id) {
        location_service.get_busines_location(id).then(res => {
          this.location = res.data
        }, res => {
          this.$toast.error('Error consultando busines location')
        })
      },
      comboChange(data) {
        //console.log('data',data);
      },
      setMakerPosition(lat, lng) {
        this.marker.setPosition({
          lat: lat,
          lng: lng
        })
      },

      setMapPosition(lat, lng) {
        this.map.setCenter({
          lat: lat,
          lng: lng
        })
      },

      async findPlaceId(place_id) {
        let results = await google_place_service.findPlaceById(place_id)
        this.location.address.coord_address.lat = results[0].geometry.location.lat()
        this.location.address.coord_address.lng = results[0].geometry.location.lng()
        this.setMapPosition(this.location.address.coord_address.lat, this.location.address.coord_address.lng)
        this.setMakerPosition(this.location.address.coord_address.lat, this.location.address.coord_address.lng)
      },

      displaySuggestions(predictions, status) {
        if (status !== google.maps.places.PlacesServiceStatus.OK) {
          return null
        }
        this.found_items = predictions
      },

      getStates() {
        states_service.get_states().then(res => {
          this.states = res.data
        }, res => {
          this.$toast.error('Error consultando estados')
        })
      },


      getCategories(role) {
        categoria_service.get_categorias(role).then(res => {
          this.categorias = res.data
        }, res => {
          this.$toast.error('Error consultando categorias')
        })
      },

      getSubcategories(categoria_id) {
        categoria_service.get_sub_categorias(categoria_id).then(res => {
          this.sub_categorias = res.data
        }, res => {
          this.$toast.error('Error consultando sub categorias')
        })
      },

      updateOrCreate() {
        if (!this.$refs.form.validate()) {
          return this.$toast.warn('Por favor completar todos los campos')
        }
        this.location.workDays.forEach((item, i) => {
          delete(item.menu)
          delete(item.menu2)
        })
        this.location.address.zipcode = parseInt(this.location.address.zipcode)
        this.location.id ? this.updateLocation() : this.saveLocation()
      },

      saveLocation() {
        if (this.count >= this.user_pack.packageDirectoryId.advertQuantity) {
          return this.$toast.warn('Has alcanzado el limite de anuncios')
        }
        location_service.save_busines_location(this.location).then(res => {
          this.uploadFiles(res.data.id)
          this.uploadSingleFile(res.data.id)
        }, res => {
          this.$toast.error('Error guardando business location')
        })
      },

      updateLocation() {
        location_service.update_busines_location(this.location).then(res => {
          this.uploadFiles(res.data.id)
          this.uploadSingleFile(res.data.id)
        }, res => {
          this.$toast.error('Error guardando business location')
        })
      },

      deleteImage(item) {
        this.location.images.splice(this.location.images.indexOf(item), 1)
      },

      getService(id) {
        services_service.get_service(id).then(res => {
          this.transformObject(res.data)
        }, res => {
          this.$toast.error('Error consultando servicio, intente nuevamente')
        })
      },

      fileChange(file) {
        this.formImage.append('img', file)
        this.image = window.URL.createObjectURL(file)
      },

      sliderChange(files) {
        let archivos = event.target.files
        for (let archivo of archivos) {
          this.imagenes.push(window.URL.createObjectURL(archivo))
          this.formImages.append('img', archivo)
        }
      },

      handleRedirect() {
        if (!this.multiple && !this.single) {
          this.$router.push('/listar-business-location')
        }
      },

      uploadFiles(id) {
        if (this.imagenes.length == 0) {
          this.handleRedirect()
          return this.$toast.sucs('¡Actualizado con exito!')
        }

        this.multiple = true

        this.axios.post(`/upload/multiple-business-location/${id}`, this.formImages, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(res => {
          this.$toast.sucs('Archivos cargados con exito!')
          this.multiple = false
          this.handleRedirect()
        }, res => {
          this.multiple = false
        })
      },

      uploadSingleFile(id) {
        if (!this.image) {
          this.handleRedirect()
          return this.$toast.sucs('¡Actualizado con exito!')
        }
        this.single = true
        this.axios.post(`/upload/single-business-location/${id}`, this.formImage, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(res => {
          this.$toast.sucs('Archivos cargados con exito!')
          this.single = false
          this.handleRedirect()
        }, res => {
          this.single = false
        })
      },

      transformObject(data) {
        data.categoryId = data.categoryId.id
        data.userId = data.userId.id
        data.subCategoryId = data.subCategoryId.id
        data.packageId = data.packageId.id
        this.locationSearchText = data.office
        this.setMapPosition(parseFloat(data.lat), parseFloat(data.long))
        this.setMakerPosition(parseFloat(data.lat), parseFloat(data.long))
        this.servicio = JSON.parse(JSON.stringify(data))
      },
    },

    computed: {
      isloading() {
        return this.$store.getters.getloading
      },
      errors() {
        return this.$store.getters.geterrors
      },
      user() {
        return JSON.parse(localStorage.getItem('user'))
      },
      user_pack() {
        return this.$store.getters.getpackage
      },
      aws_path() {
        return this.$store.getters.get_aws_path
      },
      locationFoundItems() {
        return this.found_items
      }
    }
  }
</script>

<style scoped="true">
  .v-tab {
    font-weight: 700;
  }
</style>