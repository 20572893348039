<template id="">
  <v-dialog v-model="dialog" width="760">

    <v-card>
      <v-card-title class="text-h5 blue white--text">
        Center Time
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row class="mt-3" dense>

            <v-col cols="12" md="3">
              <v-text-field v-model="item.code" hide-details outlined label="Code" small dense></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field v-model="item.name" hide-details outlined label="Name" small dense></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field v-model="item.days" hide-details outlined label="Days" small dense></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-btn @click="addItem" :loading="isloading" small dense depressed rounded class="white--text mt-1" color="green">guardar</v-btn>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12">
              <v-data-table :headers="headers" :items="items" disable-pagination hide-default-footer item-key="key" class="elevation-1 mt-6">
                <template v-slot:item.action="{ item }">

                  <v-icon @click="setItem(item)" small class="mr-2" color="blue">
                    mdi-pencil
                  </v-icon>

                  <v-icon @click="deleteItem(item)" small color="red lighten-1">
                    mdi-trash-can
                  </v-icon>

                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="white--text" color="green" :loading="isloading" depressed rounded @click="dialog = false">
          Aceptar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>


<script>
  export default {
    data() {
      return {
        dialog: false,

        items: [],

        item: {
          id: (new Date()).getTime(),
          code: null,
          name: null,
          days: 0
        },

        headers: [{
            text: 'Code',
            value: 'code',
          },
          {
            text: 'Name',
            value: 'name',
          },
          {
            text: 'Days',
            value: 'days',
          },
          {
            text: 'Acciones',
            value: 'action',
            sortable: false
          },
        ],
      }
    },

    created() {
      this.$parent.$on('open_dialog', items => {
        this.dialog = true
        this.items = items
      })
    },

    methods: {

      addItem() {
        if (Object.values(this.item).some(x => x == '' || x == null)) {
          return this.$toast.warn('Por favor completar todos los campos')
        }

        if (this.item.is_editing) {
          let index = this.items.findIndex(x => x.id == this.item.id)
          this.$set(this.items, index, this.item)
        } else {
          this.items.push(this.item)
        }

        this.item = {
          id: (new Date()).getTime(),
          code: null,
          name: null,
          days: 0
        }
      },

      setItem(item) {
        item.is_editing = true
        item.id = (new Date()).getTime()
        this.item = JSON.parse(JSON.stringify(item))
      },

      deleteItem(item) {
        this.items.splice(this.items.indexOf(item), 1)
      },

    },

    computed: {
      isloading() {
        return this.$store.getters.getloading
      }
    }
  }
</script>