export const perfil_rules = {
  data() {
    return {
      valid: true,
      title_rules: [
        v => !!v || 'Requerido',
        v => (v && v.length <= 50) || 'Titulo debe contener menos de 50 caracteres',
      ],
      description_rule: [
        v => !!v || 'Requerido',
        v => (v && v.length <= 500) || 'Descripción debe contener menos de 500 caracteres',
      ],
      categoria_rule: [
        v => !!v || 'Requerido'
      ],
      subcategoria_rule: [
        v => !!v || 'Requerido'
      ],
    }
  },
}