<template>
  <v-container>

    <v-card>
      <v-toolbar flat color="blue accent-2" class="white--text">
        <v-btn to="/" icon text>
          <v-icon small class="white--text">mdi-home</v-icon>
        </v-btn>
        <v-toolbar-title>
          <h3 class="font-weight-light">Productos App</h3>
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <loader v-if="isloading"></loader>
        <v-row dense>
          <v-col cols="12" md="3">
            <v-select v-model="tipo" outlined hide-details small dense :items="tipos" label="Tipo" item-text="nombre" item-value="id"></v-select>
          </v-col>
          <v-col cols="12" md="3">
            <v-btn :to="`/guardar-producto-app`" depressed rounded class="white--text" color="green">
              nuevo producto
            </v-btn>
          </v-col>

        </v-row>

        <v-row dense>
          <v-col cols="12">
            <v-data-table :headers="headers" :items="items" disable-pagination hide-default-footer item-key="key" class="elevation-1 mt-6">

              <template v-slot:item.isActive="{ item }">
                <v-chip class="white--text" :color="item.isActive ? 'green' : 'red lighten-1'" small dense>
                  {{item.isActive ? 'activo' : 'desactivado'}}
                </v-chip>
              </template>

              <template v-slot:item.files="{ item }">

                <router-link :to="`/subir-archivos-producto/${item.id}`">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="mr-2" color="orange" dark v-bind="attrs" v-on="on">
                        mdi-file-upload-outline
                      </v-icon>
                    </template>
                    <span>Subir archivos</span>
                  </v-tooltip>
                </router-link>


              </template>

              <template v-slot:item.action="{ item }">

                <router-link :to="`/guardar-producto-app?id=${item.id}`">
                  <v-icon small class="mr-2" color="blue">
                    mdi-pencil
                  </v-icon>
                </router-link>

                <v-icon @click="setProducto(item)" small color="red lighten-1">
                  mdi-trash-can
                </v-icon>
              </template>

            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>

    </v-card>

    <v-confirm-dialog v-on:delete="deleteProducto">
    </v-confirm-dialog>

  </v-container>
</template>

<script>
  import {
    productos_service
  } from '~/productos_service'

  export default {

    data() {
      return {
        tipos: [{
            id: 'all',
            nombre: 'All'
          },
          {
            id: 'regular',
            nombre: 'Regular'
          },
          {
            id: 'subscription',
            nombre: 'Suscripción'
          }
        ],

        tipo: 'all',

        items: [],

        item: {},

        headers: [{
            text: 'Nombre',
            value: 'name',
          },
          {
            text: 'Precio ($)',
            value: 'price',
          },
          {
            text: 'Oferta ($)',
            value: 'priceOffer',
          },
          {
            text: 'Activo',
            value: 'isActive',
          },
          {
            text: 'Subir archivos',
            value: 'files',
            align: 'center'
          },
          {
            text: 'Acciones',
            value: 'action',
            sortable: false
          },
        ],
      }
    },

    watch: {
      tipo: {
        immediate: true,
        handler(n) {
          this.getPaquetes(n)
        }
      }
    },


    methods: {
      setProducto(item) {
        this.item = item
        this.$emit('confirm_open')
      },
      deleteProducto() {
        productos_service.delete_producto(this.item.id).then(res => {
          this.items.splice(this.items.indexOf(this.item), 1)
          this.$toast.sucs('Producto eliminado con exito')
        }, res => {
          this.$toast.error('Error consultando productos')
        })
      },
      getPaquetes(tipo) {
        productos_service.get_productos(tipo).then(res => {
          this.items = res.data
        }, res => {
          this.$toast.error('Error consultando productos')
        })
      },
    },
    computed: {
      isloading() {
        return this.$store.getters.getloading
      },
      errors() {
        return this.$store.getters.geterrors
      },
      user() {
        return JSON.parse(localStorage.getItem('user'))
      },
      categoria() {
        return this.$store.getters.get_categoria
      }
    }
  }
</script>