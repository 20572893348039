<template>
  <v-container>

    <v-card>
      <v-toolbar flat color="blue accent-2" class="white--text">
        <v-btn to="/lista-adverts" icon text>
          <v-icon small class="white--text">mdi-arrow-left-bold</v-icon>
        </v-btn>
        <v-toolbar-title>
          <h3 class="font-weight-light">Crear / Editar Advert</h3>
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <loader v-if="isloading"></loader>

        <v-form ref="form" v-model="valid" lazy-validation>

          <v-row class="mt-3" dense>
            <v-col cols="12" md="6">
              <v-text-field v-model="item.title" :rules="title_rule" small dense outlined label="Titulo" type="text"></v-text-field>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" md="3">
              <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="formatDate" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field outlined small dense v-model="formatDate" label="Fecha inicio" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="formatDate" no-title scrollable :min="min_date">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(formatDate)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="3">
              <v-select v-model="item.expire" :items="expires" outlined small dense label="Expira en"></v-select>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" md="6">
              <v-text-field v-model="item.url" small dense outlined label="URL"></v-text-field>
            </v-col>
          </v-row>


          <v-row dense>
            <v-col cols="12" md="3">
              <v-checkbox class="mt-0 " v-model="item.status" label="Activo"></v-checkbox>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" md="6">
              <v-file-input @change="fileChange" small outlined dense accept="image/*" label="Imagen"></v-file-input>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <v-btn @click="updateOrCreate" :loading="isloading" rounded class="white--text" depressed color="pink darken-1">guardar</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

    </v-card>


  </v-container>
</template>

<script>
  import {
    advert_service
  } from '~/advert_service'

  import {
    advert_rules
  } from '../mixins/advert_rules'

  import moment from 'moment'

  export default {
    mixins: [advert_rules],

    data() {
      return {
        formData: new FormData(),
        expires: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        menu: false,

        item: {
          title: null,
          status: true,
          state: "Florida",
          startDate: null,
          type: "ADMIN_ROLE",
          url: null,
          expire: null,
          image: ''
        },
        formatDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        min_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      }
    },

    watch: {
      'formatDate': {
        handler(n, o) {
          let unix = moment(n).valueOf()
          this.item.startDate = unix
        }
      }
    },


    created() {
      this.$route.query.id ? this.getAdvert(this.$route.query.id) : null
    },

    methods: {
      getAdvert(id) {
        advert_service.get_advert(id).then(res => {
          this.item = res.data
          this.formatDate = moment(parseInt(res.data.startDate)).format('YYYY-MM-DD')
          this.item.expire = parseInt(res.data.expire)
        }, res => {
          this.$toast.error('Error consultando registro')
        })
      },

      updateOrCreate() {
        if (!this.$refs.form.validate()) {
          return this.$toast.warn('Error de validando formulario')
        }
        this.item.expire = parseInt(this.item.expire)
        this.item.id ? this.updateProducto() : this.saveProducto()
      },

      saveProducto() {
        if (!this.formData.has('img')) {
          return this.$toast.warn('Debe adjuntar una imagen')
        }
        advert_service.save_advert(this.item).then(res => {
          this.$toast.sucs('Advert guardado con exito')
          this.uploadImages(res.data.id, true)
        }, res => {
          this.$toast.error('Error guardando servicio')
        })
      },

      updateProducto() {
        advert_service.update_advert(this.item).then(res => {
          this.$toast.sucs('Advert actualizado con exito')
          this.uploadImages(res.data.id, false)
        }, res => {
          this.$toast.error('Error guardando servicio')
        })
      },

      fileChange(file) {
        this.formData.append('img', file)
      },

      uploadImages(id, optional) {
        if (!this.formData.has('img') && optional) {
          return this.$toast.warn('Debe adjuntar una imagen')
        }

        if (!this.formData.has('img') && !optional) {
          return this.$router.push('/lista-adverts')
        }

        this.axios.post(`/upload/adverts/${id}`, this.formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(res => {
          this.$toast.sucs('Archivos cargados con exito!')
          this.$router.push('/lista-adverts')
        }, res => {
          this.$toast.error('Algo ha salido mal!')
        })
      },

    },
    computed: {
      isloading() {
        return this.$store.getters.getloading
      },
      errors() {
        return this.$store.getters.geterrors
      },
      user() {
        return JSON.parse(localStorage.getItem('user'))
      },
    }
  }
</script>