<template>
  <v-container>
    <div style="height:300px" ref="map">

    </div>
  </v-container>
</template>

<script>
  import {
    Loader
  } from "@googlemaps/js-api-loader"

  export default {
    props: ['cords'],

    data() {
      return {
        google_loader: new Loader({
          apiKey: 'AIzaSyBM5gR8kKS8-xVr3lMyRnBpKQZObEPJIfE',
          version: 'weekly',
        }),
        geocoder: null,
        marker: null,
        map: null,
      }
    },


    mounted() {
      this.google_loader.load().then(async () => {
        const {
          Map
        } = await google.maps.importLibrary('maps')

        const {
          Marker
        } = await google.maps.importLibrary('marker')


        const {
          Geocoder
        } = await google.maps.importLibrary("geocoding")

        const default_position = {
          lat: parseFloat(this.cords.lat),
          lng: parseFloat(this.cords.long)
        }

        this.map = new Map(this.$refs.map, {
          center: default_position,
          zoom: 12,
        })

        const options = {
          types: ['geocode', 'geometry'],
          fields: ["place_id", "geometry", "name", "formatted_address"],
          placeIdOnly: true
        }


        this.geocoder = new Geocoder()

        this.marker = new Marker({
          position: default_position,
          map: this.map
        })

      })
    },

    watch: {
      'cords': {
        deep: true,
        handler(n) {
          console.log(n);
          this.setMakerPosition(n.lat, n.long)
          this.setMapPosition(n.lat, n.long)
        }
      }
    },

    methods: {

      setMakerPosition(lat, lng) {
        this.marker.setPosition({
          lat: lat,
          lng: lng
        })
      },

      setMapPosition(lat, lng) {
        this.map.setCenter({
          lat: lat,
          lng: lng
        })
      },

    },
  }
</script>