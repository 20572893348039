<template>
  <v-container>

    <loader v-if="isloading"></loader>

    <v-card>
      <v-toolbar color="blue accent-2" dark flat>

        <v-app-bar-nav-icon></v-app-bar-nav-icon>

        <v-toolbar-title>Crear anuncio directorio - {{count}} / {{user_pack.packageDirectoryId.advertQuantity}}</v-toolbar-title>

        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="pink"></v-tabs-slider>

            <v-tab key="#tab-1">
              Información
            </v-tab>

            <v-tab key="#tab-2">
              Preview
            </v-tab>

          </v-tabs>
        </template>
      </v-toolbar>

      <v-tabs-items v-model="tab">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-tab-item key="#tab-1">
            <v-card flat>
              <v-card-text>
                <v-row class="mt-3">

                  <v-col cols="12" md="4">
                    <v-select v-model="anuncio.state" :rules="required_rule" small dense outlined :items="states" label="Estado" item-text="name" item-value="name"></v-select>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-select v-model="anuncio.categoryId" :rules="required_rule" small dense outlined :items="categorias" label="Categoria" item-text="name" item-value="id"></v-select>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-select v-model="anuncio.subCategoryId" :rules="required_rule" small dense outlined :items="sub_categorias" label="Subcategoria" item-text="name" item-value="id"></v-select>
                  </v-col>

                </v-row>

                <v-row dense>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="anuncio.title" :rules="title_rule" small dense outlined label="Titulo"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="anuncio.business" :rules="title_rule" small dense outlined label="Nombre empresa"></v-text-field>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12">
                    <v-text-field v-model="anuncio.shortDescription" :rules="title_rule" small dense outlined label="Descripción corta"></v-text-field>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="anuncio.phone" :rules="title_rule" small dense outlined label="Teléfono"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="anuncio.email" :rules="email_rules" small dense outlined label="Email"></v-text-field>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="anuncio.linkWeb" :rules="web_site_rule" small dense outlined label="Web (opcional)"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="anuncio.linkVideo" :rules="video_rule" small dense outlined label="URL video (opcional)"></v-text-field>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col v-show="!change_address" cols="12" md="6">
                    <v-text-field disabled v-model="anuncio.address" outlined small dense label="Dirección" append-icon="mdi-map-marker"></v-text-field>
                  </v-col>

                  <v-col v-show="!change_address" cols="12" md="6">
                    <v-btn @click="change_address = true" class="white--text" color="blue">actualizar dirección</v-btn>
                  </v-col>

                  <v-col v-show="change_address" cols="12" md="6">
                    <v-google-autocomplete v-on:place_find="placeFind"></v-google-autocomplete>
                  </v-col>
                </v-row>



                <v-row dense>
                  <v-col cols="12" md="6">
                    <vue-editor :editor-toolbar="customToolbar" v-model="anuncio.description" />
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" md="6">
                    <v-file-input class="mt-3" @change="sliderChange" multiple small outlined dense accept="image/*" label="Imagenes Slider"></v-file-input>
                  </v-col>
                </v-row>


              </v-card-text>
            </v-card>
          </v-tab-item>


          <v-tab-item key="#tab-2">
            <preview-directorio v-on:delete_local_img="deleteLocalImg" v-on:delete_anuncio_img="deleteAnuncioImg" :anuncio="anuncio" :imagenes="imagenes">
            </preview-directorio>
          </v-tab-item>

        </v-form>

      </v-tabs-items>
    </v-card>

    <v-row class="mt-4">
      <v-col cols="12" md="6">
        <v-btn @click="updateOrCreate" :loading="isloading" rounded class="white--text" depressed color="pink darken-1">guardar</v-btn>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
  import {
    directorio_service
  } from '~/directorio_service'

  import {
    categoria_service
  } from '~/categoria_service'

  import {
    states_service
  } from '~/states_service'

  import {
    upload_service
  } from '~/upload_service'

  import {
    directorio_rules
  } from '../mixins/directorio_rules'

  import {
    VueEditor
  } from "vue2-editor";

  import previewDirectorio from './previewDirectorio'

  export default {
    mixins: [directorio_rules],
    components: {
      previewDirectorio
    },
    data() {
      return {
        count: 0,
        change_address: false,
        customToolbar: [
          ["bold", "italic", "underline"],
          [{
            list: "ordered"
          }, {
            list: "bullet"
          }],
        ],
        valid: false,
        has_address: false,
        tab: '#tab-1',
        states: [],
        categorias: [],
        sub_categorias: [],
        anuncio: {
          id: null,
          title: null,
          business: null,
          status: null,
          state: null,
          categoryId: null,
          subCategoryId: null,
          packageDirectoryId: null,
          description: null,
          shortDescription: null,
          phone: null,
          email: null,
          address: null,
          lat: -1000,
          long: -1000,
          linkWeb: null,
          linkVideo: null,
          video_id: null,
          image: []
        },
        imagenes: [],
        formImages: new FormData(),
      }
    },


    watch: {
      'anuncio.categoryId'(n, o) {
        this.getSubcategories(n)
      },
      'anuncio.linkVideo'(n, o) {
        const video_id = n.split("?v=")[1]
        this.anuncio.video_id = `https://www.youtube.com/embed/${video_id}`
      }
    },

    created() {
      this.$route.query.id ? this.getAnuncio(this.$route.query.id) : this.change_address = true
      this.getCategories('COMPANY_ROLE')
      this.getStates()
      this.getCount()
    },

    methods: {
      getCount() {
        directorio_service.get_count().then(res => {
          this.count = res.data
        }, res => {})
      },
      deleteAnuncioImg(img) {
        this.anuncio.image.splice(this.anuncio.image.indexOf(img), 1)
      },

      deleteLocalImg(img) {
        let i = this.imagenes.indexOf(img)
        this.imagenes.splice(i, 1)

        let values = this.formImages.getAll("img")

        values.splice(i, 1)

        for (var key of this.formImages.keys()) {
          this.formImages.delete(key)
        }

        for (let archivo of values) {
          this.formImages.append('img', archivo)
        }
      },

      placeFind(cords) {
        this.$set(this.anuncio, 'address', cords.formatted_address)
        this.$set(this.anuncio, 'lat', cords.lat)
        this.$set(this.anuncio, 'long', cords.lng)
        this.has_address = true
      },

      updateOrCreate() {
        this.anuncio.id ? this.updateDirectorio() : this.saveDirectorio()
      },

      saveDirectorio() {
        if (this.count >= this.user_pack.packageDirectoryId.advertQuantity) {
          return this.$toast.warn('Has alcanzado el limite de anuncios')
        }

        if (!this.$refs.form.validate()) {
          return this.$toast.warn('Por favor completar todos los campos')
        }

        if (!this.has_address) {
          return this.$toast.warn('Debe seleccionar una ubicación válida')
        }

        this.anuncio.packageDirectoryId = this.user_pack.packageDirectoryId.id
        this.anuncio.bookingDirectoryId = this.user_pack.id

        directorio_service.save_anuncio(this.anuncio).then(res => {
          this.uploadFiles(res.data.id, true)
        }, res => {
          this.$toast.error('Error guardando anuncio')
        })
      },

      updateDirectorio() {
        if (!this.$refs.form.validate()) {
          return this.$toast.warn('Por favor completar todos los campos')
        }
        this.anuncio.subCategoryId = this.anuncio.subCategoryId.id
        this.anuncio.packageDirectoryId = this.user_pack.packageDirectoryId.id
        this.anuncio.bookingDirectoryId = this.user_pack.id

        directorio_service.update_anuncio(this.anuncio.id, this.anuncio).then(res => {
          this.uploadFiles(res.data.id, false)
        }, res => {
          this.$toast.error('Error guardando anuncio')
        })
      },

      uploadFiles(id, required) {

        if (!this.formImages.has('img') && !required) {
          this.$toast.success('¡Anuncio guardado con exito!')
          this.$router.push('/mis-anuncios')
          return null;
        }
        upload_service.upload_files('adverts-directory', id, this.formImages).then(res => {
          this.$toast.success('¡Anuncio guardado con exito!')
          this.$router.push('/mis-anuncios')
        }, res => {
          this.$toast.warn('¡Error cargando archivos, intente nuevamente!')
        })
      },

      getAnuncio(id) {
        directorio_service.get_anuncio(id).then(res => {
          this.anuncio = res.data
        }, res => {
          this.$toast.error('Error consultando anuncio')
        })
      },

      getStates() {
        states_service.get_states().then(res => {
          this.states = res.data
        }, res => {
          this.$toast.error('Error consultando estados')
        })
      },

      getCategories(role) {
        categoria_service.get_categorias(role).then(res => {
          this.categorias = res.data
        }, res => {
          this.$toast.error('Error consultando categorias')
        })
      },

      getSubcategories(categoria_id) {
        categoria_service.get_sub_categorias(categoria_id).then(res => {
          this.sub_categorias = res.data
        }, res => {
          this.$toast.error('Error consultando sub categorias')
        })
      },

      sliderChange(files) {
        let archivos = event.target.files
        for (let archivo of archivos) {
          this.imagenes.push(window.URL.createObjectURL(archivo))
          this.formImages.append('img', archivo)
        }
      },
    },

    computed: {
      isloading() {
        return this.$store.getters.getloading
      },
      errors() {
        return this.$store.getters.geterrors
      },
      user() {
        return JSON.parse(localStorage.getItem('user'))
      },
      user_pack() {
        return this.$store.getters.getpackage
      },
      aws_path() {
        return this.$store.getters.get_aws_path
      },
      locationFoundItems() {
        return this.found_items
      }
    }
  }
</script>

<style scoped="true">
  .v-tab {
    font-weight: 700;
  }
</style>