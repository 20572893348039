export const servicio_rules = {
  data() {
    return {
      valid: true,
      name_rule: [
        v => !!v || 'Requerido',
        v => (v && v.length <= 60) || 'Supera el limite de 60 caracteres',
      ],
      title_rule: [
        v => !!v || 'Requerido',
        v => (v && v.length <= 60) || 'Supera el limite de 60 caracteres',
      ],
      description_rule: [
        v => !!v || 'Requerido',
        v => (v && v.length <= 1200) || 'Supera el limite de 1200 caracteres',
      ],
      phone_rule: [
        v => !!v || 'Requerido'
      ],
      zipcode_rule: [
        v => !!v || 'Requerido'
      ],
      categoria_rule: [
        v => !!v || 'Requerido'
      ],
      precio_rule: [
        v => !!v || 'Requerido'
      ],
      subcategoria_rule: [
        v => !!v || 'Requerido'
      ],
      location_rule: [
        v => !!v || 'Requerido'
      ],
      youtube_rule: [
        v => !v ? true : v.includes('https://www.youtube.com/watch') || 'Debe introducir un link valido'
      ],

    }
  },
}