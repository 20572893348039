import ListaServicios from '../componentes/ListaServicios'
import FormServicio from '../componentes/FormServicio'
import CalendarioServicio from '../componentes/CalendarioServicio'
import ListaServiciosByAdmin from '../componentes/ListaServiciosByAdmin'

const routes = [
  ...route('/servicios', ListaServicios, {
    auth: true,
    require_consultant_handy_package: true,
  }),
  ...route('/crear-servicio', FormServicio, {
    auth: true,
    require_consultant_handy_package: true,
  }),
  ...route('/calendario-servicio', CalendarioServicio, {
    auth: true,
    require_consultant_handy_package: true,
  }),
  ...route('/lista-servicios', ListaServiciosByAdmin, {
    auth: true,
    require_admin: true
  }),
]

function route(path, component = Default, meta = {}) {
  return [{
    path,
    component,
    meta
  }]
}

export default routes