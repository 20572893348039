<template>
  <v-container>

    <v-card>
      <v-toolbar flat color="blue accent-2" class="white--text">
        <v-btn to="/ucsis-type" icon text>
          <v-icon small class="white--text">mdi-arrow-left-bold</v-icon>
        </v-btn>
        <v-toolbar-title>
          <h3 class="font-weight-light">Crear / Editar Ucsis</h3>
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <loader v-if="isloading"></loader>

        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row class="mt-3" dense>

            <v-col cols="12" md="4">
              <v-text-field v-model="item.type" :rules="type_rule" small dense outlined label="Tipo"></v-text-field>
            </v-col>

            <v-col cols="12" md="8">
              <v-text-field v-model="item.title" :rules="title_rule" small dense outlined label="Titulo"></v-text-field>
            </v-col>

          </v-row>

          <v-row dense>

            <v-col cols="12">
              <v-text-field v-model="item.description" :rules="description_rule" small dense outlined label="Descripción corta"></v-text-field>
            </v-col>

          </v-row>

          <v-row dense>
            <v-col cols="12" md="3">
              <v-text-field v-model="item.averageTime" :rules="avegare_rules" type="number" step="1" small dense hide-details outlined label="Tiempo aproximado"></v-text-field>
            </v-col>

            <v-col cols="12" md="2">
              <v-checkbox class="mt-1" v-model="item.isActive" label="Activo"></v-checkbox>
            </v-col>

          </v-row>

          <!-- items -->
          <v-row align="center" dense>

            <v-col cols="12">
              <h3 class="mt-4 mb-4 blue--text">Time</h3>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field v-model="time_item.country" hide-details outlined label="Pais" small dense></v-text-field>
            </v-col>


            <v-col cols="12" md="3">
              <v-text-field v-model="time_item.days" hide-details outlined label="Días" type="number" step="1" small dense></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field v-model="time_item.year" hide-details outlined label="Año" type="number" step="1" small dense></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-btn @click="addItem" depressed rounded class="whitetext" small dense color="green">
                guardar
              </v-btn>
            </v-col>

          </v-row>

          <v-row class="mt-4" dense>
            <v-col cols="12">

              <v-data-table :headers="headers" :items="item.time" disable-pagination hide-default-footer item-key="key" class="elevation-1">

                <template v-slot:item.action="{ item }">

                  <v-icon @click="openDialog(item)" small class="mr-2" color="orange">
                    mdi-calendar
                  </v-icon>

                  <v-icon @click="setItem(item)" small class="mr-2" color="blue">
                    mdi-pencil
                  </v-icon>

                  <v-icon @click="deleteItem(item)" small color="red lighten-1">
                    mdi-trash-can
                  </v-icon>

                </template>

              </v-data-table>
            </v-col>

          </v-row>


          <v-row>
            <v-col cols="12" md="6">
              <v-btn @click="updateOrCreate" :loading="isloading" rounded class="white--text" depressed color="pink darken-1">guardar</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

    </v-card>

    <dialog-center-case>
    </dialog-center-case>


  </v-container>
</template>

<script>
  import {
    ucsis_service
  } from '~/ucsis_service'

  import {
    ucsis_rules
  } from '../mixins/ucsis_rules'

  import dialogCenterCase from './dialogCenterCase'

  export default {
    components: {
      'dialog-center-case': dialogCenterCase
    },

    mixins: [ucsis_rules],

    data() {
      return {
        time_item: {
          country: null,
          year: null,
          days: null,
        },

        item: {
          id: null,
          type: null,
          title: null,
          description: null,
          isActive: true,
          averageTime: 100,
          time: []
        },

        headers: [{
            text: 'Pais',
            value: 'country',
          },
          {
            text: 'Dias',
            value: 'days',
          },
          {
            text: 'Año',
            value: 'year',
          },
          {
            text: 'Acciones',
            value: 'action',
            sortable: false
          },
        ],
      }
    },

    created() {
      let id = this.$route.query.id;
      id ? this.getUcsis(id) : null
    },

    methods: {
      openDialog(item) {
        item.centerCase ? item.centerCase : item.centerCase = []
        this.$emit('open_dialog', item.centerCase)
      },
      addItem() {
        if (Object.values(this.time_item).some(x => x == '' || x == null)) {
          return this.$toast.warn('Por favor completar todos los campos')
        }

        if (this.time_item.is_editing) {
          let index = this.item.time.findIndex(x => x.id == this.time_item.id)
          this.$set(this.item.time, index, this.time_item)
        } else {
          this.item.time.push(this.time_item)
        }

        this.time_item = {
          description: null,
          activated: true
        }
      },

      setItem(item) {
        item.is_editing = true
        item.id = (new Date()).getTime()
        this.time_item = JSON.parse(JSON.stringify(item))
      },

      deleteItem(item) {
        this.item.items.splice(this.item.items.indexOf(item), 1)
      },

      getUcsis(id) {
        ucsis_service.get_ucsis_by_id(id).then(res => {
          this.item = res.data
        }, res => {
          this.$toast.error('Error consultando registro')
        })
      },

      updateOrCreate() {
        if (!this.$refs.form.validate()) {
          return this.$toast.warn('Error de validando formulario')
        }
        this.item.id ? this.updateUcsis() : this.saveUcsis()
      },

      saveUcsis() {
        ucsis_service.save_ucsis_type(this.item).then(res => {
          this.$toast.sucs('guardado con exito')
          this.$router.push('/ucsis-type')
        }, res => {
          this.$toast.error('Error guardando servicio')
        })
      },

      updateUcsis() {
        ucsis_service.update_ucsis_type(this.item).then(res => {
          this.$toast.sucs('guardado con exito')
          this.$router.push('/ucsis-type')
        }, res => {
          this.$toast.error('Error guardando servicio')
        })
      },
    },
    computed: {
      isloading() {
        return this.$store.getters.getloading
      },
      errors() {
        return this.$store.getters.geterrors
      },
      user() {
        return JSON.parse(localStorage.getItem('user'))
      },
    }
  }
</script>