<template>
  <v-container>

    <v-card>
      <v-toolbar flat color="blue accent-2" class="white--text">
        <v-btn to="/" icon text>
          <v-icon small class="white--text">mdi-home</v-icon>
        </v-btn>
        <v-toolbar-title>
          <h3 class="font-weight-light">Paquetes Directorio</h3>
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>

        <loader v-if="isloading"></loader>

        <v-row dense>
          <v-col cols="12" md="9">
            <v-alert dense outlined border="left" type="warning">
              <strong>Nota:</strong> Para visualizar los paquetes primero se debe seleccionar una categoria
            </v-alert>
          </v-col>
        </v-row>

        <v-row dense class="mt-3">
          <v-col cols="12" md="3">
            <v-select small dense outlined hide-details v-model="role" :items="roles" label="Rol"></v-select>
          </v-col>
          <v-col cols="12" md="3">
            <v-select return-object @change="setCategoriaSearch" :value="categoria_search" small dense outlined :items="categorias" item-text="name" item-value="id" label="Categoria"></v-select>
          </v-col>
          <v-col cols="12" md="3">
            <v-btn :to="`/editar-paquete`" depressed rounded class="white--text" color="green">
              crear nuevo package
            </v-btn>
          </v-col>
          <v-col cols="12" md="3">
            <v-btn :href="`${$inmigraPath}/planes-inmigrausa/${categoria_search.slug}`" target="_blank" v-if="categoria_search.slug" depressed rounded class="white--text" color="blue">
              prueba url paquetes
            </v-btn>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12">
            <v-data-table :headers="headers" :items="items" disable-pagination hide-default-footer item-key="key" class="elevation-1 mt-6">

              <template v-slot:item.action="{ item }">

                <router-link :to="`/editar-paquete?id=${item.id}`">
                  <v-icon small class="mr-2" color="blue">
                    mdi-pencil
                  </v-icon>
                </router-link>

                <v-icon small color="red lighten-1">
                  mdi-trash-can
                </v-icon>
              </template>

            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>

    </v-card>


  </v-container>
</template>

<script>
  import {
    paquetes_service
  } from '~/paquetes_service'

  import {
    categoria_service
  } from '~/categoria_service'

  export default {

    data() {
      return {
        roles: ['COMPANY_ROLE', 'CONSULTANT_ROLE', 'HANDYMAN_ROLE', 'INTEREST_ROLE'],

        role: null,

        categorias: [],

        category: null,

        items: [],

        headers: [{
            text: 'Titulo',
            value: 'title',
          },
          {
            text: 'Tipo',
            value: 'packageType',
          },
          {
            text: 'Precio ($)',
            value: 'price',
          },
          {
            text: 'Acciones',
            value: 'action',
            sortable: false
          },
        ],
      }
    },

    watch: {
      role(n, o) {
        this.getCategories(n)
      },
      'categoria_search': {
        deep: true,
        //immediate: true,
        handler(n) {
          if (n.slug) {
            this.getPaquetes(n.slug)
          }
        }
      }
    },

    methods: {
      getPaquetes(slug) {
        paquetes_service.get_packages(slug).then(res => {
          this.items = res.data
        }, res => {
          this.$toast.error('Error consultando registro')
        })
      },
      getCategories(role) {
        categoria_service.get_categorias(role).then(res => {
          this.categorias = res.data
        }, res => {
          this.$toast.error('Error consultando registro')
        })
      },
      setCategoriaSearch(data) {
        this.$store.dispatch('setCategoriaSearch', data)
      }
    },
    computed: {
      isloading() {
        return this.$store.getters.getloading
      },
      errors() {
        return this.$store.getters.geterrors
      },
      user() {
        return JSON.parse(localStorage.getItem('user'))
      },
      categoria() {
        return this.$store.getters.get_categoria
      },
      categoria_search() {
        return this.$store.getters.get_categoria_search
      }
    }
  }
</script>