import ListaPost from '../componentes/ListaPost'
import FormPost from '../componentes/FormPost'

const routes = [
  ...route('/mis-posts', ListaPost, {
    auth: true,
    require_collaborator: true
  }),
  ...route('/crear-post-colaborador', FormPost, {
    auth: true,
    require_collaborator: true
  }),
]

function route(path, component = Default, meta = {}) {
  return [{
    path,
    component,
    meta
  }]
}

export default routes