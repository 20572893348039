<template>
  <v-container>

    <v-card>
      <v-toolbar flat color="blue accent-2" class="white--text">
        <v-btn to="/" icon text>
          <v-icon small class="white--text">mdi-home</v-icon>
        </v-btn>
        <v-toolbar-title>
          <h3 class="font-weight-light">Adverts</h3>
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <loader v-if="isloading"></loader>

        <v-row dense>
          <v-col class="mt-2" cols="12">
            <v-btn :to="`/guardar-advert`" depressed rounded class="white--text" color="green">
              nuevo advert
            </v-btn>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12">
            <v-data-table :headers="headers" :items="items" disable-pagination hide-default-footer item-key="key" class="elevation-1 mt-6">

              <template v-slot:item.image="{ item }">
                <img v-if="item.image && item.image.includes('uploads')" class="mt-2 mb-2" height="50" width="50" :src="`${$awsPath}/${item.image}`" alt="">
              </template>

              <template v-slot:item.status="{ item }">
                <v-chip class="white--text" :color="item.status ? 'green' : 'red lighten-1'" small dense>
                  {{item.status ? 'activo' : 'inactivo'}}
                </v-chip>
              </template>

              <template v-slot:item.action="{ item }">

                <router-link :to="`/guardar-advert?id=${item.id}`">
                  <v-icon small class="mr-2" color="blue">
                    mdi-pencil
                  </v-icon>
                </router-link>

                <v-icon @click="setProducto(item)" small color="red lighten-1">
                  mdi-trash-can
                </v-icon>
              </template>

            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>

    </v-card>

    <v-confirm-dialog v-on:delete="deleteProducto">
    </v-confirm-dialog>

  </v-container>
</template>

<script>
  import {
    advert_service
  } from '~/advert_service'

  export default {

    data() {
      return {
        items: [],

        item: {},

        headers: [{
            text: 'Imagen',
            value: 'image',
          }, {
            text: 'Titulo',
            value: 'title',
          },
          {
            text: 'URL',
            value: 'url',
          },
          {
            text: 'Activo',
            value: 'status',
          },
          {
            text: 'Acciones',
            value: 'action',
            sortable: false
          },
        ],
      }
    },

    created() {
      this.getAdverts()
    },

    methods: {
      setProducto(item) {
        this.item = item
        this.$emit('confirm_open')
      },

      deleteProducto() {
        advert_service.delete_advert(this.item.id).then(res => {
          this.items.splice(this.items.indexOf(this.item), 1)
          this.$toast.sucs('Advert eliminado con exito')
        }, res => {
          this.$toast.error('Error consultando productos')
        })
      },

      getAdverts(role) {
        advert_service.get_adverts().then(res => {
          this.items = res.data
        }, res => {
          this.$toast.error('Error consultando adverts')
        })
      }

    },
    computed: {
      isloading() {
        return this.$store.getters.getloading
      },
      errors() {
        return this.$store.getters.geterrors
      },
      user() {
        return JSON.parse(localStorage.getItem('user'))
      },
      categoria() {
        return this.$store.getters.get_categoria
      }
    }
  }
</script>