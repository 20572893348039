import {
  Loader
} from "@googlemaps/js-api-loader"

const loader = new Loader({
  apiKey: "AIzaSyBM5gR8kKS8-xVr3lMyRnBpKQZObEPJIfE",
  version: "weekly"
});

let map, marker, autocomplete, geocode;

loader.load().then(async () => {
  const {
    Map
  } = await google.maps.importLibrary('maps');

  const {
    Marker
  } = await google.maps.importLibrary('marker')

  const {
    AutocompleteService
  } = await google.maps.importLibrary('places')

  const {
    Geocoder
  } = await google.maps.importLibrary("geocoding")

  geocode = new Geocoder()
})

export const google_place_service = {
  async findPlaceById(place_id) {
    let {
      results
    } = await geocode.geocode({
      placeId: place_id
    })
    return results
  },
}