<template>
  <v-container>

    <v-card>
      <v-toolbar flat color="blue accent-2" class="white--text">
        <v-btn to="/" icon text>
          <v-icon small class="white--text">mdi-home</v-icon>
        </v-btn>
        <v-toolbar-title>
          <h3 class="font-weight-light">Detalle Ticket</h3>
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>

        <v-row class="mt-0 mb-3" dense>


          <v-col cols="12" md="3">

            <v-row dense>
              <v-col cols="12">
                <h3 class="mt-4 mb-4">Información del Ticket</h3>
              </v-col>
            </v-row>

            <ticket-info :ticket="ticket"></ticket-info>

          </v-col>

          <!--detalles del ticket -->

          <v-col class="pl-5" cols="12" md="9">

            <v-card elevation="2">
              <v-card-text class="pa-0">

                <v-row class="pt-0 pl-4 pr-4 pb-6" dense>
                  <v-col cols="12">
                    <h2 class="mt-4 mb-2">Detalles Ticket #{{ticket.numeroTicket}}</h2>
                    <h3 class="mt-1 font-weight-light">Asunto: <span class="font-weight-bold">{{ticket.asunto}}</span> </h3>
                    <v-btn @click="scrolToBottom" small dense rounded depressed class="mt-4 white--text" color="cyan lighten-1">responder</v-btn>
                  </v-col>
                </v-row>

                <v-row class="mt-0" dense>

                  <v-col cols="12">
                    <template v-if="ticket.chats.length > 0">
                      <template v-for="(item, index) in ticket.chats" elevation="2">

                        <div class="blue-grey lighten-5 pa-2">
                          <p class="ma-0">Publicado por <span class="font-weight-bold">{{item.from ? item.from.name : 'N/A'}}</span> el {{item.created_date | fecha}}</p>
                        </div>

                        <div class="pt-8 mb-8 pl-4 pr-4">
                          <div v-html="item.message">

                          </div>
                        </div>


                      </template>
                    </template>
                  </v-col>
                </v-row>


                <v-divider></v-divider>

                <v-row v-if="ticket.status ==  'OPEN'" class="mt-6" dense>
                  <v-col class="pa-4" cols="12">
                    <h3 class="mb-2">Responder</h3>
                    <vue-editor :editor-toolbar="customToolbar" v-model="mensaje.message" />

                  </v-col>

                  <v-col class="pa-4" cols="12">
                    <v-btn @click="enviarMensaje" class="white--text " rounded depressed color="green">enviar</v-btn>
                    <v-btn @click="cerrarTicket" class="white--text  ml-4" rounded depressed color="red lighten-1">cerrar ticket</v-btn>
                  </v-col>
                </v-row>

                <v-row v-else dense class="mt-6">
                  <v-col class="pa-4" cols="12">
                    <v-alert outlined dense border="left" type="warning">
                      Ticket ha sido cerrado por el usuario: <strong>{{ticket.closedBy ? ticket.closedBy.name : 'N/A'}}</strong>
                    </v-alert>
                  </v-col>
                </v-row>

              </v-card-text>
            </v-card>





          </v-col>
        </v-row>




      </v-card-text>

    </v-card>


  </v-container>

</template>

<script>
  import {
    VueEditor
  } from "vue2-editor";
  import {
    ticket_service
  } from '~/ticket_service'

  import ticketInfo from './ticketInfo'

  import {
    account_service
  } from '~/account_service'

  import moment from 'moment'

  export default {
    components: {
      VueEditor,
      ticketInfo
    },
    data() {
      return {
        mensaje: {
          message: null
        },

        customToolbar: [
          ["bold", "italic", "underline"],
          [{
            list: "ordered"
          }, {
            list: "bullet"
          }],
        ],

        ticket: {
          to: null,
          id: null,
          userId: {
            name: "N/A",
            last_name: ''
          },
          message: null,
          closedBy: null,
          asunto: null,
          status: null,
          chats: [{
            message: null
          }],
          closed_date: null,
          created_date: null,
          updated_date: null,
          numeroTicket: 7
        },
      }
    },


    created() {
      this.getTicket(this.$route.params.id)
    },

    methods: {
      scrolToBottom() {

        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: 'smooth'
        })
      },
      cerrarTicket() {
        let data = {
          id: this.ticket.id,
          status: 'CLOSED',
          closedBy: this.user.id
        }
        ticket_service.cerrar_ticket(data).then(res => {
          this.$toast.sucs('¡Ticket cerrado con exito!')
          this.ticket = res.data
        }, res => {
          this.$toast.error('¡error cerrando ticket!')
        })
      },
      enviarMensaje() {
        let data = {
          id: this.ticket.id,
          message: this.mensaje.message,
          to: this.ticket.userId.id
        }
        ticket_service.enviar_mensaje(data).then(res => {
          this.$toast.sucs('¡Respuesta enviada con exito!')
          this.mensaje.message = null
          this.$set(this.ticket, 'chats', res.data.chats)
        }, res => {
          this.$toast.error('¡error enviando respuesta!')
        })
      },

      getTicket(id) {
        ticket_service.search_tickets({
          id: id
        }).then(res => {
          this.ticket = res.data[0]
        }, res => {
          this.$toast.error('Error consultando tickets')
        })
      },



    },
    filters: {
      fecha(value) {
        return moment(value).format('dddd D MMMM Y (H:s)')
      }
    },
    computed: {
      isloading() {
        return this.$store.getters.getloading
      },
      errors() {
        return this.$store.getters.geterrors
      },
      user() {
        return JSON.parse(localStorage.getItem('user'))
      },
    }
  }
</script>