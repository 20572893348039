import axios from 'axios'

export const booking_service = {
  async get_my_bookings(user_id, status) {
    return axios.get(`booking-owner/${user_id}/${status}`)
  },

  async update_booking(item) {
    return axios.put(`booking/${item.id}`, item)
  },
}