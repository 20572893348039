const modulo_package = {
  strict: false,

  state: {
    package: {
      defaults: true,
      packageDirectoryId: {
        id: null,
        advertQuantity: 0,
        business_enable: false
      }
    },

    selected_package: {
      id: null,
      items: [],
      id: null,
      title: null,
      description: null,
      price: 0,
      advertQuantity: 0,
      stripeId: null,
      environment: 'APP',
      packageType: 'MONTH',
      packageModality: 'OTHER',
      subCategory: null,
      category: null,
      type: 'CONSULTANT_ROLE',
      chat_enable: false,
      top: false,
      price_discount: 0,
      coupons: 0,
      booking_quantity: 0,
      service_quantity: 0,
      video_enable: false,
      business_enable: false,
      business_quantity: 0,
      combo_quantity: 0,
      combo: false,
      isItems: false,
      items_quantity: 0,
      payment_outside: true,
      payment_withinapp: false,
      qr_code: false,
      created_date: null,
      updated_date: null
    }
  },

  getters: {
    getpackage: state => state.package,
    get_selected_pack: state => state.selected_package
  },

  mutations: {
    set_package: (state, pack) => state.package = pack,
    set_selected_package: (state, pack) => state.selected_package = pack
  },

  actions: {
    setPackage: (context, pack) => context.commit('set_package', pack),
    setSelectedPackage: (context, pack) => context.commit('set_selected_package', pack)
  }
}

export default modulo_package;