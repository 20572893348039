<template id="">
  <v-row dense class="mb-4">

    <v-col cols="12" md="3">
      <v-card color="#26c6da" dark>
        <v-card-title class="justify-center text-center">
          <v-icon large left>
            mdi-account
          </v-icon>
        </v-card-title>
        <v-card-text class="justify-center text-center">
          <span class="title font-weight-medium white--text">Accounts: <strong>{{total_accounts}}</strong> </span>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12" md="3">

      <v-card color="#f35145" dark>
        <v-card-title class="justify-center text-center">
          <v-icon large left>
            mdi-file-document-box-multiple-outline
          </v-icon>
        </v-card-title>
        <v-card-text class="justify-center text-center">
          <span class="title font-weight-medium white--text">Ucsis: <strong>{{total_ucsis}}</strong> </span>
        </v-card-text>
      </v-card>

    </v-col>

    <v-col cols="12" md="3">
      <v-card color="orange" dark>
        <v-card-title class="justify-center text-center">
          <v-icon large left>
            mdi-poll-box
          </v-icon>
        </v-card-title>
        <v-card-text class="justify-center text-center">
          <span class="title font-weight-medium white--text">% Ucsis: <strong>{{porcentaje | porcentaje}}</strong> </span>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12" md="3">
      <v-card color="#00c853" dark>
        <v-card-title class="justify-center text-center">
          <v-icon large left>
            mdi-wallet-travel
          </v-icon>
        </v-card-title>
        <v-card-text class="justify-center text-center">
          <span class="title font-weight-medium white--text">Purchases: <strong>{{ total_purchases }}</strong> </span>
        </v-card-text>
      </v-card>
    </v-col>


  </v-row>
</template>

<script>
  import {
    reportes_service
  } from '~/reportes_service'

  export default {
    props: ['total_accounts', 'total_ucsis', 'porcentaje', 'total_purchases'],

    data() {
      return {

      }
    },

    created() {

    },

    mounted() {},

    methods: {

    },

    filters: {
      porcentaje(value) {
        return `${parseFloat(value).toFixed(2)}%`
      }
    },

    computed: {

    }
  }
</script>