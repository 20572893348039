<template id="">
  <v-dialog v-model="dialog" width="700px">

    <v-card>
      <v-card-title>
        Detalles booking
      </v-card-title>

      <v-card-text>
        <h3>Usuario</h3>
        <br>
        <v-row dense>
          <v-col cols="12" md="4">
            <v-text-field :value="booking.userId.name" disabled readonly outlined small dense label="Nombre"></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field :value="booking.userId.last_name" disabled readonly outlined small dense label="Apellido"></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field :value="booking.userId.email" disabled readonly outlined small dense label="Email"></v-text-field>
          </v-col>
        </v-row>

        <h3>Booking</h3>
        <br>
        <v-row dense>
          <v-col cols="12" md="6">
            <v-text-field :value="booking.note" disabled readonly outlined small dense label="Nota"></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field :value="booking.reason" disabled readonly outlined small dense label="Motivo"></v-text-field>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" md="6">
            <v-text-field :value="booking.bookingDate | format_date" disabled readonly outlined small dense label="Fecha"></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field :value="booking.bookingTime | format_hour" disabled readonly outlined small dense label="Hora"></v-text-field>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" md="4">
            <v-select @change="changeStatus" outlined dense v-model="booking.status" :items="states" label="Estado" item-text="label" item-value="status"></v-select>
          </v-col>
        </v-row>

      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="dialog = false">
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import moment from 'moment'

  import "moment/locale/es"

  import {
    booking_service
  } from '~/booking_service'

  export default {
    props: ['states'],
    data() {
      return {
        dialog: false,
        booking: {
          amount: 80,
          bookingDate: null,
          bookingTime: null,
          created_date: null,
          group: null,
          id: null,
          location: null,
          meeting: null,
          name: null,
          note: null,
          phone: null,
          reason: null,
          serviceId: null,
          status: null,
          type: null,
          updated_date: null,
          userId: null,
          userId: {
            id: null,
            name: "",
            last_name: "",
            email: ""
          },
          userTime: null
        },
      }
    },

    created() {
      this.$parent.$on('open_modal', (booking) => {
        this.booking = booking
        this.dialog = true
      })
    },


    methods: {
      changeStatus(status) {
        let data = {
          id: this.booking.id,
          status: status
        }

        booking_service.update_booking(data).then(res => {
          this.$emit('update_list', res.data.id)
          this.$toast.sucs('Booking actualizado con exito')
          this.dialog = false
        }, res => {
          this.$toast.error('Error actualizando booking')
        })
      },

      updateBooking() {

      }
    },

    filters: {
      format_hour(hour_24_format) {
        return moment(`01-01-2023 ${hour_24_format}:00`).format('hh:mm a')
      },
      format_date(db_date) {
        return moment(db_date).format('ll')
      },
    },

    computed: {
      isloading() {
        return this.$store.getters.getloading
      },
    }
  }
</script>