import FormReportes from '../componentes/FormReportes'
import ListaCasos from '../componentes/ListaCasos'

const routes = [
  ...route('/reportes', FormReportes, {
    auth: true,
    require_admin: true
  }),
  ...route('/reporte-uscis', ListaCasos, {
    auth: true,
    require_admin: true
  }),
]

function route(path, component = Default, meta = {}) {
  return [{
    path,
    component,
    meta
  }]
}

export default routes