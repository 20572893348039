<template>
  <v-dialog v-model="dialog" persistent max-width="500">
    <v-card>
      <v-card-title class="text-h5">
        Prueba costo por usuario
      </v-card-title>
      <v-card-text>
        <v-row dense class="mt-4">
          <v-col cols="12" md="6">
            <v-text-field v-model="date_values.publicidad" outlined small dense label="costo publicidad" type="number" step="0.1"></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" md="4">
            <v-text-field readonly v-model="costo_account" outlined small dense label="inversion account" type="number" step="0.1"></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field readonly v-model="costo_uscis" outlined small dense label="inversion ucsis" type="number" step="0.1"></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field readonly v-model="costo_purchase" outlined small dense label="inversion purchases" type="number" step="0.1"></v-text-field>
          </v-col>
        </v-row>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn rounded depressed class="white--text" color="green" @click="saveMarketing">
          guardar
        </v-btn>
        <v-btn rounded depressed class="white--text" color="blue accent-2" @click="dialog = false">
          cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import {
    marketing_service
  } from '~/marketing_service'

  export default {
    name: '',
    data() {
      return {
        dialog: false,

        date_values: {
          accounts: 0,
          ucsis: 0,
          purchases: 0,
          publicidad: 1,
          fecha: null
        },

      };
    },

    created() {
      this.$parent.$on("open", data => {
        this.dialog = true
        this.date_values = data
      });
    },

    methods: {
      saveMarketing() {
        marketing_service.save_marketing({
          costo_purchases: this.costo_purchase,
          costo_account: this.costo_account,
          costo_uscis: this.costo_uscis,
          publicidad: this.date_values.publicidad,
          date: this.date_values.date
        }).then(res => {
          this.$toast.sucs('¡Guardado con exito!')
          this.$emit('reload_reporte')
          this.dialog = false
        })
      },

      confirm() {
        this.dialog = false
      },
    },

    computed: {
      costo_account() {
        return this.date_values.accounts ? parseFloat(this.date_values.publicidad / this.date_values.accounts).toFixed(2) : 0
      },
      costo_uscis() {
        return this.date_values.ucsis ? parseFloat(this.date_values.publicidad / this.date_values.ucsis).toFixed(2) : 0
      },
      costo_purchase() {
        return this.date_values.purchases ? parseFloat(this.date_values.publicidad / this.date_values.purchases).toFixed(2) : 0
      },
    }
  };
</script>