<template>
  <v-dialog persistent v-model="dialog" width="800">

    <v-card>
      <v-card-title class="text-h5 pink darken-1 white--text">
        Lista Uscis
      </v-card-title>

      <v-card-text>
        <v-row dense>
          <v-col cols="12" align="center">

            <v-data-table :headers="headers" :items="uscis_docs" disable-pagination hide-default-footer item-key="key" class="elevation-1 mt-6">


            </v-data-table>
          </v-col>
        </v-row>

      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red lighten-1" text @click="dialog = false">
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
  export default {
    data() {
      return {
        uscis_docs: [],
        dialog: false,
        headers: [{
            text: 'Receipt num',
            value: 'receiptNum',
          }, {
            text: 'Form',
            value: 'formNum',
          },
          {
            text: 'Status',
            value: 'actionCodeText',
          }
        ],
      }
    },

    created() {
      this.$parent.$on('open_uscis', data => {
        this.dialog = true
        this.uscis_docs = data
      });
    }
  }
</script>