<template>

  <v-dialog v-model="dialog" width="650">

    <loader v-if="isloading"></loader>

    <v-card>
      <v-card-title class="text-h5 white--text pink darken-1">
        Crear Calendario
      </v-card-title>

      <v-card-text>
        <v-container>

          <v-row dense class="mt-4">
            <v-col cols="12" md="6">
              <v-select v-model="calendario.timeSlot" :items="time_slots" label="Tiempo" item-text="label" item-value="value" outlined small dense></v-select>
            </v-col>

            <v-col cols="12" md="6">
              <v-select v-model="calendario.duration" :items="durations" label="Duración" item-text="label" item-value="value" outlined small dense></v-select>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" md="6">

              <v-menu ref="menu_date" v-model="menu_date" :close-on-content-click="false" :return-value.sync="calendario.initialDate" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="calendario.initialDate" label="Fecha" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined small dense></v-text-field>
                </template>
                <v-date-picker color="pink darken-1" v-model="calendario.initialDate" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu_date = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu_date.save(calendario.initialDate)">
                    OK
                  </v-btn>
                </v-date-picker>

              </v-menu>
            </v-col>

            <v-col cols="12" md="6">

              <v-menu ref="menu_time" v-model="menu_time" :close-on-content-click="false" :nudge-right="40" :return-value.sync="calendario.initTime" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="calendario.initTime" label="Hora" prepend-icon="mdi-clock-outline" readonly v-bind="attrs" v-on="on" outlined small dense></v-text-field>
                </template>
                <v-time-picker color="pink darken-1" v-if="menu_time" v-model="calendario.initTime" full-width @click:minute="$refs.menu_time.save(calendario.initTime)"></v-time-picker>
              </v-menu>

            </v-col>
          </v-row>

        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn @click="crearCalendario" rounded depressed class="white--text" color="green">
          Guardar
        </v-btn>
        <v-btn rounded depressed class="white--text" color="red lighten-1" @click="dialog = false">
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>
  import {
    calendar_service
  } from '~/calendar_service'

  import {
    months_mixin
  } from '../mixins/months_mixin'

  export default {
    mixins: [months_mixin],

    data() {
      return {
        dialog: false,

        menu_date: false,

        menu_time: false,

        calendario: {
          serviceId: null,
          timeSlot: 'Not Repeat',
          duration: '1 Month',
          initialDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
          initTime: '00:00'
        },

        time_slots: [{
            value: 'Not Repeat',
            label: 'No repetir'
          },
          {
            value: 'Every Day',
            label: 'Todos los dias'
          },
          {
            value: 'Same Day Every Week',
            label: 'Mismo dia todas las semanas'
          },
          {
            value: 'Same Day Every Month',
            label: 'Mismo dia todo el mes'
          }
        ]
      }
    },

    created() {
      this.$parent.$on('open_modal', data => {
        this.dialog = true
        this.calendario.serviceId = data.id
      })
    },

    methods: {
      crearCalendario() {
        calendar_service.save_user_calendar(this.calendario).then(res => {
          this.$toast.sucs('Calendario creado con exito')
          this.dialog = false
        }, res => {
          this.$toast.sucs('Error creando calendario')
        })
      },
    },

    computed: {
      isloading() {
        return this.$store.getters.getloading
      },
      errors() {
        return this.$store.getters.geterrors
      },
      user() {
        return JSON.parse(localStorage.getItem('user'))
      }
    }
  }
</script>