import axios from 'axios'

export const productos_service = {

  async get_productos(tipo) {
    return axios.get(`list-products-app/${tipo}`)
  },

  async get_producto(id) {
    return axios.get(`products-app/${id}`)
  },

  async save_producto(data) {
    return axios.post(`products-app`, data)
  },

  async update_producto(data) {
    return axios.put(`products-app/${data.id}`, data)
  },

  async delete_producto(id) {
    return axios.delete(`products-app/${id}`)
  },
}