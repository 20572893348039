<template>
  <v-row justify="center" align="center">
    <v-progress-circular :size="40" :width="3" color="pink darken-1" indeterminate></v-progress-circular>
    <br>
    <br>
  </v-row>
</template>

<script>
  export default {

  }
</script>