<template>
  <v-container>

    <v-card>
      <v-toolbar flat color="blue accent-2" class="white--text">
        <v-btn to="/" icon text>
          <v-icon small class="white--text">mdi-home</v-icon>
        </v-btn>
        <v-toolbar-title>
          <h3 class="font-weight-light">Service Center</h3>
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <loader v-if="isloading"></loader>
        <v-row dense align="center">
          <v-col cols="12" md="4">
            <v-text-field v-model="search" hide-details append-icon="mdi-magnify" label="Buscar" single-line outlined small dense></v-text-field>
          </v-col>

          <v-col class="mt-2" cols="4">
            <v-btn :to="`/crear-ucsis-type`" depressed rounded class="white--text" color="green">
              nuevo
            </v-btn>
          </v-col>
        </v-row>


        <v-row dense>
          <v-col cols="12">
            <v-data-table :sort-by="['visa_type']" :search="search" :headers="headers" :items="items" disable-pagination hide-default-footer item-key="key" class="elevation-1 mt-6">

              <template v-slot:item.action="{ item }">

                <v-icon small class="mr-2" color="blue">
                  mdi-pencil
                </v-icon>

                <v-icon @click="openConfirmDialog(item)" small color="red lighten-1">
                  mdi-trash-can
                </v-icon>

              </template>

            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>

    </v-card>

    <v-confirm-dialog v-on:delete="deleteServiceCenter">
    </v-confirm-dialog>

  </v-container>
</template>

<script>
  import {
    center_service
  } from '~/center_service'

  export default {

    data() {
      return {
        search: null,

        item: {},

        items: [],

        headers: [{
            text: 'Service Center',
            value: 'service_center',
          },
          {
            text: 'Visa',
            value: 'visa_type',
          },
          {
            text: 'Aproximado dias',
            value: 'avg_days',
            width: 150,
            align: 'center'
          },
          {
            text: 'Acciones',
            value: 'action',
            sortable: false
          },
        ],
      }
    },

    created() {
      this.getUcsis()
    },

    methods: {
      getUcsis() {
        center_service.get_service_centers().then(res => {
          this.items = res.data
        }, res => {
          this.$toast.error('Error consultando registro')
        })
      },

      openConfirmDialog(item) {
        this.item = item
        this.$emit('confirm_open')
      },

      deleteServiceCenter() {
        center_service.delete_service_center(this.item.id).then(res => {
          this.items.splice(this.items.indexOf(this.item), 1)
          this.$toast.sucs('Registro eliminado con exito')
          this.item = {}
        }, res => {
          this.$toast.error('Error eliminando registro')
        })
      }
    },
    computed: {
      isloading() {
        return this.$store.getters.getloading
      },
      errors() {
        return this.$store.getters.geterrors
      },
      user() {
        return JSON.parse(localStorage.getItem('user'))
      },
      categoria() {
        return this.$store.getters.get_categoria
      }
    }
  }
</script>