import axios from 'axios'

export const ucsis_service = {

  async get_ucsis() {
    return axios.get(`list-uscis-type`)
  },

  async get_ucsis_by_id(id) {
    return axios.get(`uscis-type/${id}`)
  },

  async save_ucsis_type(data) {
    return axios.post(`uscis-type`, data)
  },

  async update_ucsis_type(data) {
    return axios.put(`uscis-type/${data.id}`, data)
  },

  async delete_ucsis(id) {
    return axios.delete(`uscis-type/${id}`)
  },

}