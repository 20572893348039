<template id="">
  <v-btn class="ml-4 mt-2 mb-4 white--text" depressed rounded color="red lighten-1" @click="cancelarSub">cancelar suscripción</v-btn>
</template>

<script>
  import {
    check_booking
  } from '~helpers/check_booking'

  import {
    package_service
  } from '~/package_service'

  import {
    booking_package_service
  } from '~/booking_package_service'

  export default {
    methods: {
      cancelarSub() {
        package_service.cancel_subscription().then(res => {
          this.$toast.sucs('Suscripción cancelada con exito')
          this.check()
        }, res => {
          this.$toast.sucs('Suscripción cancelada con exito')
          this.check()
        })
      },

      check() {
        booking_package_service.get_package_booking('APP').then(res => {
          localStorage.removeItem('package')
          this.$store.dispatch('setPackage', {})
          setTimeout(() => {
            this.$router.push('/')
          }, 800)
        })
      }
    }
  }
</script>