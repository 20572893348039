import axios from 'axios'

const modulo_token = {
  strict: false,

  state: {

  },

  getters: {

  },

  mutations: {

  },

  actions: {
    refreshToken: context => {
      /*axios.get('auth/checkin/token').then(res => {
        console.log(res.data);
      })*/
    },

  }
}

export default modulo_token;